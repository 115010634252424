import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ErrorDialogData } from '../error-dialog.types';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    NgIf,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorDialogComponent implements OnInit {
  public isManagementPortalRoute: boolean = true;

  constructor(
    private readonly _dialogRef: MatDialogRef<ErrorDialogComponent>,
    private readonly _router: Router,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: ErrorDialogData
  ) {}

  ngOnInit(): void {
    if (!this._router.url.startsWith('/manage')) {
      this.isManagementPortalRoute = false;
    }
  }

  contactSupport(): void {
    this._router
      .navigate(['/manage/help-center/support'], {
        state: {
          errorToSubmit: this.data,
        },
      })
      .then(() => {
        this._dialogRef.close();
      })
      .catch(() => {});
  }
}
