import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, debounceTime, Subscription } from 'rxjs';
import { LocalStorageService } from '../storage/storage.service';

export interface Notification {
  id: number | string;
  type: 'success' | 'warning' | 'error' | 'info';
  message: string;
  details?: string;
  timestamp: Date;
  read: boolean;
  route?: string;
  icon?: string;
}

export type NotificationUpdate = (
  prevNotifications: Notification[]
) => Notification[];

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements OnDestroy {
  private readonly _key = 'rebac-frontend-notification-history';
  private readonly _notifications$ = new BehaviorSubject<Notification[]>([]);
  private readonly _subscription = new Subscription();
  public readonly notifications$ = this._notifications$.asObservable();

  constructor(private readonly _store: LocalStorageService) {
    this._subscription.add(
      this._notifications$.pipe(debounceTime(1000)).subscribe(notifications => {
        this._store.set(this._key, notifications);
      })
    );
    const notifications = this._store.get<Notification[]>(this._key);

    if (notifications) {
      this.set(notifications);
    }
  }

  set(notifications: Notification[]): void {
    this._notifications$.next(notifications);
  }

  add(notification: Notification): void {
    this._updateNotifications(notifications =>
      notifications.concat(notification)
    );
  }

  remove(notification: Notification): void {
    this._updateNotifications(notifications =>
      notifications.filter(n => n.id !== notification.id)
    );
  }

  markAsRead(notification: Notification): void {
    this._updateNotifications(notifications => {
      const updatedNotification = notifications.find(
        n => n.id === notification.id
      );
      if (updatedNotification) {
        return notifications.map(n =>
          n.id === notification.id ? { ...n, read: true } : n
        );
      }
      return notifications;
    });
  }

  private _updateNotifications(update: NotificationUpdate): void {
    const prevNotifications = this._notifications$.getValue();
    const newNotifications = update(prevNotifications);
    this._notifications$.next(newNotifications);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
