import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A country code as defined by ISO 3166-1 alpha-2 */
  CountryCode: { input: string; output: string; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: Date | string; output: Date | string; }
  /** An arbitrary-precision Decimal type */
  Decimal: { input: number; output: number; }
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: string; output: string; }
  /** A field whose value is a valid decimal degrees latitude number (53.471): https://en.wikipedia.org/wiki/Latitude */
  Latitude: { input: string; output: string; }
  /** A field whose value is a valid decimal degrees longitude number (53.471): https://en.wikipedia.org/wiki/Longitude */
  Longitude: { input: string | number; output: string | number; }
  /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
  PostalCode: { input: string; output: string; }
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: string; output: string; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: File; output: File; }
  /** Custom scalar type to validate a year is four characters long (2024) */
  Year: { input: any; output: any; }
};

/** Information about this GraphQL API */
export type AboutModel = {
  __typename: 'AboutModel';
  author: Scalars['String']['output'];
  contact: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/** Input for the information about the business-owner account to create */
export type AccountInformationInput = {
  /** The email address of the business owner */
  email: Scalars['EmailAddress']['input'];
  /** First name of the business owner */
  firstName: Scalars['String']['input'];
  /** The last name of the business owner */
  lastName: Scalars['String']['input'];
  /** The password for the created account */
  password: Scalars['String']['input'];
};

export type Balance = {
  __typename: 'Balance';
  company: Company;
  companyId: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BalanceAvgAggregate = {
  __typename: 'BalanceAvgAggregate';
  count?: Maybe<Scalars['Float']['output']>;
};

export type BalanceCompanyIdCustomerIdCompoundUniqueInput = {
  companyId: Scalars['String']['input'];
  customerId: Scalars['String']['input'];
};

export type BalanceCountAggregate = {
  __typename: 'BalanceCountAggregate';
  _all: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type BalanceCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<BalanceWhereUniqueInput>>;
};

export type BalanceListRelationFilter = {
  every?: InputMaybe<BalanceWhereInput>;
  none?: InputMaybe<BalanceWhereInput>;
  some?: InputMaybe<BalanceWhereInput>;
};

export type BalanceMaxAggregate = {
  __typename: 'BalanceMaxAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BalanceMinAggregate = {
  __typename: 'BalanceMinAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BalanceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BalanceOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
};

export enum BalanceScalarFieldEnum {
  CompanyId = 'companyId',
  Count = 'count',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  UpdatedAt = 'updatedAt'
}

export type BalanceScalarWhereInput = {
  AND?: InputMaybe<Array<BalanceScalarWhereInput>>;
  NOT?: InputMaybe<Array<BalanceScalarWhereInput>>;
  OR?: InputMaybe<Array<BalanceScalarWhereInput>>;
  companyId?: InputMaybe<StringFilter>;
  count?: InputMaybe<IntFilter>;
  customerId?: InputMaybe<StringFilter>;
};

export type BalanceSumAggregate = {
  __typename: 'BalanceSumAggregate';
  count?: Maybe<Scalars['Int']['output']>;
};

export type BalanceUpdateManyMutationInput = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

export type BalanceUpdateManyWithWhereWithoutCustomerInput = {
  data: BalanceUpdateManyMutationInput;
  where: BalanceScalarWhereInput;
};

export type BalanceUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<BalanceWhereUniqueInput>>;
  delete?: InputMaybe<Array<BalanceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BalanceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BalanceWhereUniqueInput>>;
  set?: InputMaybe<Array<BalanceWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<BalanceUpdateManyWithWhereWithoutCustomerInput>>;
};

export type BalanceWhereInput = {
  AND?: InputMaybe<Array<BalanceWhereInput>>;
  NOT?: InputMaybe<Array<BalanceWhereInput>>;
  OR?: InputMaybe<Array<BalanceWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  count?: InputMaybe<IntFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
};

export type BalanceWhereUniqueInput = {
  AND?: InputMaybe<Array<BalanceWhereInput>>;
  NOT?: InputMaybe<Array<BalanceWhereInput>>;
  OR?: InputMaybe<Array<BalanceWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  companyId_customerId?: InputMaybe<BalanceCompanyIdCustomerIdCompoundUniqueInput>;
  count?: InputMaybe<IntFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BranchOffice = {
  __typename: 'BranchOffice';
  _count: BranchOfficeCount;
  cashDesks?: Maybe<Array<CashDesk>>;
  city: Scalars['String']['output'];
  company: Company;
  companyId: Scalars['String']['output'];
  countryCode: Scalars['CountryCode']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  houseNumber: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  latitude: Scalars['Decimal']['output'];
  longitude: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  postalCode: Scalars['PostalCode']['output'];
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BranchOfficeAvgAggregate = {
  __typename: 'BranchOfficeAvgAggregate';
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
};

export type BranchOfficeCount = {
  __typename: 'BranchOfficeCount';
  cashDesks: Scalars['Int']['output'];
};

export type BranchOfficeCountAggregate = {
  __typename: 'BranchOfficeCountAggregate';
  _all: Scalars['Int']['output'];
  city: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  countryCode: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  houseNumber: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Int']['output'];
  longitude: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  postalCode: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  street: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type BranchOfficeListRelationFilter = {
  every?: InputMaybe<BranchOfficeWhereInput>;
  none?: InputMaybe<BranchOfficeWhereInput>;
  some?: InputMaybe<BranchOfficeWhereInput>;
};

export type BranchOfficeLocationCompoundUniqueInput = {
  city: Scalars['String']['input'];
  countryCode: Scalars['CountryCode']['input'];
  houseNumber: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['PostalCode']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type BranchOfficeMaxAggregate = {
  __typename: 'BranchOfficeMaxAggregate';
  city?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['CountryCode']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['PostalCode']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BranchOfficeMinAggregate = {
  __typename: 'BranchOfficeMinAggregate';
  city?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['CountryCode']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['PostalCode']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BranchOfficeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BranchOfficeOrderByWithRelationInput = {
  cashDesks?: InputMaybe<CashDeskOrderByRelationAggregateInput>;
  city?: InputMaybe<SortOrder>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  houseNumber?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
};

export type BranchOfficeRelationFilter = {
  is?: InputMaybe<BranchOfficeWhereInput>;
  isNot?: InputMaybe<BranchOfficeWhereInput>;
};

export enum BranchOfficeScalarFieldEnum {
  City = 'city',
  CompanyId = 'companyId',
  CountryCode = 'countryCode',
  CreatedAt = 'createdAt',
  Description = 'description',
  HouseNumber = 'houseNumber',
  Id = 'id',
  Latitude = 'latitude',
  Longitude = 'longitude',
  Name = 'name',
  PostalCode = 'postalCode',
  State = 'state',
  Street = 'street',
  UpdatedAt = 'updatedAt'
}

export type BranchOfficeSumAggregate = {
  __typename: 'BranchOfficeSumAggregate';
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
};

/** Custom input type for unique identifier of branch offices */
export type BranchOfficeUniqueInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type BranchOfficeUpdateInput = {
  cashDesks?: InputMaybe<CashDeskUpdateManyWithoutBranchOfficeNestedInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutBranchOfficesNestedInput>;
  countryCode?: InputMaybe<Scalars['CountryCode']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  latitude?: InputMaybe<Scalars['Decimal']['input']>;
  longitude?: InputMaybe<Scalars['Decimal']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['PostalCode']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type BranchOfficeWhereInput = {
  AND?: InputMaybe<Array<BranchOfficeWhereInput>>;
  NOT?: InputMaybe<Array<BranchOfficeWhereInput>>;
  OR?: InputMaybe<Array<BranchOfficeWhereInput>>;
  cashDesks?: InputMaybe<CashDeskListRelationFilter>;
  city?: InputMaybe<StringFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  houseNumber?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  latitude?: InputMaybe<DecimalFilter>;
  longitude?: InputMaybe<DecimalFilter>;
  name?: InputMaybe<StringFilter>;
  postalCode?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  street?: InputMaybe<StringFilter>;
};

export type BranchOfficeWhereUniqueInput = {
  AND?: InputMaybe<Array<BranchOfficeWhereInput>>;
  NOT?: InputMaybe<Array<BranchOfficeWhereInput>>;
  OR?: InputMaybe<Array<BranchOfficeWhereInput>>;
  cashDesks?: InputMaybe<CashDeskListRelationFilter>;
  city?: InputMaybe<StringFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  houseNumber?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  latitude?: InputMaybe<DecimalFilter>;
  location?: InputMaybe<BranchOfficeLocationCompoundUniqueInput>;
  longitude?: InputMaybe<DecimalFilter>;
  name?: InputMaybe<StringFilter>;
  postalCode?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  street?: InputMaybe<StringFilter>;
};

export type CalculationRule = {
  __typename: 'CalculationRule';
  company: Company;
  companyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  points: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type CalculationRuleAvgAggregate = {
  __typename: 'CalculationRuleAvgAggregate';
  points?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type CalculationRuleCompanyIdPointsValueCompoundUniqueInput = {
  points: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};

export type CalculationRuleCountAggregate = {
  __typename: 'CalculationRuleCountAggregate';
  _all: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  points: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type CalculationRuleListRelationFilter = {
  every?: InputMaybe<CalculationRuleWhereInput>;
  none?: InputMaybe<CalculationRuleWhereInput>;
  some?: InputMaybe<CalculationRuleWhereInput>;
};

export type CalculationRuleMaxAggregate = {
  __typename: 'CalculationRuleMaxAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type CalculationRuleMinAggregate = {
  __typename: 'CalculationRuleMinAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type CalculationRuleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CalculationRuleOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  points?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum CalculationRuleScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Points = 'points',
  UpdatedAt = 'updatedAt',
  Value = 'value'
}

export type CalculationRuleSumAggregate = {
  __typename: 'CalculationRuleSumAggregate';
  points?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type CalculationRuleWhereInput = {
  AND?: InputMaybe<Array<CalculationRuleWhereInput>>;
  NOT?: InputMaybe<Array<CalculationRuleWhereInput>>;
  OR?: InputMaybe<Array<CalculationRuleWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  points?: InputMaybe<IntFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type CalculationRuleWhereUniqueInput = {
  AND?: InputMaybe<Array<CalculationRuleWhereInput>>;
  NOT?: InputMaybe<Array<CalculationRuleWhereInput>>;
  OR?: InputMaybe<Array<CalculationRuleWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId_points_value?: InputMaybe<CalculationRuleCompanyIdPointsValueCompoundUniqueInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  points?: InputMaybe<IntFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type CashDesk = {
  __typename: 'CashDesk';
  _count: CashDeskCount;
  branchOffice: BranchOffice;
  branchOfficeId: Scalars['UUID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  scannedTransactions?: Maybe<Array<ScannedTransaction>>;
};

export type CashDeskCount = {
  __typename: 'CashDeskCount';
  scannedTransactions: Scalars['Int']['output'];
};

export type CashDeskCountAggregate = {
  __typename: 'CashDeskCountAggregate';
  _all: Scalars['Int']['output'];
  branchOfficeId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
};

export type CashDeskCreateNestedOneWithoutScannedTransactionsInput = {
  connect?: InputMaybe<CashDeskWhereUniqueInput>;
};

export type CashDeskIdBranchOfficeIdCompoundUniqueInput = {
  id: Scalars['String']['input'];
};

export type CashDeskListRelationFilter = {
  every?: InputMaybe<CashDeskWhereInput>;
  none?: InputMaybe<CashDeskWhereInput>;
  some?: InputMaybe<CashDeskWhereInput>;
};

export type CashDeskMaxAggregate = {
  __typename: 'CashDeskMaxAggregate';
  branchOfficeId?: Maybe<Scalars['UUID']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CashDeskMinAggregate = {
  __typename: 'CashDeskMinAggregate';
  branchOfficeId?: Maybe<Scalars['UUID']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Custom input type for creating cash desks */
export type CashDeskNestedCreateInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CashDeskOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CashDeskOrderByWithRelationInput = {
  branchOffice?: InputMaybe<BranchOfficeOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  scannedTransactions?: InputMaybe<ScannedTransactionOrderByRelationAggregateInput>;
};

export type CashDeskRelationFilter = {
  is?: InputMaybe<CashDeskWhereInput>;
  isNot?: InputMaybe<CashDeskWhereInput>;
};

export enum CashDeskScalarFieldEnum {
  BranchOfficeId = 'branchOfficeId',
  Id = 'id',
  Name = 'name'
}

export type CashDeskScalarWhereInput = {
  AND?: InputMaybe<Array<CashDeskScalarWhereInput>>;
  NOT?: InputMaybe<Array<CashDeskScalarWhereInput>>;
  OR?: InputMaybe<Array<CashDeskScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

/** Custom unique identifier input for cash desk */
export type CashDeskUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CashDeskUpdateManyMutationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CashDeskUpdateManyWithWhereWithoutBranchOfficeInput = {
  data: CashDeskUpdateManyMutationInput;
  where: CashDeskScalarWhereInput;
};

export type CashDeskUpdateManyWithoutBranchOfficeNestedInput = {
  connect?: InputMaybe<Array<CashDeskWhereUniqueInput>>;
  delete?: InputMaybe<Array<CashDeskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CashDeskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CashDeskWhereUniqueInput>>;
  set?: InputMaybe<Array<CashDeskWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<CashDeskUpdateManyWithWhereWithoutBranchOfficeInput>>;
};

export type CashDeskUpdateOneRequiredWithoutScannedTransactionsNestedInput = {
  connect?: InputMaybe<CashDeskWhereUniqueInput>;
};

export type CashDeskWhereInput = {
  AND?: InputMaybe<Array<CashDeskWhereInput>>;
  NOT?: InputMaybe<Array<CashDeskWhereInput>>;
  OR?: InputMaybe<Array<CashDeskWhereInput>>;
  branchOffice?: InputMaybe<BranchOfficeRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  scannedTransactions?: InputMaybe<ScannedTransactionListRelationFilter>;
};

export type CashDeskWhereUniqueInput = {
  AND?: InputMaybe<Array<CashDeskWhereInput>>;
  NOT?: InputMaybe<Array<CashDeskWhereInput>>;
  OR?: InputMaybe<Array<CashDeskWhereInput>>;
  branchOffice?: InputMaybe<BranchOfficeRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  id_branchOfficeId?: InputMaybe<CashDeskIdBranchOfficeIdCompoundUniqueInput>;
  name?: InputMaybe<StringFilter>;
  scannedTransactions?: InputMaybe<ScannedTransactionListRelationFilter>;
};

/** Custom type for changing point system of company */
export type ChangePointSystemInput = {
  id: Scalars['String']['input'];
  /** Must be set to change from VISIT BASED to VALUE BASED */
  valueBasedInput?: InputMaybe<ChangeToValueBasedInput>;
  /** Must be set to change from VALUE BASED to VISIT BASED */
  visitBasedInput?: InputMaybe<ChangeToVisitBasedInput>;
};

/** Custom input to change system type to PERCENTAGE */
export type ChangeToPercentageInput = {
  calculationRule: CustomCalculationRuleInput;
};

/** Custom input to change system type to STAGGERED */
export type ChangeToStaggeredInput = {
  /** The new calculation rules for the new system type STAGGERED (up to 5 rules) */
  calculationRules: Array<CustomCalculationRuleInput>;
};

/** Input to change to value based system type (PERCENTAGE or STAGGERED). Only one of PERCENTAGE or STAGGERED input can be set! */
export type ChangeToValueBasedInput = {
  /** Must be set for change from VISIT BASED or VALUE BASED (STAGGERED) to VALUE BASED (PERCENTAGE) system type */
  percentageInput?: InputMaybe<ChangeToPercentageInput>;
  /** Must be set for change to STAGGERED system type */
  staggeredInput?: InputMaybe<ChangeToStaggeredInput>;
};

/** Custom input to change system type to VISIT BASED */
export type ChangeToVisitBasedInput = {
  /** Point amount per visit */
  pointsPerVisit?: Scalars['Float']['input'];
  scanLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type Company = {
  __typename: 'Company';
  _count: CompanyCount;
  /** @deprecated Use street and house number instead */
  address?: Maybe<Scalars['String']['output']>;
  balances?: Maybe<Array<Balance>>;
  branchOffices?: Maybe<Array<BranchOffice>>;
  calculationRules?: Maybe<Array<CalculationRule>>;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  city: Scalars['String']['output'];
  countryCode: Scalars['CountryCode']['output'];
  coupons?: Maybe<Array<Coupon>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['EmailAddress']['output'];
  employees?: Maybe<Array<Employee>>;
  houseNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  industry: Industry;
  industryId: Scalars['UUID']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notificationHistory: Array<NotificationHistory>;
  notifications?: Maybe<Array<NotificationHistory>>;
  organizationName: Scalars['String']['output'];
  paymentCustomerId?: Maybe<Scalars['String']['output']>;
  pointSystem?: Maybe<PointSystem>;
  postalCode: Scalars['PostalCode']['output'];
  promotions?: Maybe<Array<Promotion>>;
  receivedPoints?: Maybe<Array<ReceivedPoint>>;
  rewards?: Maybe<Array<Reward>>;
  state: Scalars['String']['output'];
  status: CompanyStatus;
  storeType?: Maybe<StoreType>;
  street: Scalars['String']['output'];
  subscriptionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  visible: Scalars['Boolean']['output'];
};


export type Company_CountArgs = {
  onlyActiveRewards?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CompanyCouponsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<CouponType>;
};


export type CompanyPromotionsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  filterInput?: InputMaybe<PromotionFilterInput>;
};


export type CompanyRewardsArgs = {
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<RewardStatus>;
};

export type CompanyCount = {
  __typename: 'CompanyCount';
  balances: Scalars['Int']['output'];
  branchOffices: Scalars['Int']['output'];
  calculationRules: Scalars['Int']['output'];
  coupons: Scalars['Int']['output'];
  employees: Scalars['Int']['output'];
  notifications: Scalars['Int']['output'];
  promotions: Scalars['Int']['output'];
  receivedPoints: Scalars['Int']['output'];
  rewards: Scalars['Int']['output'];
};

export type CompanyCountAggregate = {
  __typename: 'CompanyCountAggregate';
  _all: Scalars['Int']['output'];
  /** @deprecated Use street and house number instead */
  address: Scalars['Int']['output'];
  cancellationDate: Scalars['Int']['output'];
  city: Scalars['Int']['output'];
  countryCode: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  houseNumber: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  industryId: Scalars['Int']['output'];
  logoUrl: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  organizationName: Scalars['Int']['output'];
  paymentCustomerId: Scalars['Int']['output'];
  postalCode: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  storeType: Scalars['Int']['output'];
  street: Scalars['Int']['output'];
  subscriptionId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  visible: Scalars['Int']['output'];
};

export type CompanyCreateNestedManyWithoutIndustryInput = {
  connect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
};

/** Input for the company information */
export type CompanyInformationInput = {
  city: Scalars['String']['input'];
  countryCode: Scalars['CountryCode']['input'];
  email: Scalars['EmailAddress']['input'];
  houseNumber: Scalars['String']['input'];
  /** The industry id of the company */
  industryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['PostalCode']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type CompanyListRelationFilter = {
  every?: InputMaybe<CompanyWhereInput>;
  none?: InputMaybe<CompanyWhereInput>;
  some?: InputMaybe<CompanyWhereInput>;
};

export type CompanyLocationCompoundUniqueInput = {
  city: Scalars['String']['input'];
  countryCode: Scalars['CountryCode']['input'];
  houseNumber: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['PostalCode']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

/** Input for company logo. */
export type CompanyLogoInput = {
  /** Upload a new logo as file */
  fileUpload?: InputMaybe<Scalars['Upload']['input']>;
  /** The url of an existing hosted logo */
  fileUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyMaxAggregate = {
  __typename: 'CompanyMaxAggregate';
  /** @deprecated Use street and house number instead */
  address?: Maybe<Scalars['String']['output']>;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['CountryCode']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  industryId?: Maybe<Scalars['UUID']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  paymentCustomerId?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['PostalCode']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<CompanyStatus>;
  storeType?: Maybe<StoreType>;
  street?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyMinAggregate = {
  __typename: 'CompanyMinAggregate';
  /** @deprecated Use street and house number instead */
  address?: Maybe<Scalars['String']['output']>;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['CountryCode']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  industryId?: Maybe<Scalars['UUID']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  paymentCustomerId?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['PostalCode']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<CompanyStatus>;
  storeType?: Maybe<StoreType>;
  street?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CompanyOrderByWithRelationInput = {
  address?: InputMaybe<SortOrderInput>;
  balances?: InputMaybe<BalanceOrderByRelationAggregateInput>;
  branchOffices?: InputMaybe<BranchOfficeOrderByRelationAggregateInput>;
  calculationRules?: InputMaybe<CalculationRuleOrderByRelationAggregateInput>;
  city?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  coupons?: InputMaybe<CouponOrderByRelationAggregateInput>;
  description?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  employees?: InputMaybe<EmployeeOrderByRelationAggregateInput>;
  houseNumber?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  industry?: InputMaybe<IndustryOrderByWithRelationInput>;
  logoUrl?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  notifications?: InputMaybe<NotificationHistoryOrderByRelationAggregateInput>;
  organizationName?: InputMaybe<SortOrder>;
  paymentCustomerId?: InputMaybe<SortOrderInput>;
  pointSystem?: InputMaybe<PointSystemOrderByWithRelationInput>;
  postalCode?: InputMaybe<SortOrder>;
  promotions?: InputMaybe<PromotionOrderByRelationAggregateInput>;
  receivedPoints?: InputMaybe<ReceivedPointOrderByRelationAggregateInput>;
  rewards?: InputMaybe<RewardOrderByRelationAggregateInput>;
  state?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  storeType?: InputMaybe<SortOrderInput>;
  street?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrderInput>;
  visible?: InputMaybe<SortOrder>;
};

export type CompanyRelationFilter = {
  is?: InputMaybe<CompanyWhereInput>;
  isNot?: InputMaybe<CompanyWhereInput>;
};

export enum CompanyScalarFieldEnum {
  Address = 'address',
  CancellationDate = 'cancellationDate',
  City = 'city',
  CountryCode = 'countryCode',
  CreatedAt = 'createdAt',
  Description = 'description',
  Email = 'email',
  HouseNumber = 'houseNumber',
  Id = 'id',
  IndustryId = 'industryId',
  LogoUrl = 'logoUrl',
  Name = 'name',
  OrganizationName = 'organizationName',
  PaymentCustomerId = 'paymentCustomerId',
  PostalCode = 'postalCode',
  State = 'state',
  Status = 'status',
  StoreType = 'storeType',
  Street = 'street',
  SubscriptionId = 'subscriptionId',
  UpdatedAt = 'updatedAt',
  Visible = 'visible'
}

export type CompanyScalarWhereInput = {
  AND?: InputMaybe<Array<CompanyScalarWhereInput>>;
  NOT?: InputMaybe<Array<CompanyScalarWhereInput>>;
  OR?: InputMaybe<Array<CompanyScalarWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringFilter>;
  houseNumber?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  logoUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  organizationName?: InputMaybe<StringFilter>;
  paymentCustomerId?: InputMaybe<StringNullableFilter>;
  postalCode?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumCompanyStatusFilter>;
  storeType?: InputMaybe<EnumStoreTypeNullableFilter>;
  street?: InputMaybe<StringFilter>;
  subscriptionId?: InputMaybe<StringNullableFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export enum CompanyStatus {
  Blocked = 'BLOCKED',
  Canceled = 'CANCELED',
  CancellationRequested = 'CANCELLATION_REQUESTED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentPending = 'PAYMENT_PENDING',
  Registered = 'REGISTERED',
  Requested = 'REQUESTED'
}

/** Custom object type for updating company status from webhook event */
export type CompanyStatusUpdateModel = {
  __typename: 'CompanyStatusUpdateModel';
  /** Possible error message */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The ID of the updated company */
  id: Scalars['String']['output'];
  /** The new status of the company */
  status: CompanyStatus;
  /** The status of the update (succeeded, failed, skipped) */
  updateStatus: CompanyUpdateStatus;
};

/** Unique identifier of company */
export type CompanyUniqueInput = {
  /** The unique identifier of the company */
  id: Scalars['String']['input'];
};

export type CompanyUpdateManyMutationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['CountryCode']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  paymentCustomerId?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['PostalCode']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CompanyStatus>;
  storeType?: InputMaybe<StoreType>;
  street?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanyUpdateManyWithWhereWithoutIndustryInput = {
  data: CompanyUpdateManyMutationInput;
  where: CompanyScalarWhereInput;
};

export type CompanyUpdateManyWithoutIndustryNestedInput = {
  connect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  delete?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompanyScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  set?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<CompanyUpdateManyWithWhereWithoutIndustryInput>>;
};

export type CompanyUpdateOneRequiredWithoutBranchOfficesNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
};

export type CompanyUpdateOneRequiredWithoutCouponsNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
};

export type CompanyUpdateOneRequiredWithoutPointSystemNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
};

export type CompanyUpdateOneRequiredWithoutReceivedPointsNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
};

export type CompanyUpdateOneRequiredWithoutRewardsNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
};

/** Enum of status for company status updates */
export enum CompanyUpdateStatus {
  Failed = 'FAILED',
  Skipped = 'SKIPPED',
  Succeeded = 'SUCCEEDED'
}

export enum CompanyUserType {
  BusinessOwner = 'BUSINESS_OWNER',
  Employee = 'EMPLOYEE'
}

export type CompanyWhereInput = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  balances?: InputMaybe<BalanceListRelationFilter>;
  branchOffices?: InputMaybe<BranchOfficeListRelationFilter>;
  calculationRules?: InputMaybe<CalculationRuleListRelationFilter>;
  city?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<StringFilter>;
  coupons?: InputMaybe<CouponListRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringFilter>;
  employees?: InputMaybe<EmployeeListRelationFilter>;
  houseNumber?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  industry?: InputMaybe<IndustryRelationFilter>;
  logoUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  notifications?: InputMaybe<NotificationHistoryListRelationFilter>;
  organizationName?: InputMaybe<StringFilter>;
  paymentCustomerId?: InputMaybe<StringNullableFilter>;
  pointSystem?: InputMaybe<PointSystemNullableRelationFilter>;
  postalCode?: InputMaybe<StringFilter>;
  promotions?: InputMaybe<PromotionListRelationFilter>;
  receivedPoints?: InputMaybe<ReceivedPointListRelationFilter>;
  rewards?: InputMaybe<RewardListRelationFilter>;
  state?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumCompanyStatusFilter>;
  storeType?: InputMaybe<EnumStoreTypeNullableFilter>;
  street?: InputMaybe<StringFilter>;
  subscriptionId?: InputMaybe<StringNullableFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export type CompanyWhereUniqueInput = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  balances?: InputMaybe<BalanceListRelationFilter>;
  branchOffices?: InputMaybe<BranchOfficeListRelationFilter>;
  calculationRules?: InputMaybe<CalculationRuleListRelationFilter>;
  city?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<StringFilter>;
  coupons?: InputMaybe<CouponListRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringFilter>;
  employees?: InputMaybe<EmployeeListRelationFilter>;
  houseNumber?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<IndustryRelationFilter>;
  location?: InputMaybe<CompanyLocationCompoundUniqueInput>;
  logoUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  notifications?: InputMaybe<NotificationHistoryListRelationFilter>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  paymentCustomerId?: InputMaybe<Scalars['String']['input']>;
  pointSystem?: InputMaybe<PointSystemNullableRelationFilter>;
  postalCode?: InputMaybe<StringFilter>;
  promotions?: InputMaybe<PromotionListRelationFilter>;
  receivedPoints?: InputMaybe<ReceivedPointListRelationFilter>;
  rewards?: InputMaybe<RewardListRelationFilter>;
  state?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumCompanyStatusFilter>;
  storeType?: InputMaybe<EnumStoreTypeNullableFilter>;
  street?: InputMaybe<StringFilter>;
  subscriptionId?: InputMaybe<StringNullableFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export type Contact = {
  __typename: 'Contact';
  id: Scalars['ID']['output'];
  requestId?: Maybe<Scalars['String']['output']>;
};

export type ContactSupportInput = {
  error?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type Coupon = {
  __typename: 'Coupon';
  _count: CouponCount;
  active: Scalars['Boolean']['output'];
  company: Company;
  companyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  imageTemplate?: Maybe<ImageTemplate>;
  imageTemplateId?: Maybe<Scalars['UUID']['output']>;
  name: Scalars['String']['output'];
  pointsCredit?: Maybe<Scalars['Int']['output']>;
  redemptions?: Maybe<Array<RedeemedCoupon>>;
  type: CouponType;
  updatedAt: Scalars['DateTime']['output'];
};

export type CouponAvgAggregate = {
  __typename: 'CouponAvgAggregate';
  pointsCredit?: Maybe<Scalars['Float']['output']>;
};

export type CouponCount = {
  __typename: 'CouponCount';
  redemptions: Scalars['Int']['output'];
};

export type CouponCountAggregate = {
  __typename: 'CouponCountAggregate';
  _all: Scalars['Int']['output'];
  active: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageTemplateId: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  pointsCredit: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

/** Input for creating a coupon */
export type CouponCreateInput = {
  /** The ID of the company this coupon should be created for */
  companyId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  imageTemplate?: InputMaybe<ImageTemplateCreateNestedOneWithoutCouponsInput>;
  name: Scalars['String']['input'];
  pointsCredit?: InputMaybe<Scalars['Int']['input']>;
  /** The type of the coupon */
  type: CouponType;
};

export type CouponCreateNestedManyWithoutImageTemplateInput = {
  connect?: InputMaybe<Array<CouponWhereUniqueInput>>;
};

export type CouponListRelationFilter = {
  every?: InputMaybe<CouponWhereInput>;
  none?: InputMaybe<CouponWhereInput>;
  some?: InputMaybe<CouponWhereInput>;
};

export type CouponMaxAggregate = {
  __typename: 'CouponMaxAggregate';
  active?: Maybe<Scalars['Boolean']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  imageTemplateId?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pointsCredit?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<CouponType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CouponMinAggregate = {
  __typename: 'CouponMinAggregate';
  active?: Maybe<Scalars['Boolean']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  imageTemplateId?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pointsCredit?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<CouponType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CouponOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CouponOrderByWithRelationInput = {
  active?: InputMaybe<SortOrder>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  imageTemplate?: InputMaybe<ImageTemplateOrderByWithRelationInput>;
  name?: InputMaybe<SortOrder>;
  pointsCredit?: InputMaybe<SortOrderInput>;
  redemptions?: InputMaybe<RedeemedCouponOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
};

export type CouponRelationFilter = {
  is?: InputMaybe<CouponWhereInput>;
  isNot?: InputMaybe<CouponWhereInput>;
};

/** The report object for coupons */
export type CouponReportModel = {
  __typename: 'CouponReportModel';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  pointsCredit?: Maybe<Scalars['Int']['output']>;
  /** The number of times this coupon was redeemed */
  redemptions: Scalars['Float']['output'];
};

export enum CouponScalarFieldEnum {
  Active = 'active',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  ImageTemplateId = 'imageTemplateId',
  Name = 'name',
  PointsCredit = 'pointsCredit',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type CouponScalarWhereInput = {
  AND?: InputMaybe<Array<CouponScalarWhereInput>>;
  NOT?: InputMaybe<Array<CouponScalarWhereInput>>;
  OR?: InputMaybe<Array<CouponScalarWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  pointsCredit?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<EnumCouponTypeFilter>;
};

/** Enum of status of coupons  */
export enum CouponStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type CouponSumAggregate = {
  __typename: 'CouponSumAggregate';
  pointsCredit?: Maybe<Scalars['Int']['output']>;
};

export enum CouponType {
  Promotional = 'PROMOTIONAL',
  Welcome = 'WELCOME'
}

/** Input of unique identifier of a coupon */
export type CouponUniqueInput = {
  id: Scalars['String']['input'];
};

export type CouponUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutCouponsNestedInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageTemplate?: InputMaybe<ImageTemplateUpdateOneWithoutCouponsNestedInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  pointsCredit?: InputMaybe<Scalars['Int']['input']>;
  redemptions?: InputMaybe<RedeemedCouponUpdateManyWithoutCouponNestedInput>;
  type?: InputMaybe<CouponType>;
};

export type CouponUpdateManyMutationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pointsCredit?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<CouponType>;
};

export type CouponUpdateManyWithWhereWithoutImageTemplateInput = {
  data: CouponUpdateManyMutationInput;
  where: CouponScalarWhereInput;
};

export type CouponUpdateManyWithoutImageTemplateNestedInput = {
  connect?: InputMaybe<Array<CouponWhereUniqueInput>>;
  delete?: InputMaybe<Array<CouponWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<CouponUpdateManyWithWhereWithoutImageTemplateInput>>;
};

export type CouponWhereInput = {
  AND?: InputMaybe<Array<CouponWhereInput>>;
  NOT?: InputMaybe<Array<CouponWhereInput>>;
  OR?: InputMaybe<Array<CouponWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  imageTemplate?: InputMaybe<ImageTemplateNullableRelationFilter>;
  name?: InputMaybe<StringFilter>;
  pointsCredit?: InputMaybe<IntNullableFilter>;
  redemptions?: InputMaybe<RedeemedCouponListRelationFilter>;
  type?: InputMaybe<EnumCouponTypeFilter>;
};

export type CouponWhereUniqueInput = {
  AND?: InputMaybe<Array<CouponWhereInput>>;
  NOT?: InputMaybe<Array<CouponWhereInput>>;
  OR?: InputMaybe<Array<CouponWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageTemplate?: InputMaybe<ImageTemplateNullableRelationFilter>;
  name?: InputMaybe<StringFilter>;
  pointsCredit?: InputMaybe<IntNullableFilter>;
  redemptions?: InputMaybe<RedeemedCouponListRelationFilter>;
  type?: InputMaybe<EnumCouponTypeFilter>;
};

/** Custom input to create a new branch office. Optionally with one or more cash desks */
export type CreateBranchOfficeWithCashDeskInput = {
  /** Optional input to create some cash desks too */
  cashDesks?: InputMaybe<Array<CashDeskNestedCreateInput>>;
  city: Scalars['String']['input'];
  countryCode: Scalars['CountryCode']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  houseNumber: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['PostalCode']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

/** Custom input to create cash desk */
export type CreateCashDeskWithoutTransactionInput = {
  /** The unique identifier of the branch office the cash desk should be created for */
  branchOffice: BranchOfficeUniqueInput;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** Input for creating a notificaiton hisotry entry */
export type CreateNotificationHistoryInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  message: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<NotificationStatus>;
};

export type CreateOrChangeCalculationRuleInput = {
  /** Create a VALUE BASED calculation rule. */
  valueBasedInput?: InputMaybe<ChangeToValueBasedInput>;
  /** Create a VISIT BASED calculation rule. */
  visitBasedInput?: InputMaybe<ChangeToVisitBasedInput>;
};

/** Input for creating promotion period */
export type CreatePromotionPeriodInput = {
  activeFrom: Scalars['DateTime']['input'];
  activeUntil: Scalars['DateTime']['input'];
};

/** Custom input to create rewards */
export type CreateRewardInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  requiredPoints: Scalars['Int']['input'];
  saving?: InputMaybe<Scalars['Float']['input']>;
};

/** Input type for calculation rule with picked fields from original model */
export type CustomCalculationRuleInput = {
  points: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};

export type Customer = {
  __typename: 'Customer';
  _count: CustomerCount;
  /** @deprecated Use street and house number instead */
  address?: Maybe<Scalars['String']['output']>;
  balances?: Maybe<Array<Balance>>;
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['CountryCode']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  devices?: Maybe<Array<Scalars['String']['output']>>;
  email: Scalars['EmailAddress']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['PostalCode']['output']>;
  pushToken?: Maybe<Scalars['String']['output']>;
  receivedPoints?: Maybe<Array<ReceivedPoint>>;
  redeemedCoupons?: Maybe<Array<RedeemedCoupon>>;
  redeemedPoints?: Maybe<Array<RedeemedPoint>>;
  state?: Maybe<Scalars['String']['output']>;
  statistic?: Maybe<CustomerStatistic>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
};


export type CustomerBalancesArgs = {
  sortOrder?: InputMaybe<SortOrder>;
};

/** Information about customers achievements */
export type CustomerAchievement = {
  __typename: 'CustomerAchievement';
  company: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** name of reward or coupon */
  name: Scalars['String']['output'];
};

export type CustomerCount = {
  __typename: 'CustomerCount';
  balances: Scalars['Int']['output'];
  receivedPoints: Scalars['Int']['output'];
  redeemedCoupons: Scalars['Int']['output'];
  redeemedPoints: Scalars['Int']['output'];
};

export type CustomerCountAggregate = {
  __typename: 'CustomerCountAggregate';
  _all: Scalars['Int']['output'];
  /** @deprecated Use street and house number instead */
  address: Scalars['Int']['output'];
  birthDate: Scalars['Int']['output'];
  city: Scalars['Int']['output'];
  countryCode: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  devices: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  firstName: Scalars['Int']['output'];
  houseNumber: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['Int']['output'];
  postalCode: Scalars['Int']['output'];
  pushToken: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  street: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  username: Scalars['Int']['output'];
};

export type CustomerCreateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  balances?: InputMaybe<BalanceCreateNestedManyWithoutCustomerInput>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['CountryCode']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  devices?: InputMaybe<Array<Scalars['String']['input']>>;
  email: Scalars['EmailAddress']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['PostalCode']['input']>;
  pushToken?: InputMaybe<Scalars['String']['input']>;
  receivedPoints?: InputMaybe<ReceivedPointCreateNestedManyWithoutCustomerInput>;
  redeemedCoupons?: InputMaybe<RedeemedCouponCreateNestedManyWithoutCustomerInput>;
  redeemedPoints?: InputMaybe<RedeemedPointCreateNestedManyWithoutCustomerInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  statistic?: InputMaybe<CustomerStatisticCreateNestedOneWithoutCustomerInput>;
  street?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username: Scalars['String']['input'];
};

export type CustomerCreateNestedOneWithoutRedeemedPointsInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
};

export type CustomerLocationCompoundUniqueInput = {
  city: Scalars['String']['input'];
  countryCode: Scalars['CountryCode']['input'];
  email: Scalars['EmailAddress']['input'];
  houseNumber: Scalars['String']['input'];
  postalCode: Scalars['PostalCode']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type CustomerMaxAggregate = {
  __typename: 'CustomerMaxAggregate';
  /** @deprecated Use street and house number instead */
  address?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['CountryCode']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['PostalCode']['output']>;
  pushToken?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type CustomerMinAggregate = {
  __typename: 'CustomerMinAggregate';
  /** @deprecated Use street and house number instead */
  address?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['CountryCode']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['PostalCode']['output']>;
  pushToken?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type CustomerOrderByWithRelationInput = {
  address?: InputMaybe<SortOrderInput>;
  balances?: InputMaybe<BalanceOrderByRelationAggregateInput>;
  birthDate?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrderInput>;
  countryCode?: InputMaybe<SortOrderInput>;
  deletedAt?: InputMaybe<SortOrderInput>;
  devices?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrderInput>;
  houseNumber?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrderInput>;
  postalCode?: InputMaybe<SortOrderInput>;
  pushToken?: InputMaybe<SortOrderInput>;
  receivedPoints?: InputMaybe<ReceivedPointOrderByRelationAggregateInput>;
  redeemedCoupons?: InputMaybe<RedeemedCouponOrderByRelationAggregateInput>;
  redeemedPoints?: InputMaybe<RedeemedPointOrderByRelationAggregateInput>;
  state?: InputMaybe<SortOrderInput>;
  statistic?: InputMaybe<CustomerStatisticOrderByWithRelationInput>;
  street?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type CustomerRelationFilter = {
  is?: InputMaybe<CustomerWhereInput>;
  isNot?: InputMaybe<CustomerWhereInput>;
};

export enum CustomerScalarFieldEnum {
  Address = 'address',
  BirthDate = 'birthDate',
  City = 'city',
  CountryCode = 'countryCode',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Devices = 'devices',
  Email = 'email',
  FirstName = 'firstName',
  HouseNumber = 'houseNumber',
  Id = 'id',
  LastName = 'lastName',
  PostalCode = 'postalCode',
  PushToken = 'pushToken',
  State = 'state',
  Street = 'street',
  UpdatedAt = 'updatedAt',
  Username = 'username'
}

/** Possible states of customer */
export enum CustomerStateEnum {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type CustomerStatistic = {
  __typename: 'CustomerStatistic';
  collectedPoints: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  savedMoney: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CustomerStatisticAvgAggregate = {
  __typename: 'CustomerStatisticAvgAggregate';
  collectedPoints?: Maybe<Scalars['Float']['output']>;
  savedMoney?: Maybe<Scalars['Float']['output']>;
};

export type CustomerStatisticCountAggregate = {
  __typename: 'CustomerStatisticCountAggregate';
  _all: Scalars['Int']['output'];
  collectedPoints: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  savedMoney: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type CustomerStatisticCreateNestedOneWithoutCustomerInput = {
  connect?: InputMaybe<CustomerStatisticWhereUniqueInput>;
};

export type CustomerStatisticMaxAggregate = {
  __typename: 'CustomerStatisticMaxAggregate';
  collectedPoints?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  savedMoney?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerStatisticMinAggregate = {
  __typename: 'CustomerStatisticMinAggregate';
  collectedPoints?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  savedMoney?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerStatisticNullableRelationFilter = {
  is?: InputMaybe<CustomerStatisticWhereInput>;
  isNot?: InputMaybe<CustomerStatisticWhereInput>;
};

export type CustomerStatisticOrderByWithRelationInput = {
  collectedPoints?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  savedMoney?: InputMaybe<SortOrder>;
};

export enum CustomerStatisticScalarFieldEnum {
  CollectedPoints = 'collectedPoints',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  Id = 'id',
  SavedMoney = 'savedMoney',
  UpdatedAt = 'updatedAt'
}

export type CustomerStatisticSumAggregate = {
  __typename: 'CustomerStatisticSumAggregate';
  collectedPoints?: Maybe<Scalars['Int']['output']>;
  savedMoney?: Maybe<Scalars['Float']['output']>;
};

export type CustomerStatisticUpdateOneWithoutCustomerNestedInput = {
  connect?: InputMaybe<CustomerStatisticWhereUniqueInput>;
  delete?: InputMaybe<CustomerStatisticWhereInput>;
  disconnect?: InputMaybe<CustomerStatisticWhereInput>;
};

export type CustomerStatisticWhereInput = {
  AND?: InputMaybe<Array<CustomerStatisticWhereInput>>;
  NOT?: InputMaybe<Array<CustomerStatisticWhereInput>>;
  OR?: InputMaybe<Array<CustomerStatisticWhereInput>>;
  collectedPoints?: InputMaybe<IntFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  savedMoney?: InputMaybe<FloatFilter>;
};

export type CustomerStatisticWhereUniqueInput = {
  AND?: InputMaybe<Array<CustomerStatisticWhereInput>>;
  NOT?: InputMaybe<Array<CustomerStatisticWhereInput>>;
  OR?: InputMaybe<Array<CustomerStatisticWhereInput>>;
  collectedPoints?: InputMaybe<IntFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  savedMoney?: InputMaybe<FloatFilter>;
};

export type CustomerUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  balances?: InputMaybe<BalanceUpdateManyWithoutCustomerNestedInput>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['CountryCode']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  devices?: InputMaybe<Array<Scalars['String']['input']>>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['PostalCode']['input']>;
  pushToken?: InputMaybe<Scalars['String']['input']>;
  receivedPoints?: InputMaybe<ReceivedPointUpdateManyWithoutCustomerNestedInput>;
  redeemedCoupons?: InputMaybe<RedeemedCouponUpdateManyWithoutCustomerNestedInput>;
  redeemedPoints?: InputMaybe<RedeemedPointUpdateManyWithoutCustomerNestedInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  statistic?: InputMaybe<CustomerStatisticUpdateOneWithoutCustomerNestedInput>;
  street?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerUpdateOneRequiredWithoutReceivedPointsNestedInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
};

export type CustomerUpdateOneRequiredWithoutRedeemedPointsNestedInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
};

export type CustomerWhereInput = {
  AND?: InputMaybe<Array<CustomerWhereInput>>;
  NOT?: InputMaybe<Array<CustomerWhereInput>>;
  OR?: InputMaybe<Array<CustomerWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  balances?: InputMaybe<BalanceListRelationFilter>;
  birthDate?: InputMaybe<DateTimeNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  countryCode?: InputMaybe<StringNullableFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  devices?: InputMaybe<StringNullableListFilter>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  houseNumber?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  postalCode?: InputMaybe<StringNullableFilter>;
  pushToken?: InputMaybe<StringNullableFilter>;
  receivedPoints?: InputMaybe<ReceivedPointListRelationFilter>;
  redeemedCoupons?: InputMaybe<RedeemedCouponListRelationFilter>;
  redeemedPoints?: InputMaybe<RedeemedPointListRelationFilter>;
  state?: InputMaybe<StringNullableFilter>;
  statistic?: InputMaybe<CustomerStatisticNullableRelationFilter>;
  street?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringFilter>;
};

export type CustomerWhereUniqueInput = {
  AND?: InputMaybe<Array<CustomerWhereInput>>;
  NOT?: InputMaybe<Array<CustomerWhereInput>>;
  OR?: InputMaybe<Array<CustomerWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  balances?: InputMaybe<BalanceListRelationFilter>;
  birthDate?: InputMaybe<DateTimeNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  countryCode?: InputMaybe<StringNullableFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  devices?: InputMaybe<StringNullableListFilter>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  firstName?: InputMaybe<StringNullableFilter>;
  houseNumber?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<StringNullableFilter>;
  location?: InputMaybe<CustomerLocationCompoundUniqueInput>;
  postalCode?: InputMaybe<StringNullableFilter>;
  pushToken?: InputMaybe<StringNullableFilter>;
  receivedPoints?: InputMaybe<ReceivedPointListRelationFilter>;
  redeemedCoupons?: InputMaybe<RedeemedCouponListRelationFilter>;
  redeemedPoints?: InputMaybe<RedeemedPointListRelationFilter>;
  state?: InputMaybe<StringNullableFilter>;
  statistic?: InputMaybe<CustomerStatisticNullableRelationFilter>;
  street?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringFilter>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

/** Input for deletion of subscription items */
export type DeleteSubscriptionItemInput = {
  /** The product to delete */
  product: StripeSubscriptionProduct;
};

/** Custom input type for creation of received points from ecommerce stores */
export type EcomStoreReceivedPointCreateInput = {
  storeType: StoreType;
  valueBasedInput?: InputMaybe<EcomStoreValueBasedInput>;
  visitBasedInput?: InputMaybe<VisitBasedInput>;
};

export type EcomStoreValueBasedInput = {
  amount: Scalars['Float']['input'];
  companyId: Scalars['String']['input'];
};

export type Employee = {
  __typename: 'Employee';
  company: Company;
  companyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['EmailAddress']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invitationAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  status: EmployeeStatus;
  updatedAt: Scalars['DateTime']['output'];
  userType: CompanyUserType;
};

export type EmployeeCountAggregate = {
  __typename: 'EmployeeCountAggregate';
  _all: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  firstName: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  invitationAcceptedAt: Scalars['Int']['output'];
  lastName: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userType: Scalars['Int']['output'];
};

export type EmployeeEmployeeIdentifierCompoundUniqueInput = {
  companyId: Scalars['String']['input'];
  email: Scalars['EmailAddress']['input'];
};

/** Input of employee identifier with optional field companyId */
export type EmployeeIdentifierInput = {
  /** The ID of the company the employee is member of */
  companyId?: InputMaybe<Scalars['String']['input']>;
  /** The EMail address of the employee to be delted */
  email: Scalars['EmailAddress']['input'];
};

export type EmployeeListRelationFilter = {
  every?: InputMaybe<EmployeeWhereInput>;
  none?: InputMaybe<EmployeeWhereInput>;
  some?: InputMaybe<EmployeeWhereInput>;
};

export type EmployeeMaxAggregate = {
  __typename: 'EmployeeMaxAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invitationAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EmployeeStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userType?: Maybe<CompanyUserType>;
};

export type EmployeeMinAggregate = {
  __typename: 'EmployeeMinAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invitationAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EmployeeStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userType?: Maybe<CompanyUserType>;
};

export type EmployeeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EmployeeOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrderInput>;
  lastName?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  userType?: InputMaybe<SortOrder>;
};

export enum EmployeeScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  Id = 'id',
  InvitationAcceptedAt = 'invitationAcceptedAt',
  LastName = 'lastName',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UserType = 'userType'
}

export enum EmployeeStatus {
  Invited = 'INVITED',
  Registered = 'REGISTERED'
}

/** Unique input to identify an employee */
export type EmployeeUniqueInput = {
  /** The unique combination of email address and company id */
  employeeIdentifier: EmployeeEmployeeIdentifierCompoundUniqueInput;
};

export type EmployeeWhereInput = {
  AND?: InputMaybe<Array<EmployeeWhereInput>>;
  NOT?: InputMaybe<Array<EmployeeWhereInput>>;
  OR?: InputMaybe<Array<EmployeeWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringNullableFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumEmployeeStatusFilter>;
  userType?: InputMaybe<EnumCompanyUserTypeFilter>;
};

export type EmployeeWhereUniqueInput = {
  AND?: InputMaybe<Array<EmployeeWhereInput>>;
  NOT?: InputMaybe<Array<EmployeeWhereInput>>;
  OR?: InputMaybe<Array<EmployeeWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringFilter>;
  employeeIdentifier?: InputMaybe<EmployeeEmployeeIdentifierCompoundUniqueInput>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumEmployeeStatusFilter>;
  userType?: InputMaybe<EnumCompanyUserTypeFilter>;
};

export type EnumCompanyStatusFilter = {
  equals?: InputMaybe<CompanyStatus>;
  in?: InputMaybe<Array<CompanyStatus>>;
  not?: InputMaybe<NestedEnumCompanyStatusFilter>;
  notIn?: InputMaybe<Array<CompanyStatus>>;
};

export type EnumCompanyUserTypeFilter = {
  equals?: InputMaybe<CompanyUserType>;
  in?: InputMaybe<Array<CompanyUserType>>;
  not?: InputMaybe<NestedEnumCompanyUserTypeFilter>;
  notIn?: InputMaybe<Array<CompanyUserType>>;
};

export type EnumCouponTypeFilter = {
  equals?: InputMaybe<CouponType>;
  in?: InputMaybe<Array<CouponType>>;
  not?: InputMaybe<NestedEnumCouponTypeFilter>;
  notIn?: InputMaybe<Array<CouponType>>;
};

export type EnumEmployeeStatusFilter = {
  equals?: InputMaybe<EmployeeStatus>;
  in?: InputMaybe<Array<EmployeeStatus>>;
  not?: InputMaybe<NestedEnumEmployeeStatusFilter>;
  notIn?: InputMaybe<Array<EmployeeStatus>>;
};

export type EnumNotificationStatusFilter = {
  equals?: InputMaybe<NotificationStatus>;
  in?: InputMaybe<Array<NotificationStatus>>;
  not?: InputMaybe<NestedEnumNotificationStatusFilter>;
  notIn?: InputMaybe<Array<NotificationStatus>>;
};

export type EnumReceivedPointSourceTypeFilter = {
  equals?: InputMaybe<ReceivedPointSourceType>;
  in?: InputMaybe<Array<ReceivedPointSourceType>>;
  not?: InputMaybe<NestedEnumReceivedPointSourceTypeFilter>;
  notIn?: InputMaybe<Array<ReceivedPointSourceType>>;
};

export type EnumStoreTypeFilter = {
  equals?: InputMaybe<StoreType>;
  in?: InputMaybe<Array<StoreType>>;
  not?: InputMaybe<NestedEnumStoreTypeFilter>;
  notIn?: InputMaybe<Array<StoreType>>;
};

export type EnumStoreTypeNullableFilter = {
  equals?: InputMaybe<StoreType>;
  in?: InputMaybe<Array<StoreType>>;
  not?: InputMaybe<NestedEnumStoreTypeNullableFilter>;
  notIn?: InputMaybe<Array<StoreType>>;
};

export type EnumSystemTypeFilter = {
  equals?: InputMaybe<SystemType>;
  in?: InputMaybe<Array<SystemType>>;
  not?: InputMaybe<NestedEnumSystemTypeFilter>;
  notIn?: InputMaybe<Array<SystemType>>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type ImageTemplate = {
  __typename: 'ImageTemplate';
  _count: ImageTemplateCount;
  coupons?: Maybe<Array<Coupon>>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  imageKey: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rewards?: Maybe<Array<Reward>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ImageTemplateCount = {
  __typename: 'ImageTemplateCount';
  coupons: Scalars['Int']['output'];
  rewards: Scalars['Int']['output'];
};

export type ImageTemplateCountAggregate = {
  __typename: 'ImageTemplateCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageKey: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ImageTemplateCreateInput = {
  coupons?: InputMaybe<CouponCreateNestedManyWithoutImageTemplateInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageKey: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rewards?: InputMaybe<RewardCreateNestedManyWithoutImageTemplateInput>;
};

export type ImageTemplateCreateNestedOneWithoutCouponsInput = {
  connect?: InputMaybe<ImageTemplateWhereUniqueInput>;
};

export type ImageTemplateMaxAggregate = {
  __typename: 'ImageTemplateMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ImageTemplateMinAggregate = {
  __typename: 'ImageTemplateMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ImageTemplateNullableRelationFilter = {
  is?: InputMaybe<ImageTemplateWhereInput>;
  isNot?: InputMaybe<ImageTemplateWhereInput>;
};

export type ImageTemplateOrderByWithRelationInput = {
  coupons?: InputMaybe<CouponOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  imageKey?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  rewards?: InputMaybe<RewardOrderByRelationAggregateInput>;
};

export enum ImageTemplateScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  ImageKey = 'imageKey',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type ImageTemplateUpdateInput = {
  coupons?: InputMaybe<CouponUpdateManyWithoutImageTemplateNestedInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rewards?: InputMaybe<RewardUpdateManyWithoutImageTemplateNestedInput>;
};

export type ImageTemplateUpdateOneWithoutCouponsNestedInput = {
  connect?: InputMaybe<ImageTemplateWhereUniqueInput>;
  delete?: InputMaybe<ImageTemplateWhereInput>;
  disconnect?: InputMaybe<ImageTemplateWhereInput>;
};

export type ImageTemplateUpdateOneWithoutRewardsNestedInput = {
  connect?: InputMaybe<ImageTemplateWhereUniqueInput>;
  delete?: InputMaybe<ImageTemplateWhereInput>;
  disconnect?: InputMaybe<ImageTemplateWhereInput>;
};

export type ImageTemplateWhereInput = {
  AND?: InputMaybe<Array<ImageTemplateWhereInput>>;
  NOT?: InputMaybe<Array<ImageTemplateWhereInput>>;
  OR?: InputMaybe<Array<ImageTemplateWhereInput>>;
  coupons?: InputMaybe<CouponListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  imageKey?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  rewards?: InputMaybe<RewardListRelationFilter>;
};

export type ImageTemplateWhereUniqueInput = {
  AND?: InputMaybe<Array<ImageTemplateWhereInput>>;
  NOT?: InputMaybe<Array<ImageTemplateWhereInput>>;
  OR?: InputMaybe<Array<ImageTemplateWhereInput>>;
  coupons?: InputMaybe<CouponListRelationFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageKey?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  rewards?: InputMaybe<RewardListRelationFilter>;
};

export type Industry = {
  __typename: 'Industry';
  _count: IndustryCount;
  companies?: Maybe<Array<Company>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type IndustryCount = {
  __typename: 'IndustryCount';
  companies: Scalars['Int']['output'];
};

export type IndustryCountAggregate = {
  __typename: 'IndustryCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type IndustryCreateInput = {
  companies?: InputMaybe<CompanyCreateNestedManyWithoutIndustryInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
};

export type IndustryCreateNestedOneWithoutCompaniesInput = {
  connect?: InputMaybe<IndustryWhereUniqueInput>;
};

export type IndustryMaxAggregate = {
  __typename: 'IndustryMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type IndustryMinAggregate = {
  __typename: 'IndustryMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type IndustryOrderByWithRelationInput = {
  companies?: InputMaybe<CompanyOrderByRelationAggregateInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type IndustryRelationFilter = {
  is?: InputMaybe<IndustryWhereInput>;
  isNot?: InputMaybe<IndustryWhereInput>;
};

export enum IndustryScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type IndustryUpdateInput = {
  companies?: InputMaybe<CompanyUpdateManyWithoutIndustryNestedInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type IndustryUpdateOneRequiredWithoutCompaniesNestedInput = {
  connect?: InputMaybe<IndustryWhereUniqueInput>;
};

export type IndustryWhereInput = {
  AND?: InputMaybe<Array<IndustryWhereInput>>;
  NOT?: InputMaybe<Array<IndustryWhereInput>>;
  OR?: InputMaybe<Array<IndustryWhereInput>>;
  companies?: InputMaybe<CompanyListRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
};

export type IndustryWhereUniqueInput = {
  AND?: InputMaybe<Array<IndustryWhereInput>>;
  NOT?: InputMaybe<Array<IndustryWhereInput>>;
  OR?: InputMaybe<Array<IndustryWhereInput>>;
  companies?: InputMaybe<CompanyListRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<StringFilter>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Custom input to create received points from local stores */
export type LocalStoreReceivedPointCreateInput = {
  storeType: StoreType;
  valueBasedInput?: InputMaybe<LocalStoreValueBasedInput>;
  visitBasedInput?: InputMaybe<VisitBasedInput>;
};

export type LocalStoreValueBasedInput = {
  amount: Scalars['Float']['input'];
  cashDeskId: Scalars['String']['input'];
  transactionNumber: Scalars['Float']['input'];
};

/** Model for created Jobs of management client */
export type ManagementJobModel = {
  __typename: 'ManagementJobModel';
  created_at?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** Represent all months */
export enum Month {
  April = 'APRIL',
  August = 'AUGUST',
  December = 'DECEMBER',
  February = 'FEBRUARY',
  January = 'JANUARY',
  July = 'JULY',
  June = 'JUNE',
  March = 'MARCH',
  May = 'MAY',
  November = 'NOVEMBER',
  October = 'OCTOBER',
  September = 'SEPTEMBER'
}

export type Mutation = {
  __typename: 'Mutation';
  cancelSubscriptionAtNoticePeriod: PaymentSubscription;
  /** This endpoint is triggered by the payment provider webhook. The company status will be changed to the given status */
  changeCompanyStatus: CompanyStatusUpdateModel;
  changeCustomerState: Customer;
  changePointSystem: PointSystem;
  changeVisibility: Company;
  contactSupport: Contact;
  createBranchOffice: BranchOffice;
  createCashDesk: Array<CashDesk>;
  createCoupon: Coupon;
  createCustomer: Customer;
  createEcomStorePoint: ReceivedPoint;
  createImageTemplate: ImageTemplate;
  createIndustry: Industry;
  createLocalStorePoint: ReceivedPoint;
  createManuallyReceivedPoint: ReceivedPoint;
  createNotificationHistory: NotificationHistory;
  createOrChangeCalculationRule: Array<CalculationRule>;
  /** Creates a customer at the payment provider. If this was successful, a subscription will be created at the same time and the necessary information will be saved in the REBAC system. */
  createPaymentCustomer: Payment;
  createPointSystem: PointSystem;
  createPointSystemType: PointSystemType;
  createPromotion: Promotion;
  createPromotionPeriod: PromotionPeriod;
  createRedeemedPoint: RedeemedPoint;
  createReward: Reward;
  createScannedTransaction: ScannedTransaction;
  /** Creates a subscription for an existing customer. */
  createSubscription: Payment;
  createSubscriptionItem: Array<SubscriptionItemResultModel>;
  deleteBranchOffice: BranchOffice;
  deleteCalculationRule: CalculationRule;
  deleteCashDesk: CashDesk;
  deleteCompany: Company;
  deleteCoupon: Coupon;
  deleteCustomer: Customer;
  deleteEmployee: Employee;
  deleteImageTemplate: ImageTemplate;
  deleteIndustry: Industry;
  /** Deletes the customer account with all associated invoices, subscriptions, etc. */
  deletePaymentCustomer: PaymentDeletedCustomer;
  deletePointSystem: PointSystem;
  deletePointSystemType: PointSystemType;
  deletePromotion: Promotion;
  deletePromotionPeriod: PromotionPeriod;
  deleteReceivedPoint: ReceivedPoint;
  deleteRedeemedPoint: RedeemedPoint;
  deleteReward: Reward;
  deleteScannedTransaction: ScannedTransaction;
  deleteSubscriptionItem: Array<SubscriptionItemResultModel>;
  /** Invite a employee into a organization */
  inviteEmployee: Employee;
  processNotifications: Array<NotificationHistory>;
  redeemCoupon: RedeemedCoupon;
  redeemPromotionCode: PaymentSubscription;
  registerCompany: Company;
  registerInvitedEmployee: Employee;
  resendVerificationMail: ManagementJobModel;
  sendPasswordChangeEmail: Employee;
  /** Generate and send the report to the business owner of the company */
  sendReport: ReportModel;
  startRegistrationFlow: RegistrationFlowModel;
  switchCouponStatus: Coupon;
  /** Switch the status of reward to deleted (set deletedAt date) or active (set deletedAt to null) */
  switchRewardStatus: Reward;
  updateBranchOffice: BranchOffice;
  updateCashDesk: CashDesk;
  updateCompany: Company;
  updateCompanyLogo: Company;
  updateCoupon: Coupon;
  updateCustomer: Customer;
  updateEmployee: Employee;
  updateImageTemplate: ImageTemplate;
  updateIndustry: Industry;
  updatePointSystem: PointSystem;
  updatePointSystemType: PointSystemType;
  updatePromotionPeriod: PromotionPeriod;
  updateReceivedPoint: ReceivedPoint;
  updateRedeemedPoint: RedeemedPoint;
  updateReward: Reward;
  updateScannedTransaction: ScannedTransaction;
  upgradeSubscriptionItems: Array<SubscriptionItemResultModel>;
};


export type MutationChangeCompanyStatusArgs = {
  paymentCustomerId: Scalars['String']['input'];
  status: CompanyStatus;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationChangeCustomerStateArgs = {
  customerId: Scalars['String']['input'];
  state: CustomerStateEnum;
};


export type MutationChangePointSystemArgs = {
  input: ChangePointSystemInput;
};


export type MutationChangeVisibilityArgs = {
  visible: Scalars['Boolean']['input'];
};


export type MutationContactSupportArgs = {
  data: ContactSupportInput;
};


export type MutationCreateBranchOfficeArgs = {
  createInput: CreateBranchOfficeWithCashDeskInput;
};


export type MutationCreateCashDeskArgs = {
  createInput: Array<CreateCashDeskWithoutTransactionInput>;
};


export type MutationCreateCouponArgs = {
  data: CouponCreateInput;
};


export type MutationCreateCustomerArgs = {
  data: CustomerCreateInput;
};


export type MutationCreateEcomStorePointArgs = {
  createInput: EcomStoreReceivedPointCreateInput;
};


export type MutationCreateImageTemplateArgs = {
  data: ImageTemplateCreateInput;
};


export type MutationCreateIndustryArgs = {
  data: IndustryCreateInput;
};


export type MutationCreateLocalStorePointArgs = {
  createInput: LocalStoreReceivedPointCreateInput;
};


export type MutationCreateManuallyReceivedPointArgs = {
  count: Scalars['Float']['input'];
  customer: CustomerWhereUniqueInput;
};


export type MutationCreateNotificationHistoryArgs = {
  createInput: CreateNotificationHistoryInput;
};


export type MutationCreateOrChangeCalculationRuleArgs = {
  data: CreateOrChangeCalculationRuleInput;
};


export type MutationCreatePaymentCustomerArgs = {
  paymentCustomer: PaymentCreateCustomerInput;
  paymentSuccessRedirectUrl: Scalars['String']['input'];
};


export type MutationCreatePointSystemArgs = {
  createInput: PointSystemCreateInput;
};


export type MutationCreatePointSystemTypeArgs = {
  data: PointSystemTypeCreateInput;
};


export type MutationCreatePromotionArgs = {
  data: PromotionCreateInput;
};


export type MutationCreatePromotionPeriodArgs = {
  data: PromotionPeriodCreateInput;
};


export type MutationCreateRedeemedPointArgs = {
  data: RedeemedPointCreateInput;
};


export type MutationCreateRewardArgs = {
  data: CreateRewardInput;
};


export type MutationCreateScannedTransactionArgs = {
  data: ScannedTransactionCreateInput;
};


export type MutationCreateSubscriptionArgs = {
  paymentSubscription?: InputMaybe<PaymentCreateSubscriptionInput>;
  paymentSuccessRedirectUrl: Scalars['String']['input'];
};


export type MutationCreateSubscriptionItemArgs = {
  createInput: Array<SubscriptionItemInput>;
};


export type MutationDeleteBranchOfficeArgs = {
  where: BranchOfficeUniqueInput;
};


export type MutationDeleteCalculationRuleArgs = {
  where: CalculationRuleWhereUniqueInput;
};


export type MutationDeleteCashDeskArgs = {
  uniqueInput: CashDeskUniqueInput;
};


export type MutationDeleteCompanyArgs = {
  where: CompanyWhereUniqueInput;
};


export type MutationDeleteCouponArgs = {
  where: CouponWhereUniqueInput;
};


export type MutationDeleteCustomerArgs = {
  where: CustomerWhereUniqueInput;
};


export type MutationDeleteEmployeeArgs = {
  where: EmployeeIdentifierInput;
};


export type MutationDeleteImageTemplateArgs = {
  where: ImageTemplateWhereUniqueInput;
};


export type MutationDeleteIndustryArgs = {
  where: IndustryWhereUniqueInput;
};


export type MutationDeletePointSystemArgs = {
  where: PointSystemWhereUniqueInput;
};


export type MutationDeletePointSystemTypeArgs = {
  where: PointSystemTypeWhereUniqueInput;
};


export type MutationDeletePromotionArgs = {
  where: PromotionWhereUniqueInput;
};


export type MutationDeletePromotionPeriodArgs = {
  where: PromotionPeriodWhereUniqueInput;
};


export type MutationDeleteReceivedPointArgs = {
  where: ReceivedPointWhereUniqueInput;
};


export type MutationDeleteRedeemedPointArgs = {
  where: RedeemedPointWhereUniqueInput;
};


export type MutationDeleteRewardArgs = {
  where: RewardWhereUniqueInput;
};


export type MutationDeleteScannedTransactionArgs = {
  where: ScannedTransactionWhereUniqueInput;
};


export type MutationDeleteSubscriptionItemArgs = {
  deleteInput: Array<DeleteSubscriptionItemInput>;
};


export type MutationInviteEmployeeArgs = {
  email: Scalars['String']['input'];
};


export type MutationProcessNotificationsArgs = {
  notifications: Array<ProcessNotificationInput>;
};


export type MutationRedeemCouponArgs = {
  couponId: Scalars['String']['input'];
};


export type MutationRedeemPromotionCodeArgs = {
  promotionCodeId: Scalars['String']['input'];
};


export type MutationRegisterCompanyArgs = {
  registerInput: RegisterCompanyInput;
};


export type MutationRegisterInvitedEmployeeArgs = {
  data: RegisterEmployeeInput;
  where: EmployeeUniqueInput;
};


export type MutationResendVerificationMailArgs = {
  userId: Scalars['String']['input'];
};


export type MutationSendReportArgs = {
  filter?: InputMaybe<ReportFilterInput>;
};


export type MutationStartRegistrationFlowArgs = {
  accountInformation: AccountInformationInput;
  companyInformation: CompanyInformationInput;
};


export type MutationSwitchCouponStatusArgs = {
  data: SwitchCouponStatusInput;
  where: CouponUniqueInput;
};


export type MutationSwitchRewardStatusArgs = {
  input: SwitchRewardStatusInput;
};


export type MutationUpdateBranchOfficeArgs = {
  data: BranchOfficeUpdateInput;
  where: BranchOfficeWhereUniqueInput;
};


export type MutationUpdateCashDeskArgs = {
  updateInput: UpdateCashDeskArgs;
};


export type MutationUpdateCompanyArgs = {
  data: UpdateCompanyInput;
  where?: InputMaybe<CompanyUniqueInput>;
};


export type MutationUpdateCompanyLogoArgs = {
  input: CompanyLogoInput;
};


export type MutationUpdateCouponArgs = {
  data: CouponUpdateInput;
  where: CouponWhereUniqueInput;
};


export type MutationUpdateCustomerArgs = {
  data: CustomerUpdateInput;
  where: CustomerWhereUniqueInput;
};


export type MutationUpdateEmployeeArgs = {
  data: UpdateEmployeeInput;
  where?: InputMaybe<EmployeeUniqueInput>;
};


export type MutationUpdateImageTemplateArgs = {
  data: ImageTemplateUpdateInput;
  where: ImageTemplateWhereUniqueInput;
};


export type MutationUpdateIndustryArgs = {
  data: IndustryUpdateInput;
  where: IndustryWhereUniqueInput;
};


export type MutationUpdatePointSystemArgs = {
  data: PointSystemUpdateInput;
  where: PointSystemWhereUniqueInput;
};


export type MutationUpdatePointSystemTypeArgs = {
  data: PointSystemTypeUpdateInput;
  where: PointSystemTypeWhereUniqueInput;
};


export type MutationUpdatePromotionPeriodArgs = {
  data: PromotionPeriodUpdateInput;
  where: PromotionPeriodUniqueInput;
};


export type MutationUpdateReceivedPointArgs = {
  data: ReceivedPointUpdateInput;
  where: ReceivedPointWhereUniqueInput;
};


export type MutationUpdateRedeemedPointArgs = {
  data: RedeemedPointUpdateInput;
  where: RedeemedPointWhereUniqueInput;
};


export type MutationUpdateRewardArgs = {
  data: RewardUpdateInput;
  where: RewardWhereUniqueInput;
};


export type MutationUpdateScannedTransactionArgs = {
  data: ScannedTransactionUpdateInput;
  where: ScannedTransactionWhereUniqueInput;
};


export type MutationUpgradeSubscriptionItemsArgs = {
  upgradeInput: Array<SubscriptionItemInput>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

/** Custom input for nested creation of employee by registering a company */
export type NestedEmployeeCreateWithoutCompanyInput = {
  email: Scalars['EmailAddress']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type NestedEnumCompanyStatusFilter = {
  equals?: InputMaybe<CompanyStatus>;
  in?: InputMaybe<Array<CompanyStatus>>;
  not?: InputMaybe<NestedEnumCompanyStatusFilter>;
  notIn?: InputMaybe<Array<CompanyStatus>>;
};

export type NestedEnumCompanyUserTypeFilter = {
  equals?: InputMaybe<CompanyUserType>;
  in?: InputMaybe<Array<CompanyUserType>>;
  not?: InputMaybe<NestedEnumCompanyUserTypeFilter>;
  notIn?: InputMaybe<Array<CompanyUserType>>;
};

export type NestedEnumCouponTypeFilter = {
  equals?: InputMaybe<CouponType>;
  in?: InputMaybe<Array<CouponType>>;
  not?: InputMaybe<NestedEnumCouponTypeFilter>;
  notIn?: InputMaybe<Array<CouponType>>;
};

export type NestedEnumEmployeeStatusFilter = {
  equals?: InputMaybe<EmployeeStatus>;
  in?: InputMaybe<Array<EmployeeStatus>>;
  not?: InputMaybe<NestedEnumEmployeeStatusFilter>;
  notIn?: InputMaybe<Array<EmployeeStatus>>;
};

export type NestedEnumNotificationStatusFilter = {
  equals?: InputMaybe<NotificationStatus>;
  in?: InputMaybe<Array<NotificationStatus>>;
  not?: InputMaybe<NestedEnumNotificationStatusFilter>;
  notIn?: InputMaybe<Array<NotificationStatus>>;
};

export type NestedEnumReceivedPointSourceTypeFilter = {
  equals?: InputMaybe<ReceivedPointSourceType>;
  in?: InputMaybe<Array<ReceivedPointSourceType>>;
  not?: InputMaybe<NestedEnumReceivedPointSourceTypeFilter>;
  notIn?: InputMaybe<Array<ReceivedPointSourceType>>;
};

export type NestedEnumStoreTypeFilter = {
  equals?: InputMaybe<StoreType>;
  in?: InputMaybe<Array<StoreType>>;
  not?: InputMaybe<NestedEnumStoreTypeFilter>;
  notIn?: InputMaybe<Array<StoreType>>;
};

export type NestedEnumStoreTypeNullableFilter = {
  equals?: InputMaybe<StoreType>;
  in?: InputMaybe<Array<StoreType>>;
  not?: InputMaybe<NestedEnumStoreTypeNullableFilter>;
  notIn?: InputMaybe<Array<StoreType>>;
};

export type NestedEnumSystemTypeFilter = {
  equals?: InputMaybe<SystemType>;
  in?: InputMaybe<Array<SystemType>>;
  not?: InputMaybe<NestedEnumSystemTypeFilter>;
  notIn?: InputMaybe<Array<SystemType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedUuidFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NotificationHistory = {
  __typename: 'NotificationHistory';
  affectedCustomers: Scalars['Int']['output'];
  company: Company;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  message: Scalars['String']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  status: NotificationStatus;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type NotificationHistoryAvgAggregate = {
  __typename: 'NotificationHistoryAvgAggregate';
  affectedCustomers?: Maybe<Scalars['Float']['output']>;
};

export type NotificationHistoryCountAggregate = {
  __typename: 'NotificationHistoryCountAggregate';
  _all: Scalars['Int']['output'];
  affectedCustomers: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['Int']['output'];
  reason: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type NotificationHistoryListRelationFilter = {
  every?: InputMaybe<NotificationHistoryWhereInput>;
  none?: InputMaybe<NotificationHistoryWhereInput>;
  some?: InputMaybe<NotificationHistoryWhereInput>;
};

export type NotificationHistoryMaxAggregate = {
  __typename: 'NotificationHistoryMaxAggregate';
  affectedCustomers?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<NotificationStatus>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type NotificationHistoryMinAggregate = {
  __typename: 'NotificationHistoryMinAggregate';
  affectedCustomers?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<NotificationStatus>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type NotificationHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NotificationHistorySumAggregate = {
  __typename: 'NotificationHistorySumAggregate';
  affectedCustomers?: Maybe<Scalars['Int']['output']>;
};

export type NotificationHistoryWhereInput = {
  AND?: InputMaybe<Array<NotificationHistoryWhereInput>>;
  NOT?: InputMaybe<Array<NotificationHistoryWhereInput>>;
  OR?: InputMaybe<Array<NotificationHistoryWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  message?: InputMaybe<StringFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumNotificationStatusFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum NotificationStatus {
  Error = 'ERROR',
  Rejected = 'REJECTED',
  Review = 'REVIEW',
  Sent = 'SENT'
}

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type Payment = {
  __typename: 'Payment';
  paymentCustomerId: Scalars['String']['output'];
  /** Payment page, which creates a direct link to the payment providers and not via your own packages. */
  paymentUrl: Scalars['String']['output'];
};

export type PaymentCreateCustomerInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  email: Scalars['EmailAddress']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  /** Create a subscription during customer creation if this is set */
  subscription?: InputMaybe<PaymentCreateSubscriptionInput>;
};

export type PaymentCreateSubscriptionInput = {
  /** Free trial period in days */
  freeTrial?: InputMaybe<Scalars['Float']['input']>;
  /** Array of product extensions. The basic package is set by default */
  products?: InputMaybe<Array<SubscriptionItemInput>>;
  /** Code that the user enters. For example: REBAC20 */
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  /** Id of the code that the user enters. For example: promo_xxxxx */
  promotionCodeId?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentDeletedCustomer = {
  __typename: 'PaymentDeletedCustomer';
  deleted: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

export type PaymentInvoices = {
  __typename: 'PaymentInvoices';
  charge?: Maybe<Scalars['String']['output']>;
  created: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  customer?: Maybe<Scalars['String']['output']>;
  customerAddress?: Maybe<Scalars['String']['output']>;
  customerEmail?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hostedInvoiceUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoicePdf?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  periodEnd: Scalars['Float']['output'];
  periodStart: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  subscription?: Maybe<Scalars['String']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
};

export type PaymentPrices = {
  __typename: 'PaymentPrices';
  /** Whether the price can be used for new purchases. */
  active: Scalars['Boolean']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** A brief description of the price, hidden from customers. */
  nickname?: Maybe<Scalars['String']['output']>;
  product: Scalars['String']['output'];
  type: Scalars['String']['output'];
  /** The unit amount in paise to be charged, represented as a whole integer if possible. */
  unitAmount: Scalars['Float']['output'];
};

export type PaymentSubscription = {
  __typename: 'PaymentSubscription';
  billingCycleAnchor: Scalars['Float']['output'];
  cancelAt?: Maybe<Scalars['Float']['output']>;
  cancelAtPeriodEnd: Scalars['Boolean']['output'];
  canceledAt?: Maybe<Scalars['Float']['output']>;
  created: Scalars['Float']['output'];
  currentPeriodEnd: Scalars['Float']['output'];
  currentPeriodStart: Scalars['Float']['output'];
  customer?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  latestInvoice?: Maybe<Scalars['String']['output']>;
  /** The current quantity of each in product in this subscription */
  productQuantities?: Maybe<Array<ProductQuantityInformation>>;
  status: Scalars['String']['output'];
};

/** Custom object type for information about the next cancellation date for a subscription */
export type PaymentSubscriptionNextCancellationDateModel = {
  __typename: 'PaymentSubscriptionNextCancellationDateModel';
  /** The next cancellation date of the subscription. always one year after creation or (if the subscription lasts longer than one year, one year after the last extension) */
  cancellationDate: Scalars['DateTime']['output'];
  /** The current date */
  currentDate: Scalars['DateTime']['output'];
  /** creation date of the subscription */
  subscriptionCreated: Scalars['DateTime']['output'];
  /** The ID of the subscription */
  subscriptionId: Scalars['String']['output'];
};

export type PointSystem = {
  __typename: 'PointSystem';
  calculationRuleEntries: Scalars['Int']['output'];
  company: Company;
  companyId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  pointSystemType: PointSystemType;
  pointSystemTypeId: Scalars['UUID']['output'];
  scanLimit: Scalars['Int']['output'];
};

export type PointSystemAvgAggregate = {
  __typename: 'PointSystemAvgAggregate';
  calculationRuleEntries?: Maybe<Scalars['Float']['output']>;
  scanLimit?: Maybe<Scalars['Float']['output']>;
};

export type PointSystemCountAggregate = {
  __typename: 'PointSystemCountAggregate';
  _all: Scalars['Int']['output'];
  calculationRuleEntries: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  pointSystemTypeId: Scalars['Int']['output'];
  scanLimit: Scalars['Int']['output'];
};

/** Input for creation of point system */
export type PointSystemCreateInput = {
  pointSystemType: PointSystemTypeCreateNestedOneWithoutPointSystemInput;
  /** Only allowed for system type VISIT_BASED. In combination with other system types, an error will be thrown */
  scanLimit?: InputMaybe<Scalars['Float']['input']>;
};

export type PointSystemCreateNestedManyWithoutPointSystemTypeInput = {
  connect?: InputMaybe<Array<PointSystemWhereUniqueInput>>;
};

export type PointSystemListRelationFilter = {
  every?: InputMaybe<PointSystemWhereInput>;
  none?: InputMaybe<PointSystemWhereInput>;
  some?: InputMaybe<PointSystemWhereInput>;
};

export type PointSystemMaxAggregate = {
  __typename: 'PointSystemMaxAggregate';
  calculationRuleEntries?: Maybe<Scalars['Int']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  pointSystemTypeId?: Maybe<Scalars['UUID']['output']>;
  scanLimit?: Maybe<Scalars['Int']['output']>;
};

export type PointSystemMinAggregate = {
  __typename: 'PointSystemMinAggregate';
  calculationRuleEntries?: Maybe<Scalars['Int']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  pointSystemTypeId?: Maybe<Scalars['UUID']['output']>;
  scanLimit?: Maybe<Scalars['Int']['output']>;
};

export type PointSystemNullableRelationFilter = {
  is?: InputMaybe<PointSystemWhereInput>;
  isNot?: InputMaybe<PointSystemWhereInput>;
};

export type PointSystemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PointSystemOrderByWithRelationInput = {
  calculationRuleEntries?: InputMaybe<SortOrder>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  pointSystemType?: InputMaybe<PointSystemTypeOrderByWithRelationInput>;
  scanLimit?: InputMaybe<SortOrder>;
};

export enum PointSystemScalarFieldEnum {
  CalculationRuleEntries = 'calculationRuleEntries',
  CompanyId = 'companyId',
  Id = 'id',
  PointSystemTypeId = 'pointSystemTypeId',
  ScanLimit = 'scanLimit'
}

export type PointSystemScalarWhereInput = {
  AND?: InputMaybe<Array<PointSystemScalarWhereInput>>;
  NOT?: InputMaybe<Array<PointSystemScalarWhereInput>>;
  OR?: InputMaybe<Array<PointSystemScalarWhereInput>>;
  calculationRuleEntries?: InputMaybe<IntFilter>;
  id?: InputMaybe<UuidFilter>;
  scanLimit?: InputMaybe<IntFilter>;
};

export type PointSystemSumAggregate = {
  __typename: 'PointSystemSumAggregate';
  calculationRuleEntries?: Maybe<Scalars['Int']['output']>;
  scanLimit?: Maybe<Scalars['Int']['output']>;
};

export type PointSystemType = {
  __typename: 'PointSystemType';
  _count: PointSystemTypeCount;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  pointSystem?: Maybe<Array<PointSystem>>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type PointSystemTypeCount = {
  __typename: 'PointSystemTypeCount';
  pointSystem: Scalars['Int']['output'];
};

export type PointSystemTypeCountAggregate = {
  __typename: 'PointSystemTypeCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type PointSystemTypeCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  pointSystem?: InputMaybe<PointSystemCreateNestedManyWithoutPointSystemTypeInput>;
  type?: InputMaybe<SystemType>;
};

export type PointSystemTypeCreateNestedOneWithoutPointSystemInput = {
  connect?: InputMaybe<PointSystemTypeWhereUniqueInput>;
};

export type PointSystemTypeMaxAggregate = {
  __typename: 'PointSystemTypeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  type?: Maybe<SystemType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PointSystemTypeMinAggregate = {
  __typename: 'PointSystemTypeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  type?: Maybe<SystemType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PointSystemTypeOrderByWithRelationInput = {
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  pointSystem?: InputMaybe<PointSystemOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
};

export type PointSystemTypeRelationFilter = {
  is?: InputMaybe<PointSystemTypeWhereInput>;
  isNot?: InputMaybe<PointSystemTypeWhereInput>;
};

export enum PointSystemTypeScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type PointSystemTypeUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  pointSystem?: InputMaybe<PointSystemUpdateManyWithoutPointSystemTypeNestedInput>;
  type?: InputMaybe<SystemType>;
};

export type PointSystemTypeUpdateOneRequiredWithoutPointSystemNestedInput = {
  connect?: InputMaybe<PointSystemTypeWhereUniqueInput>;
};

export type PointSystemTypeWhereInput = {
  AND?: InputMaybe<Array<PointSystemTypeWhereInput>>;
  NOT?: InputMaybe<Array<PointSystemTypeWhereInput>>;
  OR?: InputMaybe<Array<PointSystemTypeWhereInput>>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  pointSystem?: InputMaybe<PointSystemListRelationFilter>;
  type?: InputMaybe<EnumSystemTypeFilter>;
};

export type PointSystemTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<PointSystemTypeWhereInput>>;
  NOT?: InputMaybe<Array<PointSystemTypeWhereInput>>;
  OR?: InputMaybe<Array<PointSystemTypeWhereInput>>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  pointSystem?: InputMaybe<PointSystemListRelationFilter>;
  type?: InputMaybe<SystemType>;
};

export type PointSystemUpdateInput = {
  calculationRuleEntries?: InputMaybe<Scalars['Int']['input']>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutPointSystemNestedInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  pointSystemType?: InputMaybe<PointSystemTypeUpdateOneRequiredWithoutPointSystemNestedInput>;
  scanLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type PointSystemUpdateManyMutationInput = {
  calculationRuleEntries?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  scanLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type PointSystemUpdateManyWithWhereWithoutPointSystemTypeInput = {
  data: PointSystemUpdateManyMutationInput;
  where: PointSystemScalarWhereInput;
};

export type PointSystemUpdateManyWithoutPointSystemTypeNestedInput = {
  connect?: InputMaybe<Array<PointSystemWhereUniqueInput>>;
  delete?: InputMaybe<Array<PointSystemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PointSystemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PointSystemWhereUniqueInput>>;
  set?: InputMaybe<Array<PointSystemWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<PointSystemUpdateManyWithWhereWithoutPointSystemTypeInput>>;
};

export type PointSystemWhereInput = {
  AND?: InputMaybe<Array<PointSystemWhereInput>>;
  NOT?: InputMaybe<Array<PointSystemWhereInput>>;
  OR?: InputMaybe<Array<PointSystemWhereInput>>;
  calculationRuleEntries?: InputMaybe<IntFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  pointSystemType?: InputMaybe<PointSystemTypeRelationFilter>;
  scanLimit?: InputMaybe<IntFilter>;
};

export type PointSystemWhereUniqueInput = {
  AND?: InputMaybe<Array<PointSystemWhereInput>>;
  NOT?: InputMaybe<Array<PointSystemWhereInput>>;
  OR?: InputMaybe<Array<PointSystemWhereInput>>;
  calculationRuleEntries?: InputMaybe<IntFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  pointSystemType?: InputMaybe<PointSystemTypeRelationFilter>;
  scanLimit?: InputMaybe<IntFilter>;
};

/** Information about a price */
export type PriceInformation = {
  __typename: 'PriceInformation';
  /** The quantity */
  maxQuantity: Scalars['Float']['output'];
  /** The price for the specific quantity */
  price: Scalars['Float']['output'];
};

/** Custom input to process multiple notification entries (SENT or REJECT them) */
export type ProcessNotificationInput = {
  /** The ID of the notification history entry that should be processed */
  id: Scalars['UUID']['input'];
  /** The reason for rejection */
  reason?: InputMaybe<Scalars['String']['input']>;
  /** The status for the notification history entry */
  status: NotificationStatus;
};

/** Prices for products */
export type ProductPriceInformation = {
  __typename: 'ProductPriceInformation';
  id: Scalars['String']['output'];
  /** The price information for the product */
  prices: Array<PriceInformation>;
  /** The product */
  product: StripeProduct;
};

/** Quantities of products */
export type ProductQuantityInformation = {
  __typename: 'ProductQuantityInformation';
  /** The product */
  product: StripeProduct;
  /** The quantity for the product */
  quantity: Scalars['Float']['output'];
};

export type Promotion = {
  __typename: 'Promotion';
  _count: PromotionCount;
  company: Company;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  periods?: Maybe<Array<PromotionPeriod>>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Object type for promotion codes */
export type PromotionCodeModel = {
  __typename: 'PromotionCodeModel';
  active: Scalars['Boolean']['output'];
  /** The promotion code */
  code: Scalars['String']['output'];
  /** The creation date of the promotion code */
  created: Scalars['DateTime']['output'];
  /** The expire date of the promotion code */
  expiresAt: Scalars['DateTime']['output'];
  /** The id of a promotion code */
  id: Scalars['String']['output'];
  /** Maximum number of times this promotion code can be redeemed */
  maxRedemptions: Scalars['Float']['output'];
  /** Number of times this promotion code has been used */
  timesRedeemed?: Maybe<Scalars['Float']['output']>;
};

export type PromotionCount = {
  __typename: 'PromotionCount';
  periods: Scalars['Int']['output'];
};

export type PromotionCountAggregate = {
  __typename: 'PromotionCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

/** Input for creation a promotion */
export type PromotionCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  /** The period for the promotion */
  period?: InputMaybe<CreatePromotionPeriodInput>;
  title: Scalars['String']['input'];
};

export type PromotionCreateNestedOneWithoutPeriodsInput = {
  connect?: InputMaybe<PromotionWhereUniqueInput>;
};

/** Custom input type to filter promotions by current location */
export type PromotionFilterInput = {
  /** Latitude of current position */
  latitude: Scalars['Latitude']['input'];
  /** Longitude of current position */
  longitude: Scalars['Longitude']['input'];
  /** The radius promotions should be found in. given in kilometers */
  radius: Scalars['Float']['input'];
};

export type PromotionListRelationFilter = {
  every?: InputMaybe<PromotionWhereInput>;
  none?: InputMaybe<PromotionWhereInput>;
  some?: InputMaybe<PromotionWhereInput>;
};

export type PromotionMaxAggregate = {
  __typename: 'PromotionMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionMinAggregate = {
  __typename: 'PromotionMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PromotionOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  periods?: InputMaybe<PromotionPeriodOrderByRelationAggregateInput>;
  title?: InputMaybe<SortOrder>;
};

export type PromotionPeriod = {
  __typename: 'PromotionPeriod';
  activeFrom: Scalars['DateTime']['output'];
  activeUntil: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  promotion: Promotion;
  promotionId: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PromotionPeriodCountAggregate = {
  __typename: 'PromotionPeriodCountAggregate';
  _all: Scalars['Int']['output'];
  activeFrom: Scalars['Int']['output'];
  activeUntil: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  promotionId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type PromotionPeriodCreateInput = {
  activeFrom: Scalars['DateTime']['input'];
  activeUntil: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  promotion: PromotionCreateNestedOneWithoutPeriodsInput;
};

export type PromotionPeriodListRelationFilter = {
  every?: InputMaybe<PromotionPeriodWhereInput>;
  none?: InputMaybe<PromotionPeriodWhereInput>;
  some?: InputMaybe<PromotionPeriodWhereInput>;
};

export type PromotionPeriodMaxAggregate = {
  __typename: 'PromotionPeriodMaxAggregate';
  activeFrom?: Maybe<Scalars['DateTime']['output']>;
  activeUntil?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  promotionId?: Maybe<Scalars['UUID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionPeriodMinAggregate = {
  __typename: 'PromotionPeriodMinAggregate';
  activeFrom?: Maybe<Scalars['DateTime']['output']>;
  activeUntil?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  promotionId?: Maybe<Scalars['UUID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionPeriodOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PromotionPeriodOrderByWithRelationInput = {
  activeFrom?: InputMaybe<SortOrder>;
  activeUntil?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  promotion?: InputMaybe<PromotionOrderByWithRelationInput>;
  promotionId?: InputMaybe<SortOrder>;
};

export enum PromotionPeriodScalarFieldEnum {
  ActiveFrom = 'activeFrom',
  ActiveUntil = 'activeUntil',
  CreatedAt = 'createdAt',
  Id = 'id',
  PromotionId = 'promotionId',
  UpdatedAt = 'updatedAt'
}

/** Unique input for promotion period */
export type PromotionPeriodUniqueInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};

/** Input for updating a promotion period */
export type PromotionPeriodUpdateInput = {
  activeFrom?: InputMaybe<Scalars['DateTime']['input']>;
  activeUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PromotionPeriodWhereInput = {
  AND?: InputMaybe<Array<PromotionPeriodWhereInput>>;
  NOT?: InputMaybe<Array<PromotionPeriodWhereInput>>;
  OR?: InputMaybe<Array<PromotionPeriodWhereInput>>;
  activeFrom?: InputMaybe<DateTimeFilter>;
  activeUntil?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  promotion?: InputMaybe<PromotionRelationFilter>;
  promotionId?: InputMaybe<UuidFilter>;
};

export type PromotionPeriodWhereUniqueInput = {
  AND?: InputMaybe<Array<PromotionPeriodWhereInput>>;
  NOT?: InputMaybe<Array<PromotionPeriodWhereInput>>;
  OR?: InputMaybe<Array<PromotionPeriodWhereInput>>;
  activeFrom?: InputMaybe<DateTimeFilter>;
  activeUntil?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  promotion?: InputMaybe<PromotionRelationFilter>;
  promotionId?: InputMaybe<UuidFilter>;
};

export type PromotionRelationFilter = {
  is?: InputMaybe<PromotionWhereInput>;
  isNot?: InputMaybe<PromotionWhereInput>;
};

export enum PromotionScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type PromotionWhereInput = {
  AND?: InputMaybe<Array<PromotionWhereInput>>;
  NOT?: InputMaybe<Array<PromotionWhereInput>>;
  OR?: InputMaybe<Array<PromotionWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  periods?: InputMaybe<PromotionPeriodListRelationFilter>;
  title?: InputMaybe<StringFilter>;
};

export type PromotionWhereUniqueInput = {
  AND?: InputMaybe<Array<PromotionWhereInput>>;
  NOT?: InputMaybe<Array<PromotionWhereInput>>;
  OR?: InputMaybe<Array<PromotionWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  periods?: InputMaybe<PromotionPeriodListRelationFilter>;
  title?: InputMaybe<StringFilter>;
};

export type Query = {
  __typename: 'Query';
  about: AboutModel;
  achievements: Array<CustomerAchievement>;
  balance: Balance;
  balances: Array<Balance>;
  branchOffice: BranchOffice;
  branchOffices: Array<BranchOffice>;
  calculationRule: CalculationRule;
  calculationRules: Array<CalculationRule>;
  cashDesk: CashDesk;
  cashDesks: Array<CashDesk>;
  companies: Array<Company>;
  company: Company;
  coupon: Coupon;
  coupons: Array<Coupon>;
  customer: Customer;
  customerStatistic: CustomerStatistic;
  customerStatistics: Array<CustomerStatistic>;
  customers: Array<Customer>;
  employee: Employee;
  employees: Array<Employee>;
  /** Returns the hosted invoice url of the unpaid open invoice of a company to perform a payment */
  fetchHostedInvoiceUrl: Scalars['String']['output'];
  /** Return the url of the open checkout session. Should only be requested if initial payment was aborted */
  fetchOpenCheckoutSessionUrl?: Maybe<Scalars['String']['output']>;
  getAllPrices: Array<PaymentPrices>;
  getInvoicesOfCustomer: Array<PaymentInvoices>;
  getInvoicesPreview: PaymentInvoices;
  getProductPrices: Array<ProductPriceInformation>;
  getSubscriptionOfCustomer?: Maybe<PaymentSubscription>;
  imageTemplate: ImageTemplate;
  imageTemplates: Array<ImageTemplate>;
  industries: Array<Industry>;
  industry: Industry;
  nextCancellationDate: PaymentSubscriptionNextCancellationDateModel;
  notificationHistory: Array<NotificationHistory>;
  pointSystem?: Maybe<PointSystem>;
  pointSystemType: PointSystemType;
  pointSystemTypes: Array<PointSystemType>;
  pointSystems: Array<PointSystem>;
  promotion: Promotion;
  promotionCodes: Array<PromotionCodeModel>;
  promotionPeriod: PromotionPeriod;
  promotionPeriods: Array<PromotionPeriod>;
  promotions: Array<Promotion>;
  /** Promotions are only returned for companies that are visible!! If filtered on a location, only active promotions in this location will be returned. If no location filter is configured, the active flag can be used to either return only active promotions, or all promotions. Setting of active flag in combination with the location filter does not take any effect. Running this without any input will return all promotions */
  promotionsByLocation: Array<Promotion>;
  receivedPoint: ReceivedPoint;
  receivedPoints: Array<ReceivedPoint>;
  redeemedCoupon: RedeemedCoupon;
  redeemedCoupons: Array<RedeemedCoupon>;
  redeemedPoint: RedeemedPoint;
  redeemedPoints: Array<RedeemedPoint>;
  /** Generate the report for the company */
  report: ReportModel;
  reward: Reward;
  rewards: Array<Reward>;
  scannedTransaction: ScannedTransaction;
  scannedTransactions: Array<ScannedTransaction>;
  summarizedRedeemedPoints: Array<SummarizedRedeemedPointModel>;
};


export type QueryAchievementsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryBalanceArgs = {
  where: BalanceWhereUniqueInput;
};


export type QueryBalancesArgs = {
  cursor?: InputMaybe<BalanceWhereUniqueInput>;
  distinct?: InputMaybe<Array<BalanceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BalanceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BalanceWhereInput>;
};


export type QueryBranchOfficeArgs = {
  where: BranchOfficeWhereUniqueInput;
};


export type QueryBranchOfficesArgs = {
  cursor?: InputMaybe<BranchOfficeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BranchOfficeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BranchOfficeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BranchOfficeWhereInput>;
};


export type QueryCalculationRuleArgs = {
  where: CalculationRuleWhereUniqueInput;
};


export type QueryCalculationRulesArgs = {
  cursor?: InputMaybe<CalculationRuleWhereUniqueInput>;
  distinct?: InputMaybe<Array<CalculationRuleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CalculationRuleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CalculationRuleWhereInput>;
};


export type QueryCashDeskArgs = {
  where: CashDeskWhereUniqueInput;
};


export type QueryCashDesksArgs = {
  cursor?: InputMaybe<CashDeskWhereUniqueInput>;
  distinct?: InputMaybe<Array<CashDeskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CashDeskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CashDeskWhereInput>;
};


export type QueryCompaniesArgs = {
  cursor?: InputMaybe<CompanyWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CompanyWhereInput>;
};


export type QueryCompanyArgs = {
  where?: InputMaybe<CompanyUniqueInput>;
};


export type QueryCouponArgs = {
  where: CouponWhereUniqueInput;
};


export type QueryCouponsArgs = {
  cursor?: InputMaybe<CouponWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CouponWhereInput>;
};


export type QueryCustomerArgs = {
  where: CustomerWhereUniqueInput;
};


export type QueryCustomerStatisticArgs = {
  where: CustomerStatisticWhereUniqueInput;
};


export type QueryCustomerStatisticsArgs = {
  cursor?: InputMaybe<CustomerStatisticWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerStatisticScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerStatisticOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerStatisticWhereInput>;
};


export type QueryCustomersArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryEmployeeArgs = {
  where: EmployeeWhereUniqueInput;
};


export type QueryEmployeesArgs = {
  cursor?: InputMaybe<EmployeeWhereUniqueInput>;
  distinct?: InputMaybe<Array<EmployeeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EmployeeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EmployeeWhereInput>;
};


export type QueryGetAllPricesArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryImageTemplateArgs = {
  where: ImageTemplateWhereUniqueInput;
};


export type QueryImageTemplatesArgs = {
  cursor?: InputMaybe<ImageTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<ImageTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ImageTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ImageTemplateWhereInput>;
};


export type QueryIndustriesArgs = {
  cursor?: InputMaybe<IndustryWhereUniqueInput>;
  distinct?: InputMaybe<Array<IndustryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IndustryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IndustryWhereInput>;
};


export type QueryIndustryArgs = {
  where: IndustryWhereUniqueInput;
};


export type QueryNotificationHistoryArgs = {
  currentMonth?: InputMaybe<Scalars['Boolean']['input']>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPointSystemTypeArgs = {
  where: PointSystemTypeWhereUniqueInput;
};


export type QueryPointSystemTypesArgs = {
  cursor?: InputMaybe<PointSystemTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PointSystemTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PointSystemTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PointSystemTypeWhereInput>;
};


export type QueryPointSystemsArgs = {
  cursor?: InputMaybe<PointSystemWhereUniqueInput>;
  distinct?: InputMaybe<Array<PointSystemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PointSystemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PointSystemWhereInput>;
};


export type QueryPromotionArgs = {
  where: PromotionWhereUniqueInput;
};


export type QueryPromotionCodesArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPromotionPeriodArgs = {
  where: PromotionPeriodWhereUniqueInput;
};


export type QueryPromotionPeriodsArgs = {
  cursor?: InputMaybe<PromotionPeriodWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromotionPeriodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromotionPeriodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromotionPeriodWhereInput>;
};


export type QueryPromotionsArgs = {
  cursor?: InputMaybe<PromotionWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromotionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromotionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromotionWhereInput>;
};


export type QueryPromotionsByLocationArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  filterInput?: InputMaybe<PromotionFilterInput>;
};


export type QueryReceivedPointArgs = {
  where: ReceivedPointWhereUniqueInput;
};


export type QueryReceivedPointsArgs = {
  cursor?: InputMaybe<ReceivedPointWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReceivedPointScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReceivedPointOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedPointWhereInput>;
};


export type QueryRedeemedCouponArgs = {
  where: RedeemedCouponWhereUniqueInput;
};


export type QueryRedeemedCouponsArgs = {
  cursor?: InputMaybe<RedeemedCouponWhereUniqueInput>;
  distinct?: InputMaybe<Array<RedeemedCouponScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RedeemedCouponOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RedeemedCouponWhereInput>;
};


export type QueryRedeemedPointArgs = {
  where: RedeemedPointWhereUniqueInput;
};


export type QueryRedeemedPointsArgs = {
  cursor?: InputMaybe<RedeemedPointWhereUniqueInput>;
  distinct?: InputMaybe<Array<RedeemedPointScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RedeemedPointOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RedeemedPointWhereInput>;
};


export type QueryReportArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportFilterInput>;
};


export type QueryRewardArgs = {
  where: RewardWhereUniqueInput;
};


export type QueryRewardsArgs = {
  cursor?: InputMaybe<RewardWhereUniqueInput>;
  distinct?: InputMaybe<Array<RewardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RewardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RewardWhereInput>;
};


export type QueryScannedTransactionArgs = {
  where: ScannedTransactionWhereUniqueInput;
};


export type QueryScannedTransactionsArgs = {
  cursor?: InputMaybe<ScannedTransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScannedTransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScannedTransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScannedTransactionWhereInput>;
};


export type QuerySummarizedRedeemedPointsArgs = {
  cursor?: InputMaybe<RedeemedPointWhereUniqueInput>;
  distinct?: InputMaybe<Array<RedeemedPointScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RedeemedPointOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RedeemedPointWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type ReceivedPoint = {
  __typename: 'ReceivedPoint';
  company: Company;
  companyId: Scalars['String']['output'];
  count?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  scannedTransaction?: Maybe<ScannedTransaction>;
  sourceType: ReceivedPointSourceType;
  storeType: StoreType;
  updatedAt: Scalars['DateTime']['output'];
};

export type ReceivedPointAvgAggregate = {
  __typename: 'ReceivedPointAvgAggregate';
  count?: Maybe<Scalars['Float']['output']>;
};

export type ReceivedPointCountAggregate = {
  __typename: 'ReceivedPointCountAggregate';
  _all: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  sourceType: Scalars['Int']['output'];
  storeType: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ReceivedPointCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<ReceivedPointWhereUniqueInput>>;
};

export type ReceivedPointCreateNestedOneWithoutScannedTransactionInput = {
  connect?: InputMaybe<ReceivedPointWhereUniqueInput>;
};

export type ReceivedPointListRelationFilter = {
  every?: InputMaybe<ReceivedPointWhereInput>;
  none?: InputMaybe<ReceivedPointWhereInput>;
  some?: InputMaybe<ReceivedPointWhereInput>;
};

export type ReceivedPointMaxAggregate = {
  __typename: 'ReceivedPointMaxAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  sourceType?: Maybe<ReceivedPointSourceType>;
  storeType?: Maybe<StoreType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReceivedPointMinAggregate = {
  __typename: 'ReceivedPointMinAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  sourceType?: Maybe<ReceivedPointSourceType>;
  storeType?: Maybe<StoreType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReceivedPointOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReceivedPointOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  count?: InputMaybe<SortOrderInput>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  scannedTransaction?: InputMaybe<ScannedTransactionOrderByWithRelationInput>;
  sourceType?: InputMaybe<SortOrder>;
  storeType?: InputMaybe<SortOrder>;
};

export type ReceivedPointRelationFilter = {
  is?: InputMaybe<ReceivedPointWhereInput>;
  isNot?: InputMaybe<ReceivedPointWhereInput>;
};

export enum ReceivedPointScalarFieldEnum {
  CompanyId = 'companyId',
  Count = 'count',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  Id = 'id',
  SourceType = 'sourceType',
  StoreType = 'storeType',
  UpdatedAt = 'updatedAt'
}

export type ReceivedPointScalarWhereInput = {
  AND?: InputMaybe<Array<ReceivedPointScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedPointScalarWhereInput>>;
  OR?: InputMaybe<Array<ReceivedPointScalarWhereInput>>;
  count?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  sourceType?: InputMaybe<EnumReceivedPointSourceTypeFilter>;
  storeType?: InputMaybe<EnumStoreTypeFilter>;
};

export enum ReceivedPointSourceType {
  Manually = 'MANUALLY',
  ValueBased = 'VALUE_BASED',
  VisitBased = 'VISIT_BASED'
}

export type ReceivedPointSumAggregate = {
  __typename: 'ReceivedPointSumAggregate';
  count?: Maybe<Scalars['Int']['output']>;
};

export type ReceivedPointUpdateInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutReceivedPointsNestedInput>;
  count?: InputMaybe<Scalars['Int']['input']>;
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutReceivedPointsNestedInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  scannedTransaction?: InputMaybe<ScannedTransactionUpdateOneWithoutReceivedPointNestedInput>;
  sourceType?: InputMaybe<ReceivedPointSourceType>;
  storeType?: InputMaybe<StoreType>;
};

export type ReceivedPointUpdateManyMutationInput = {
  count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  sourceType?: InputMaybe<ReceivedPointSourceType>;
  storeType?: InputMaybe<StoreType>;
};

export type ReceivedPointUpdateManyWithWhereWithoutCustomerInput = {
  data: ReceivedPointUpdateManyMutationInput;
  where: ReceivedPointScalarWhereInput;
};

export type ReceivedPointUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<ReceivedPointWhereUniqueInput>>;
  delete?: InputMaybe<Array<ReceivedPointWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReceivedPointScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReceivedPointWhereUniqueInput>>;
  set?: InputMaybe<Array<ReceivedPointWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<ReceivedPointUpdateManyWithWhereWithoutCustomerInput>>;
};

export type ReceivedPointUpdateOneRequiredWithoutScannedTransactionNestedInput = {
  connect?: InputMaybe<ReceivedPointWhereUniqueInput>;
};

export type ReceivedPointWhereInput = {
  AND?: InputMaybe<Array<ReceivedPointWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedPointWhereInput>>;
  OR?: InputMaybe<Array<ReceivedPointWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  count?: InputMaybe<IntNullableFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  scannedTransaction?: InputMaybe<ScannedTransactionNullableRelationFilter>;
  sourceType?: InputMaybe<EnumReceivedPointSourceTypeFilter>;
  storeType?: InputMaybe<EnumStoreTypeFilter>;
};

export type ReceivedPointWhereUniqueInput = {
  AND?: InputMaybe<Array<ReceivedPointWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedPointWhereInput>>;
  OR?: InputMaybe<Array<ReceivedPointWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  count?: InputMaybe<IntNullableFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  scannedTransaction?: InputMaybe<ScannedTransactionNullableRelationFilter>;
  sourceType?: InputMaybe<EnumReceivedPointSourceTypeFilter>;
  storeType?: InputMaybe<EnumStoreTypeFilter>;
};

export type RedeemedCoupon = {
  __typename: 'RedeemedCoupon';
  coupon: Coupon;
  couponId: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RedeemedCouponCountAggregate = {
  __typename: 'RedeemedCouponCountAggregate';
  _all: Scalars['Int']['output'];
  couponId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type RedeemedCouponCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
};

export type RedeemedCouponListRelationFilter = {
  every?: InputMaybe<RedeemedCouponWhereInput>;
  none?: InputMaybe<RedeemedCouponWhereInput>;
  some?: InputMaybe<RedeemedCouponWhereInput>;
};

export type RedeemedCouponMaxAggregate = {
  __typename: 'RedeemedCouponMaxAggregate';
  couponId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RedeemedCouponMinAggregate = {
  __typename: 'RedeemedCouponMinAggregate';
  couponId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RedeemedCouponOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RedeemedCouponOrderByWithRelationInput = {
  coupon?: InputMaybe<CouponOrderByWithRelationInput>;
  couponId?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export enum RedeemedCouponScalarFieldEnum {
  CouponId = 'couponId',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type RedeemedCouponScalarWhereInput = {
  AND?: InputMaybe<Array<RedeemedCouponScalarWhereInput>>;
  NOT?: InputMaybe<Array<RedeemedCouponScalarWhereInput>>;
  OR?: InputMaybe<Array<RedeemedCouponScalarWhereInput>>;
  couponId?: InputMaybe<UuidFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
};

export type RedeemedCouponUpdateManyMutationInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type RedeemedCouponUpdateManyWithWhereWithoutCouponInput = {
  data: RedeemedCouponUpdateManyMutationInput;
  where: RedeemedCouponScalarWhereInput;
};

export type RedeemedCouponUpdateManyWithWhereWithoutCustomerInput = {
  data: RedeemedCouponUpdateManyMutationInput;
  where: RedeemedCouponScalarWhereInput;
};

export type RedeemedCouponUpdateManyWithoutCouponNestedInput = {
  connect?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  delete?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RedeemedCouponScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  set?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<RedeemedCouponUpdateManyWithWhereWithoutCouponInput>>;
};

export type RedeemedCouponUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  delete?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RedeemedCouponScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  set?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<RedeemedCouponUpdateManyWithWhereWithoutCustomerInput>>;
};

export type RedeemedCouponWhereInput = {
  AND?: InputMaybe<Array<RedeemedCouponWhereInput>>;
  NOT?: InputMaybe<Array<RedeemedCouponWhereInput>>;
  OR?: InputMaybe<Array<RedeemedCouponWhereInput>>;
  coupon?: InputMaybe<CouponRelationFilter>;
  couponId?: InputMaybe<UuidFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
};

export type RedeemedCouponWhereUniqueInput = {
  AND?: InputMaybe<Array<RedeemedCouponWhereInput>>;
  NOT?: InputMaybe<Array<RedeemedCouponWhereInput>>;
  OR?: InputMaybe<Array<RedeemedCouponWhereInput>>;
  coupon?: InputMaybe<CouponRelationFilter>;
  couponId?: InputMaybe<UuidFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type RedeemedPoint = {
  __typename: 'RedeemedPoint';
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  reward: Reward;
  rewardId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RedeemedPointCountAggregate = {
  __typename: 'RedeemedPointCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  rewardId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type RedeemedPointCreateInput = {
  customer: CustomerCreateNestedOneWithoutRedeemedPointsInput;
  id?: InputMaybe<Scalars['UUID']['input']>;
  reward: RewardCreateNestedOneWithoutRedeemedPointsInput;
};

export type RedeemedPointCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
};

export type RedeemedPointListRelationFilter = {
  every?: InputMaybe<RedeemedPointWhereInput>;
  none?: InputMaybe<RedeemedPointWhereInput>;
  some?: InputMaybe<RedeemedPointWhereInput>;
};

export type RedeemedPointMaxAggregate = {
  __typename: 'RedeemedPointMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  rewardId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RedeemedPointMinAggregate = {
  __typename: 'RedeemedPointMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  rewardId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RedeemedPointOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RedeemedPointOrderByWithRelationInput = {
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  reward?: InputMaybe<RewardOrderByWithRelationInput>;
};

export enum RedeemedPointScalarFieldEnum {
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  Id = 'id',
  RewardId = 'rewardId',
  UpdatedAt = 'updatedAt'
}

export type RedeemedPointScalarWhereInput = {
  AND?: InputMaybe<Array<RedeemedPointScalarWhereInput>>;
  NOT?: InputMaybe<Array<RedeemedPointScalarWhereInput>>;
  OR?: InputMaybe<Array<RedeemedPointScalarWhereInput>>;
  id?: InputMaybe<UuidFilter>;
};

export type RedeemedPointUpdateInput = {
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutRedeemedPointsNestedInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  reward?: InputMaybe<RewardUpdateOneRequiredWithoutRedeemedPointsNestedInput>;
};

export type RedeemedPointUpdateManyMutationInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type RedeemedPointUpdateManyWithWhereWithoutCustomerInput = {
  data: RedeemedPointUpdateManyMutationInput;
  where: RedeemedPointScalarWhereInput;
};

export type RedeemedPointUpdateManyWithWhereWithoutRewardInput = {
  data: RedeemedPointUpdateManyMutationInput;
  where: RedeemedPointScalarWhereInput;
};

export type RedeemedPointUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  delete?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RedeemedPointScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  set?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<RedeemedPointUpdateManyWithWhereWithoutCustomerInput>>;
};

export type RedeemedPointUpdateManyWithoutRewardNestedInput = {
  connect?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  delete?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RedeemedPointScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  set?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<RedeemedPointUpdateManyWithWhereWithoutRewardInput>>;
};

export type RedeemedPointWhereInput = {
  AND?: InputMaybe<Array<RedeemedPointWhereInput>>;
  NOT?: InputMaybe<Array<RedeemedPointWhereInput>>;
  OR?: InputMaybe<Array<RedeemedPointWhereInput>>;
  customer?: InputMaybe<CustomerRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  reward?: InputMaybe<RewardRelationFilter>;
};

export type RedeemedPointWhereUniqueInput = {
  AND?: InputMaybe<Array<RedeemedPointWhereInput>>;
  NOT?: InputMaybe<Array<RedeemedPointWhereInput>>;
  OR?: InputMaybe<Array<RedeemedPointWhereInput>>;
  customer?: InputMaybe<CustomerRelationFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  reward?: InputMaybe<RewardRelationFilter>;
};

/** Custom input for registration of companies */
export type RegisterCompanyInput = {
  city: Scalars['String']['input'];
  countryCode: Scalars['CountryCode']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailAddress']['input'];
  houseNumber: Scalars['String']['input'];
  id: Scalars['String']['input'];
  industry: IndustryCreateNestedOneWithoutCompaniesInput;
  name: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
  owner: NestedEmployeeCreateWithoutCompanyInput;
  postalCode: Scalars['PostalCode']['input'];
  state: Scalars['String']['input'];
  storeType?: InputMaybe<StoreType>;
  street: Scalars['String']['input'];
};

/** Input for registering an invited employee */
export type RegisterEmployeeInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the employees auth0 account */
  id: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
};

/** Result of starting registration flow */
export type RegistrationFlowModel = {
  __typename: 'RegistrationFlowModel';
  /** The arn of executed state machine */
  executionArn: Scalars['String']['output'];
  /** Start date of execution */
  startDate: Scalars['DateTime']['output'];
};

/** Input for filtering the report by given year, month or both */
export type ReportFilterInput = {
  /** The month the report should be fetched for. Example: January */
  month?: InputMaybe<Month>;
  /** The year the report should be fetched for. Example: 2024 */
  year?: InputMaybe<Scalars['Year']['input']>;
};

/** The reporting object of a company */
export type ReportModel = {
  __typename: 'ReportModel';
  /** The period begin of the report */
  periodBegin: Scalars['String']['output'];
  /** The period end of the report */
  periodEnd: Scalars['String']['output'];
  /** The redeemed coupons */
  redeemedCoupons: Array<CouponReportModel>;
  /** The redeemed rewards */
  redeemedRewards: Array<RewardReportModel>;
};

export type Reward = {
  __typename: 'Reward';
  _count: RewardCount;
  company: Company;
  companyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  imageKey?: Maybe<Scalars['String']['output']>;
  imageTemplate?: Maybe<ImageTemplate>;
  imageTemplateId?: Maybe<Scalars['UUID']['output']>;
  name: Scalars['String']['output'];
  redeemedPoints?: Maybe<Array<RedeemedPoint>>;
  requiredPoints: Scalars['Int']['output'];
  saving: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RewardAvgAggregate = {
  __typename: 'RewardAvgAggregate';
  requiredPoints?: Maybe<Scalars['Float']['output']>;
  saving?: Maybe<Scalars['Float']['output']>;
};

export type RewardCount = {
  __typename: 'RewardCount';
  redeemedPoints: Scalars['Int']['output'];
};

export type RewardCountAggregate = {
  __typename: 'RewardCountAggregate';
  _all: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageKey: Scalars['Int']['output'];
  imageTemplateId: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  requiredPoints: Scalars['Int']['output'];
  saving: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type RewardCreateNestedManyWithoutImageTemplateInput = {
  connect?: InputMaybe<Array<RewardWhereUniqueInput>>;
};

export type RewardCreateNestedOneWithoutRedeemedPointsInput = {
  connect?: InputMaybe<RewardWhereUniqueInput>;
};

export type RewardListRelationFilter = {
  every?: InputMaybe<RewardWhereInput>;
  none?: InputMaybe<RewardWhereInput>;
  some?: InputMaybe<RewardWhereInput>;
};

export type RewardMaxAggregate = {
  __typename: 'RewardMaxAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  imageTemplateId?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  requiredPoints?: Maybe<Scalars['Int']['output']>;
  saving?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RewardMinAggregate = {
  __typename: 'RewardMinAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  imageTemplateId?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  requiredPoints?: Maybe<Scalars['Int']['output']>;
  saving?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RewardOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RewardOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  deletedAt?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  imageKey?: InputMaybe<SortOrderInput>;
  imageTemplate?: InputMaybe<ImageTemplateOrderByWithRelationInput>;
  name?: InputMaybe<SortOrder>;
  redeemedPoints?: InputMaybe<RedeemedPointOrderByRelationAggregateInput>;
  requiredPoints?: InputMaybe<SortOrder>;
  saving?: InputMaybe<SortOrder>;
};

export type RewardRelationFilter = {
  is?: InputMaybe<RewardWhereInput>;
  isNot?: InputMaybe<RewardWhereInput>;
};

/** The report object for rewards */
export type RewardReportModel = {
  __typename: 'RewardReportModel';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  /** The number of times this reward was redeemed */
  redemptions: Scalars['Float']['output'];
  saving: Scalars['Float']['output'];
};

export enum RewardScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  ImageKey = 'imageKey',
  ImageTemplateId = 'imageTemplateId',
  Name = 'name',
  RequiredPoints = 'requiredPoints',
  Saving = 'saving',
  UpdatedAt = 'updatedAt'
}

export type RewardScalarWhereInput = {
  AND?: InputMaybe<Array<RewardScalarWhereInput>>;
  NOT?: InputMaybe<Array<RewardScalarWhereInput>>;
  OR?: InputMaybe<Array<RewardScalarWhereInput>>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  imageKey?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  requiredPoints?: InputMaybe<IntFilter>;
  saving?: InputMaybe<FloatFilter>;
};

/** Enum of status of rewards */
export enum RewardStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type RewardSumAggregate = {
  __typename: 'RewardSumAggregate';
  requiredPoints?: Maybe<Scalars['Int']['output']>;
  saving?: Maybe<Scalars['Float']['output']>;
};

export type RewardUpdateInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutRewardsNestedInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  imageTemplate?: InputMaybe<ImageTemplateUpdateOneWithoutRewardsNestedInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  redeemedPoints?: InputMaybe<RedeemedPointUpdateManyWithoutRewardNestedInput>;
  requiredPoints?: InputMaybe<Scalars['Int']['input']>;
  saving?: InputMaybe<Scalars['Float']['input']>;
};

export type RewardUpdateManyMutationInput = {
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  requiredPoints?: InputMaybe<Scalars['Int']['input']>;
  saving?: InputMaybe<Scalars['Float']['input']>;
};

export type RewardUpdateManyWithWhereWithoutImageTemplateInput = {
  data: RewardUpdateManyMutationInput;
  where: RewardScalarWhereInput;
};

export type RewardUpdateManyWithoutImageTemplateNestedInput = {
  connect?: InputMaybe<Array<RewardWhereUniqueInput>>;
  delete?: InputMaybe<Array<RewardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RewardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RewardWhereUniqueInput>>;
  set?: InputMaybe<Array<RewardWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<RewardUpdateManyWithWhereWithoutImageTemplateInput>>;
};

export type RewardUpdateOneRequiredWithoutRedeemedPointsNestedInput = {
  connect?: InputMaybe<RewardWhereUniqueInput>;
};

export type RewardWhereInput = {
  AND?: InputMaybe<Array<RewardWhereInput>>;
  NOT?: InputMaybe<Array<RewardWhereInput>>;
  OR?: InputMaybe<Array<RewardWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  imageKey?: InputMaybe<StringNullableFilter>;
  imageTemplate?: InputMaybe<ImageTemplateNullableRelationFilter>;
  name?: InputMaybe<StringFilter>;
  redeemedPoints?: InputMaybe<RedeemedPointListRelationFilter>;
  requiredPoints?: InputMaybe<IntFilter>;
  saving?: InputMaybe<FloatFilter>;
};

export type RewardWhereUniqueInput = {
  AND?: InputMaybe<Array<RewardWhereInput>>;
  NOT?: InputMaybe<Array<RewardWhereInput>>;
  OR?: InputMaybe<Array<RewardWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageKey?: InputMaybe<StringNullableFilter>;
  imageTemplate?: InputMaybe<ImageTemplateNullableRelationFilter>;
  name?: InputMaybe<StringFilter>;
  redeemedPoints?: InputMaybe<RedeemedPointListRelationFilter>;
  requiredPoints?: InputMaybe<IntFilter>;
  saving?: InputMaybe<FloatFilter>;
};

export type ScannedTransaction = {
  __typename: 'ScannedTransaction';
  amount: Scalars['Float']['output'];
  cashDesk: CashDesk;
  cashDeskId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  receivedPoint: ReceivedPoint;
  receivedPointId: Scalars['UUID']['output'];
  transactionId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ScannedTransactionAvgAggregate = {
  __typename: 'ScannedTransactionAvgAggregate';
  amount?: Maybe<Scalars['Float']['output']>;
  transactionId?: Maybe<Scalars['Float']['output']>;
};

export type ScannedTransactionCountAggregate = {
  __typename: 'ScannedTransactionCountAggregate';
  _all: Scalars['Int']['output'];
  amount: Scalars['Int']['output'];
  cashDeskId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  receivedPointId: Scalars['Int']['output'];
  transactionId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ScannedTransactionCreateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  cashDesk: CashDeskCreateNestedOneWithoutScannedTransactionsInput;
  id?: InputMaybe<Scalars['UUID']['input']>;
  receivedPoint: ReceivedPointCreateNestedOneWithoutScannedTransactionInput;
  transactionId: Scalars['Int']['input'];
};

export type ScannedTransactionListRelationFilter = {
  every?: InputMaybe<ScannedTransactionWhereInput>;
  none?: InputMaybe<ScannedTransactionWhereInput>;
  some?: InputMaybe<ScannedTransactionWhereInput>;
};

export type ScannedTransactionMaxAggregate = {
  __typename: 'ScannedTransactionMaxAggregate';
  amount?: Maybe<Scalars['Float']['output']>;
  cashDeskId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  receivedPointId?: Maybe<Scalars['UUID']['output']>;
  transactionId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ScannedTransactionMinAggregate = {
  __typename: 'ScannedTransactionMinAggregate';
  amount?: Maybe<Scalars['Float']['output']>;
  cashDeskId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  receivedPointId?: Maybe<Scalars['UUID']['output']>;
  transactionId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ScannedTransactionNullableRelationFilter = {
  is?: InputMaybe<ScannedTransactionWhereInput>;
  isNot?: InputMaybe<ScannedTransactionWhereInput>;
};

export type ScannedTransactionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ScannedTransactionOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  cashDesk?: InputMaybe<CashDeskOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  receivedPoint?: InputMaybe<ReceivedPointOrderByWithRelationInput>;
  transactionId?: InputMaybe<SortOrder>;
};

export enum ScannedTransactionScalarFieldEnum {
  Amount = 'amount',
  CashDeskId = 'cashDeskId',
  CreatedAt = 'createdAt',
  Id = 'id',
  ReceivedPointId = 'receivedPointId',
  TransactionId = 'transactionId',
  UpdatedAt = 'updatedAt'
}

export type ScannedTransactionSumAggregate = {
  __typename: 'ScannedTransactionSumAggregate';
  amount?: Maybe<Scalars['Float']['output']>;
  transactionId?: Maybe<Scalars['Int']['output']>;
};

export type ScannedTransactionTransactionIdCashDeskIdCompoundUniqueInput = {
  transactionId: Scalars['Int']['input'];
};

export type ScannedTransactionUpdateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  cashDesk?: InputMaybe<CashDeskUpdateOneRequiredWithoutScannedTransactionsNestedInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  receivedPoint?: InputMaybe<ReceivedPointUpdateOneRequiredWithoutScannedTransactionNestedInput>;
  transactionId?: InputMaybe<Scalars['Int']['input']>;
};

export type ScannedTransactionUpdateOneWithoutReceivedPointNestedInput = {
  connect?: InputMaybe<ScannedTransactionWhereUniqueInput>;
  delete?: InputMaybe<ScannedTransactionWhereInput>;
  disconnect?: InputMaybe<ScannedTransactionWhereInput>;
};

export type ScannedTransactionWhereInput = {
  AND?: InputMaybe<Array<ScannedTransactionWhereInput>>;
  NOT?: InputMaybe<Array<ScannedTransactionWhereInput>>;
  OR?: InputMaybe<Array<ScannedTransactionWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  cashDesk?: InputMaybe<CashDeskRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  receivedPoint?: InputMaybe<ReceivedPointRelationFilter>;
  transactionId?: InputMaybe<IntFilter>;
};

export type ScannedTransactionWhereUniqueInput = {
  AND?: InputMaybe<Array<ScannedTransactionWhereInput>>;
  NOT?: InputMaybe<Array<ScannedTransactionWhereInput>>;
  OR?: InputMaybe<Array<ScannedTransactionWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  cashDesk?: InputMaybe<CashDeskRelationFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  receivedPoint?: InputMaybe<ReceivedPointRelationFilter>;
  transactionId?: InputMaybe<IntFilter>;
  transactionId_cashDeskId?: InputMaybe<ScannedTransactionTransactionIdCashDeskIdCompoundUniqueInput>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export enum StoreType {
  Ecom = 'ECOM',
  Local = 'LOCAL',
  LocalAndEcom = 'LOCAL_AND_ECOM'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']['input']>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['String']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Enum of all products */
export enum StripeProduct {
  BasePackage = 'BASE_PACKAGE',
  BranchOffice = 'BRANCH_OFFICE',
  Employee = 'EMPLOYEE',
  PushNotification = 'PUSH_NOTIFICATION',
  Reward = 'REWARD'
}

/** Enum of available products */
export enum StripeSubscriptionProduct {
  BranchOffice = 'BRANCH_OFFICE',
  Employee = 'EMPLOYEE',
  Reward = 'REWARD'
}

/** Available actions on subscription items */
export enum SubscriptionItemAction {
  Create = 'CREATE',
  Delete = 'DELETE',
  Downgrade = 'DOWNGRADE',
  Skip = 'SKIP',
  Upgrade = 'UPGRADE'
}

/** Input to upgrade subscription item with new quantity */
export type SubscriptionItemInput = {
  /** New quantity for the selected product */
  additionalQuantity: Scalars['Float']['input'];
  /** The product that's quantity should be upgraded */
  product: StripeSubscriptionProduct;
};

/** Result of updating quantity of subscription item */
export type SubscriptionItemResultModel = {
  __typename: 'SubscriptionItemResultModel';
  /** Tell what action was proceed */
  action: SubscriptionItemAction;
  additionalQuantity: Scalars['Float']['output'];
  error?: Maybe<UpdateSubscriptionItemError>;
  product: StripeSubscriptionProduct;
  /** The subscription ID */
  subscription: Scalars['String']['output'];
};

/** Model for summarized redeemed points by reward */
export type SummarizedRedeemedPointModel = {
  __typename: 'SummarizedRedeemedPointModel';
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  reward: Reward;
  rewardId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Input to change coupon status */
export type SwitchCouponStatusInput = {
  /** The new status for the coupon */
  status: CouponStatus;
};

/** Input type to soft delete or reactivate a reward */
export type SwitchRewardStatusInput = {
  /** The ID of the reward to switch the status of */
  rewardId: Scalars['String']['input'];
  /** The status the reward should be changed to (set deletedAt based on the status) */
  status: RewardStatus;
};

export enum SystemType {
  Percentage = 'PERCENTAGE',
  Staggered = 'STAGGERED',
  VisitBased = 'VISIT_BASED'
}

/** Custom arg type for updating a cash desk */
export type UpdateCashDeskArgs = {
  /** The fields to update of a cash desk */
  data: UpdateCashDeskInput;
  /** The unique identifier of the cash desk that should be updated */
  where: CashDeskUniqueInput;
};

/** Custom input for updating fields of an cash desk */
export type UpdateCashDeskInput = {
  /** The ID of an branch office this cash desk should be moved to */
  branchOffice?: InputMaybe<BranchOfficeUniqueInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input for updating information about a company */
export type UpdateCompanyInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  industry?: InputMaybe<IndustryUpdateOneRequiredWithoutCompaniesNestedInput>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Input for updating information of employee */
export type UpdateEmployeeInput = {
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

/** Object type for possible errors during subscription item update */
export type UpdateSubscriptionItemError = {
  __typename: 'UpdateSubscriptionItemError';
  cause?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

export type UuidFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type VisitBasedInput = {
  companyId: Scalars['String']['input'];
};

export type BalanceFieldsFragment = { __typename: 'Balance', updatedAt: Date | string, createdAt: Date | string, count: number };

export type BranchOfficeCountFieldsFragment = { __typename: 'BranchOffice', id: string, _count: { __typename: 'BranchOfficeCount', cashDesks: number } };

export type BranchOfficeFieldsFragment = { __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, updatedAt: Date | string, createdAt: Date | string };

export type CalculationRuleFieldsFragment = { __typename: 'CalculationRule', id: string, points: number, value: number };

export type CashDeskFieldsFragment = { __typename: 'CashDesk', id: string, name: string, branchOfficeId: string };

export type CompanyAddressFieldsFragment = { __typename: 'Company', street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string };

export type CompanyInformationFieldsFragment = { __typename: 'Company', visible: boolean, name: string, email: string, description?: string | null, id: string, logoUrl?: string | null, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, industry: { __typename: 'Industry', id: string, name: string } };

export type CompanySubscriptionInfoFieldsFragment = { __typename: 'Company', id: string, status: CompanyStatus, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string };

export type CompanyFieldsFragment = { __typename: 'Company', id: string, logoUrl?: string | null, visible: boolean };

export type ContactFieldsFragment = { __typename: 'Contact', id: string, requestId?: string | null };

export type CouponFieldsFragment = { __typename: 'Coupon', id: string, name: string, description?: string | null, type: CouponType, active: boolean, createdAt: Date | string, updatedAt: Date | string, pointsCredit?: number | null, redemptions?: Array<{ __typename: 'RedeemedCoupon', id: string }> | null };

export type CustomerFieldsFragment = { __typename: 'Customer', id: string, username: string, firstName?: string | null, lastName?: string | null, email: string };

export type EmployeeFieldsFragment = { __typename: 'Employee', id?: string | null, firstName?: string | null, lastName?: string | null, email: string, userType: CompanyUserType, status: EmployeeStatus, updatedAt: Date | string, createdAt: Date | string };

export type IndustryFieldsFragment = { __typename: 'Industry', id: string, name: string, description?: string | null };

export type NotificationHistoryFieldsFragment = { __typename: 'NotificationHistory', id: string, title: string, message: string, status: NotificationStatus, affectedCustomers: number, reason?: string | null, createdAt: Date | string, updatedAt?: Date | string | null };

export type PaymentInvoiceFieldsFragment = { __typename: 'PaymentInvoices', id?: string | null, charge?: string | null, created: number, currency: string, description?: string | null, hostedInvoiceUrl?: string | null, invoicePdf?: string | null, paid: boolean, status: string, tax?: number | null, total: number, number: string };

export type PaymentSubscriptionFieldsFragment = { __typename: 'PaymentSubscription', id: string, billingCycleAnchor: number, cancelAt?: number | null, cancelAtPeriodEnd: boolean, status: string, productQuantities?: Array<{ __typename: 'ProductQuantityInformation', product: StripeProduct, quantity: number }> | null };

export type PaymentFieldsFragment = { __typename: 'Payment', paymentCustomerId: string, paymentUrl: string };

export type PointSystemCheckFieldsFragment = { __typename: 'PointSystem', pointSystemType: { __typename: 'PointSystemType', type: SystemType } };

export type PointSystemFieldsFragment = { __typename: 'PointSystem', id: string, calculationRuleEntries: number, scanLimit: number, company: { __typename: 'Company', calculationRules?: Array<{ __typename: 'CalculationRule', id: string, points: number, value: number }> | null }, pointSystemType: { __typename: 'PointSystemType', id: string, type: SystemType } };

export type PointSystemTypeFieldsFragment = { __typename: 'PointSystemType', id: string, type: SystemType };

export type ProductPriceFieldsFragment = { __typename: 'ProductPriceInformation', id: string, product: StripeProduct, prices: Array<{ __typename: 'PriceInformation', price: number, maxQuantity: number }> };

export type PromotionPeriodFieldsFragment = { __typename: 'PromotionPeriod', id: string, updatedAt: Date | string, createdAt: Date | string, activeFrom: Date | string, activeUntil: Date | string, promotion: { __typename: 'Promotion', id: string, title: string, description?: string | null, createdAt: Date | string, updatedAt: Date | string } };

export type PromotionFieldsFragment = { __typename: 'Promotion', id: string, title: string, description?: string | null, createdAt: Date | string, updatedAt: Date | string };

export type ReceivedPointFieldsFragment = { __typename: 'ReceivedPoint', id: string, count?: number | null, createdAt: Date | string, updatedAt: Date | string, sourceType: ReceivedPointSourceType };

export type RedeemedPointFieldsFragment = { __typename: 'RedeemedPoint', id: string, createdAt: Date | string, updatedAt: Date | string, reward: { __typename: 'Reward', id: string, name: string, description?: string | null, requiredPoints: number, saving: number, imageKey?: string | null, updatedAt: Date | string, createdAt: Date | string } };

export type RewardFieldsFragment = { __typename: 'Reward', id: string, name: string, description?: string | null, requiredPoints: number, saving: number, imageKey?: string | null, updatedAt: Date | string, createdAt: Date | string };

export type CreateBranchOfficeWithCashDeskMutationVariables = Exact<{
  createInput: CreateBranchOfficeWithCashDeskInput;
}>;


export type CreateBranchOfficeWithCashDeskMutation = { __typename: 'Mutation', createBranchOffice: { __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, updatedAt: Date | string, createdAt: Date | string } };

export type DeleteBranchOfficeMutationVariables = Exact<{
  where: BranchOfficeUniqueInput;
}>;


export type DeleteBranchOfficeMutation = { __typename: 'Mutation', deleteBranchOffice: { __typename: 'BranchOffice', id: string } };

export type UpdateBranchOfficeMutationVariables = Exact<{
  data: BranchOfficeUpdateInput;
  where: BranchOfficeWhereUniqueInput;
}>;


export type UpdateBranchOfficeMutation = { __typename: 'Mutation', updateBranchOffice: { __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, updatedAt: Date | string, createdAt: Date | string } };

export type CreateOrChangeCalculationRuleMutationVariables = Exact<{
  data: CreateOrChangeCalculationRuleInput;
}>;


export type CreateOrChangeCalculationRuleMutation = { __typename: 'Mutation', createOrChangeCalculationRule: Array<{ __typename: 'CalculationRule', id: string, points: number, value: number }> };

export type CreateCashDesksMutationVariables = Exact<{
  createInput: Array<CreateCashDeskWithoutTransactionInput> | CreateCashDeskWithoutTransactionInput;
}>;


export type CreateCashDesksMutation = { __typename: 'Mutation', createCashDesk: Array<{ __typename: 'CashDesk', id: string, name: string, branchOfficeId: string }> };

export type DeleteCashDeskMutationVariables = Exact<{
  uniqueInput: CashDeskUniqueInput;
}>;


export type DeleteCashDeskMutation = { __typename: 'Mutation', deleteCashDesk: { __typename: 'CashDesk', id: string, branchOfficeId: string } };

export type UpdateCashDeskMutationVariables = Exact<{
  updateInput: UpdateCashDeskArgs;
}>;


export type UpdateCashDeskMutation = { __typename: 'Mutation', updateCashDesk: { __typename: 'CashDesk', id: string, name: string, branchOfficeId: string } };

export type ChangeVisibilityMutationVariables = Exact<{
  visible: Scalars['Boolean']['input'];
}>;


export type ChangeVisibilityMutation = { __typename: 'Mutation', changeVisibility: { __typename: 'Company', id: string, logoUrl?: string | null, visible: boolean } };

export type UpdateCompanyMutationVariables = Exact<{
  data: UpdateCompanyInput;
  where?: InputMaybe<CompanyUniqueInput>;
}>;


export type UpdateCompanyMutation = { __typename: 'Mutation', updateCompany: { __typename: 'Company', visible: boolean, name: string, email: string, description?: string | null, id: string, logoUrl?: string | null, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, industry: { __typename: 'Industry', id: string, name: string } } };

export type ContactSupportMutationVariables = Exact<{
  data: ContactSupportInput;
}>;


export type ContactSupportMutation = { __typename: 'Mutation', contactSupport: { __typename: 'Contact', id: string, requestId?: string | null } };

export type CreateCouponMutationVariables = Exact<{
  data: CouponCreateInput;
}>;


export type CreateCouponMutation = { __typename: 'Mutation', createCoupon: { __typename: 'Coupon', id: string, name: string, description?: string | null, type: CouponType, active: boolean, createdAt: Date | string, updatedAt: Date | string, pointsCredit?: number | null, redemptions?: Array<{ __typename: 'RedeemedCoupon', id: string }> | null } };

export type SwitchCouponStatusMutationVariables = Exact<{
  where: CouponUniqueInput;
  data: SwitchCouponStatusInput;
}>;


export type SwitchCouponStatusMutation = { __typename: 'Mutation', switchCouponStatus: { __typename: 'Coupon', id: string, name: string, description?: string | null, type: CouponType, active: boolean, createdAt: Date | string, updatedAt: Date | string, pointsCredit?: number | null, redemptions?: Array<{ __typename: 'RedeemedCoupon', id: string }> | null } };

export type DeleteEmployeeMutationVariables = Exact<{
  where: EmployeeIdentifierInput;
}>;


export type DeleteEmployeeMutation = { __typename: 'Mutation', deleteEmployee: { __typename: 'Employee', id?: string | null } };

export type InviteEmployeeMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type InviteEmployeeMutation = { __typename: 'Mutation', inviteEmployee: { __typename: 'Employee', id?: string | null, firstName?: string | null, lastName?: string | null, email: string, userType: CompanyUserType, status: EmployeeStatus, updatedAt: Date | string, createdAt: Date | string } };

export type SendPasswordChangeEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type SendPasswordChangeEmailMutation = { __typename: 'Mutation', sendPasswordChangeEmail: { __typename: 'Employee', id?: string | null, firstName?: string | null, lastName?: string | null, email: string, userType: CompanyUserType, status: EmployeeStatus, updatedAt: Date | string, createdAt: Date | string } };

export type UpdateEmployeeMutationVariables = Exact<{
  data: UpdateEmployeeInput;
  where?: InputMaybe<EmployeeUniqueInput>;
}>;


export type UpdateEmployeeMutation = { __typename: 'Mutation', updateEmployee: { __typename: 'Employee', id?: string | null, firstName?: string | null, lastName?: string | null, email: string, userType: CompanyUserType, status: EmployeeStatus, updatedAt: Date | string, createdAt: Date | string } };

export type CreateNotificationHistoryMutationVariables = Exact<{
  createInput: CreateNotificationHistoryInput;
}>;


export type CreateNotificationHistoryMutation = { __typename: 'Mutation', createNotificationHistory: { __typename: 'NotificationHistory', id: string, title: string, message: string, status: NotificationStatus, affectedCustomers: number, reason?: string | null, createdAt: Date | string, updatedAt?: Date | string | null } };

export type CancelSubscriptionAtNoticePeriodMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelSubscriptionAtNoticePeriodMutation = { __typename: 'Mutation', cancelSubscriptionAtNoticePeriod: { __typename: 'PaymentSubscription', id: string, billingCycleAnchor: number, cancelAt?: number | null, cancelAtPeriodEnd: boolean, status: string, productQuantities?: Array<{ __typename: 'ProductQuantityInformation', product: StripeProduct, quantity: number }> | null } };

export type CreatePaymentCustomerMutationVariables = Exact<{
  paymentCustomer: PaymentCreateCustomerInput;
  paymentSuccessRedirectUrl: Scalars['String']['input'];
}>;


export type CreatePaymentCustomerMutation = { __typename: 'Mutation', createPaymentCustomer: { __typename: 'Payment', paymentCustomerId: string, paymentUrl: string } };

export type CreateSubscriptionForExistingCustomerMutationVariables = Exact<{
  paymentSubscription?: InputMaybe<PaymentCreateSubscriptionInput>;
  paymentSuccessRedirectUrl: Scalars['String']['input'];
}>;


export type CreateSubscriptionForExistingCustomerMutation = { __typename: 'Mutation', createSubscription: { __typename: 'Payment', paymentCustomerId: string, paymentUrl: string } };

export type UpgradeSubscriptionItemsMutationVariables = Exact<{
  upgradeInput: Array<SubscriptionItemInput> | SubscriptionItemInput;
}>;


export type UpgradeSubscriptionItemsMutation = { __typename: 'Mutation', upgradeSubscriptionItems: Array<{ __typename: 'SubscriptionItemResultModel', product: StripeSubscriptionProduct, action: SubscriptionItemAction, additionalQuantity: number, subscription: string, error?: { __typename: 'UpdateSubscriptionItemError', cause?: string | null, message: string } | null }> };

export type ChangePointSystemMutationVariables = Exact<{
  input: ChangePointSystemInput;
}>;


export type ChangePointSystemMutation = { __typename: 'Mutation', changePointSystem: { __typename: 'PointSystem', id: string, calculationRuleEntries: number, scanLimit: number, company: { __typename: 'Company', calculationRules?: Array<{ __typename: 'CalculationRule', id: string, points: number, value: number }> | null }, pointSystemType: { __typename: 'PointSystemType', id: string, type: SystemType } } };

export type CreatePointSystemMutationVariables = Exact<{
  createInput: PointSystemCreateInput;
}>;


export type CreatePointSystemMutation = { __typename: 'Mutation', createPointSystem: { __typename: 'PointSystem', id: string, calculationRuleEntries: number, scanLimit: number, company: { __typename: 'Company', calculationRules?: Array<{ __typename: 'CalculationRule', id: string, points: number, value: number }> | null }, pointSystemType: { __typename: 'PointSystemType', id: string, type: SystemType } } };

export type CreatePromotionPeriodMutationVariables = Exact<{
  data: PromotionPeriodCreateInput;
}>;


export type CreatePromotionPeriodMutation = { __typename: 'Mutation', createPromotionPeriod: { __typename: 'PromotionPeriod', id: string, updatedAt: Date | string, createdAt: Date | string, activeFrom: Date | string, activeUntil: Date | string, promotion: { __typename: 'Promotion', id: string, title: string, description?: string | null, createdAt: Date | string, updatedAt: Date | string } } };

export type DeletePromotionPeriodMutationVariables = Exact<{
  where: PromotionPeriodWhereUniqueInput;
}>;


export type DeletePromotionPeriodMutation = { __typename: 'Mutation', deletePromotionPeriod: { __typename: 'PromotionPeriod', id: string } };

export type UpdatePromotionPeriodMutationVariables = Exact<{
  where: PromotionPeriodUniqueInput;
  data: PromotionPeriodUpdateInput;
}>;


export type UpdatePromotionPeriodMutation = { __typename: 'Mutation', updatePromotionPeriod: { __typename: 'PromotionPeriod', id: string, updatedAt: Date | string, createdAt: Date | string, activeFrom: Date | string, activeUntil: Date | string, promotion: { __typename: 'Promotion', id: string, title: string, description?: string | null, createdAt: Date | string, updatedAt: Date | string } } };

export type CreatePromotionMutationVariables = Exact<{
  data: PromotionCreateInput;
}>;


export type CreatePromotionMutation = { __typename: 'Mutation', createPromotion: { __typename: 'Promotion', id: string, title: string, description?: string | null, createdAt: Date | string, updatedAt: Date | string } };

export type DeletePromotionMutationVariables = Exact<{
  where: PromotionWhereUniqueInput;
}>;


export type DeletePromotionMutation = { __typename: 'Mutation', deletePromotion: { __typename: 'Promotion', id: string } };

export type CreateManuallyReceivedPointMutationVariables = Exact<{
  customer: CustomerWhereUniqueInput;
  count: Scalars['Float']['input'];
}>;


export type CreateManuallyReceivedPointMutation = { __typename: 'Mutation', createManuallyReceivedPoint: { __typename: 'ReceivedPoint', id: string, count?: number | null, createdAt: Date | string, updatedAt: Date | string, sourceType: ReceivedPointSourceType } };

export type StartRegistrationFlowMutationVariables = Exact<{
  accountInformation: AccountInformationInput;
  companyInformation: CompanyInformationInput;
}>;


export type StartRegistrationFlowMutation = { __typename: 'Mutation', startRegistrationFlow: { __typename: 'RegistrationFlowModel', executionArn: string, startDate: Date | string } };

export type CreateRewardMutationVariables = Exact<{
  data: CreateRewardInput;
}>;


export type CreateRewardMutation = { __typename: 'Mutation', createReward: { __typename: 'Reward', id: string, name: string, description?: string | null, requiredPoints: number, saving: number, imageKey?: string | null, updatedAt: Date | string, createdAt: Date | string } };

export type SwitchRewardStatusMutationVariables = Exact<{
  input: SwitchRewardStatusInput;
}>;


export type SwitchRewardStatusMutation = { __typename: 'Mutation', switchRewardStatus: { __typename: 'Reward', id: string } };

export type UpdateRewardMutationVariables = Exact<{
  data: RewardUpdateInput;
  where: RewardWhereUniqueInput;
}>;


export type UpdateRewardMutation = { __typename: 'Mutation', updateReward: { __typename: 'Reward', id: string, name: string, description?: string | null, requiredPoints: number, saving: number, imageKey?: string | null, updatedAt: Date | string, createdAt: Date | string } };

export type BranchOfficesQueryVariables = Exact<{
  where?: InputMaybe<BranchOfficeWhereInput>;
  oderBy?: InputMaybe<Array<BranchOfficeOrderByWithRelationInput> | BranchOfficeOrderByWithRelationInput>;
  cursor?: InputMaybe<BranchOfficeWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<BranchOfficeScalarFieldEnum> | BranchOfficeScalarFieldEnum>;
}>;


export type BranchOfficesQuery = { __typename: 'Query', branchOffices: Array<{ __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, updatedAt: Date | string, createdAt: Date | string }> };

export type CashDesksQueryVariables = Exact<{
  where?: InputMaybe<CashDeskWhereInput>;
  orderBy?: InputMaybe<Array<CashDeskOrderByWithRelationInput> | CashDeskOrderByWithRelationInput>;
  cursor?: InputMaybe<CashDeskWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<CashDeskScalarFieldEnum> | CashDeskScalarFieldEnum>;
}>;


export type CashDesksQuery = { __typename: 'Query', cashDesks: Array<{ __typename: 'CashDesk', id: string, name: string, branchOfficeId: string }> };

export type CompanyInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyInformationQuery = { __typename: 'Query', company: { __typename: 'Company', visible: boolean, name: string, email: string, description?: string | null, id: string, logoUrl?: string | null, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, industry: { __typename: 'Industry', id: string, name: string } } };

export type CompanySubscriptionInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanySubscriptionInfoQuery = { __typename: 'Query', company: { __typename: 'Company', id: string, status: CompanyStatus, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string } };

export type CompanyQueryVariables = Exact<{
  where?: InputMaybe<CompanyUniqueInput>;
}>;


export type CompanyQuery = { __typename: 'Query', company: { __typename: 'Company', id: string, logoUrl?: string | null, visible: boolean } };

export type CurrentUsageAmountQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUsageAmountQuery = { __typename: 'Query', company: { __typename: 'Company', id: string, _count: { __typename: 'CompanyCount', branchOffices: number, employees: number, rewards: number, calculationRules: number } } };

export type CouponsQueryVariables = Exact<{
  where?: InputMaybe<CouponWhereInput>;
  orderBy?: InputMaybe<Array<CouponOrderByWithRelationInput> | CouponOrderByWithRelationInput>;
  cursor?: InputMaybe<CouponWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<CouponScalarFieldEnum> | CouponScalarFieldEnum>;
}>;


export type CouponsQuery = { __typename: 'Query', coupons: Array<{ __typename: 'Coupon', id: string, name: string, description?: string | null, type: CouponType, active: boolean, createdAt: Date | string, updatedAt: Date | string, pointsCredit?: number | null, redemptions?: Array<{ __typename: 'RedeemedCoupon', id: string }> | null }> };

export type CustomersQueryVariables = Exact<{
  where?: InputMaybe<CustomerWhereInput>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput> | CustomerOrderByWithRelationInput>;
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<CustomerScalarFieldEnum> | CustomerScalarFieldEnum>;
}>;


export type CustomersQuery = { __typename: 'Query', customers: Array<{ __typename: 'Customer', id: string, username: string, firstName?: string | null, lastName?: string | null, email: string }> };

export type EmployeesQueryVariables = Exact<{
  where?: InputMaybe<EmployeeWhereInput>;
  orderBy?: InputMaybe<Array<EmployeeOrderByWithRelationInput> | EmployeeOrderByWithRelationInput>;
  cursor?: InputMaybe<EmployeeWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<EmployeeScalarFieldEnum> | EmployeeScalarFieldEnum>;
}>;


export type EmployeesQuery = { __typename: 'Query', employees: Array<{ __typename: 'Employee', id?: string | null, firstName?: string | null, lastName?: string | null, email: string, userType: CompanyUserType, status: EmployeeStatus, updatedAt: Date | string, createdAt: Date | string }> };

export type IndustriesQueryVariables = Exact<{
  where?: InputMaybe<IndustryWhereInput>;
  orderBy?: InputMaybe<Array<IndustryOrderByWithRelationInput> | IndustryOrderByWithRelationInput>;
  cursor?: InputMaybe<IndustryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<IndustryScalarFieldEnum> | IndustryScalarFieldEnum>;
}>;


export type IndustriesQuery = { __typename: 'Query', industries: Array<{ __typename: 'Industry', id: string, name: string, description?: string | null }> };

export type NotificationHistoryQueryVariables = Exact<{
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
  currentMonth?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type NotificationHistoryQuery = { __typename: 'Query', notificationHistory: Array<{ __typename: 'NotificationHistory', id: string, title: string, message: string, status: NotificationStatus, affectedCustomers: number, reason?: string | null, createdAt: Date | string, updatedAt?: Date | string | null }> };

export type CheckoutSessionUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckoutSessionUrlQuery = { __typename: 'Query', fetchOpenCheckoutSessionUrl?: string | null };

export type CurrentSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentSubscriptionQuery = { __typename: 'Query', getSubscriptionOfCustomer?: { __typename: 'PaymentSubscription', id: string, billingCycleAnchor: number, cancelAt?: number | null, cancelAtPeriodEnd: boolean, status: string, productQuantities?: Array<{ __typename: 'ProductQuantityInformation', product: StripeProduct, quantity: number }> | null } | null };

export type InvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoicesQuery = { __typename: 'Query', getInvoicesOfCustomer: Array<{ __typename: 'PaymentInvoices', id?: string | null, charge?: string | null, created: number, currency: string, description?: string | null, hostedInvoiceUrl?: string | null, invoicePdf?: string | null, paid: boolean, status: string, tax?: number | null, total: number, number: string }> };

export type NextCancellationDateQueryVariables = Exact<{ [key: string]: never; }>;


export type NextCancellationDateQuery = { __typename: 'Query', nextCancellationDate: { __typename: 'PaymentSubscriptionNextCancellationDateModel', cancellationDate: Date | string } };

export type ProductPricesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductPricesQuery = { __typename: 'Query', getProductPrices: Array<{ __typename: 'ProductPriceInformation', id: string, product: StripeProduct, prices: Array<{ __typename: 'PriceInformation', price: number, maxQuantity: number }> }> };

export type PointSystemCheckQueryVariables = Exact<{ [key: string]: never; }>;


export type PointSystemCheckQuery = { __typename: 'Query', pointSystem?: { __typename: 'PointSystem', pointSystemType: { __typename: 'PointSystemType', type: SystemType } } | null };

export type PointSystemQueryVariables = Exact<{ [key: string]: never; }>;


export type PointSystemQuery = { __typename: 'Query', pointSystem?: { __typename: 'PointSystem', id: string, calculationRuleEntries: number, scanLimit: number, company: { __typename: 'Company', calculationRules?: Array<{ __typename: 'CalculationRule', id: string, points: number, value: number }> | null }, pointSystemType: { __typename: 'PointSystemType', id: string, type: SystemType } } | null };

export type PromotionPeriodsQueryVariables = Exact<{
  where?: InputMaybe<PromotionPeriodWhereInput>;
  orderBy?: InputMaybe<Array<PromotionPeriodOrderByWithRelationInput> | PromotionPeriodOrderByWithRelationInput>;
  cursor?: InputMaybe<PromotionPeriodWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PromotionPeriodScalarFieldEnum> | PromotionPeriodScalarFieldEnum>;
}>;


export type PromotionPeriodsQuery = { __typename: 'Query', promotionPeriods: Array<{ __typename: 'PromotionPeriod', id: string, updatedAt: Date | string, createdAt: Date | string, activeFrom: Date | string, activeUntil: Date | string, promotion: { __typename: 'Promotion', id: string, title: string, description?: string | null, createdAt: Date | string, updatedAt: Date | string } }> };

export type PromotionsQueryVariables = Exact<{
  where?: InputMaybe<PromotionWhereInput>;
  orderBy?: InputMaybe<Array<PromotionOrderByWithRelationInput> | PromotionOrderByWithRelationInput>;
  cursor?: InputMaybe<PromotionWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PromotionScalarFieldEnum> | PromotionScalarFieldEnum>;
}>;


export type PromotionsQuery = { __typename: 'Query', promotions: Array<{ __typename: 'Promotion', id: string, title: string, description?: string | null, createdAt: Date | string, updatedAt: Date | string }> };

export type ReceivedPointsQueryVariables = Exact<{
  where?: InputMaybe<ReceivedPointWhereInput>;
  orderBy?: InputMaybe<Array<ReceivedPointOrderByWithRelationInput> | ReceivedPointOrderByWithRelationInput>;
  cursor?: InputMaybe<ReceivedPointWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<ReceivedPointScalarFieldEnum> | ReceivedPointScalarFieldEnum>;
}>;


export type ReceivedPointsQuery = { __typename: 'Query', receivedPoints: Array<{ __typename: 'ReceivedPoint', id: string, count?: number | null, createdAt: Date | string, updatedAt: Date | string, sourceType: ReceivedPointSourceType }> };

export type RedeemedPointsQueryVariables = Exact<{
  where?: InputMaybe<RedeemedPointWhereInput>;
  orderBy?: InputMaybe<Array<RedeemedPointOrderByWithRelationInput> | RedeemedPointOrderByWithRelationInput>;
  cursor?: InputMaybe<RedeemedPointWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<RedeemedPointScalarFieldEnum> | RedeemedPointScalarFieldEnum>;
}>;


export type RedeemedPointsQuery = { __typename: 'Query', redeemedPoints: Array<{ __typename: 'RedeemedPoint', id: string, createdAt: Date | string, updatedAt: Date | string, reward: { __typename: 'Reward', id: string, name: string, description?: string | null, requiredPoints: number, saving: number, imageKey?: string | null, updatedAt: Date | string, createdAt: Date | string } }> };

export type RewardsQueryVariables = Exact<{
  where?: InputMaybe<RewardWhereInput>;
  orderBy?: InputMaybe<Array<RewardOrderByWithRelationInput> | RewardOrderByWithRelationInput>;
  cursor?: InputMaybe<RewardWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<RewardScalarFieldEnum> | RewardScalarFieldEnum>;
}>;


export type RewardsQuery = { __typename: 'Query', rewards: Array<{ __typename: 'Reward', id: string, name: string, description?: string | null, requiredPoints: number, saving: number, imageKey?: string | null, updatedAt: Date | string, createdAt: Date | string }> };

export type ValidateBaseConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type ValidateBaseConfigurationQuery = { __typename: 'Query', calculationRules: Array<{ __typename: 'CalculationRule', id: string, points: number, value: number }>, pointSystem?: { __typename: 'PointSystem', pointSystemType: { __typename: 'PointSystemType', type: SystemType } } | null, branchOffices: Array<{ __typename: 'BranchOffice', id: string, _count: { __typename: 'BranchOfficeCount', cashDesks: number } }> };

export const BalanceFieldsFragmentDoc = gql`
    fragment BalanceFields on Balance {
  updatedAt
  createdAt
  count
}
    `;
export const BranchOfficeCountFieldsFragmentDoc = gql`
    fragment BranchOfficeCountFields on BranchOffice {
  id
  _count {
    cashDesks
  }
}
    `;
export const BranchOfficeFieldsFragmentDoc = gql`
    fragment BranchOfficeFields on BranchOffice {
  id
  name
  companyId
  description
  latitude
  longitude
  street
  houseNumber
  city
  state
  postalCode
  countryCode
  updatedAt
  createdAt
}
    `;
export const CashDeskFieldsFragmentDoc = gql`
    fragment CashDeskFields on CashDesk {
  id
  name
  branchOfficeId
}
    `;
export const CompanyFieldsFragmentDoc = gql`
    fragment CompanyFields on Company {
  id
  logoUrl
  visible
}
    `;
export const CompanyAddressFieldsFragmentDoc = gql`
    fragment CompanyAddressFields on Company {
  street
  houseNumber
  city
  state
  postalCode
  countryCode
}
    `;
export const CompanyInformationFieldsFragmentDoc = gql`
    fragment CompanyInformationFields on Company {
  ...CompanyFields
  ...CompanyAddressFields
  industry {
    id
    name
  }
  visible
  name
  email
  description
}
    ${CompanyFieldsFragmentDoc}
${CompanyAddressFieldsFragmentDoc}`;
export const CompanySubscriptionInfoFieldsFragmentDoc = gql`
    fragment CompanySubscriptionInfoFields on Company {
  id
  status
  ...CompanyAddressFields
}
    ${CompanyAddressFieldsFragmentDoc}`;
export const ContactFieldsFragmentDoc = gql`
    fragment ContactFields on Contact {
  id
  requestId
}
    `;
export const CouponFieldsFragmentDoc = gql`
    fragment CouponFields on Coupon {
  id
  name
  description
  type
  active
  createdAt
  updatedAt
  pointsCredit
  redemptions {
    id
  }
}
    `;
export const CustomerFieldsFragmentDoc = gql`
    fragment CustomerFields on Customer {
  id
  username
  firstName
  lastName
  email
}
    `;
export const EmployeeFieldsFragmentDoc = gql`
    fragment EmployeeFields on Employee {
  id
  firstName
  lastName
  email
  userType
  status
  updatedAt
  createdAt
}
    `;
export const IndustryFieldsFragmentDoc = gql`
    fragment IndustryFields on Industry {
  id
  name
  description
}
    `;
export const NotificationHistoryFieldsFragmentDoc = gql`
    fragment NotificationHistoryFields on NotificationHistory {
  id
  title
  message
  status
  affectedCustomers
  reason
  createdAt
  updatedAt
}
    `;
export const PaymentInvoiceFieldsFragmentDoc = gql`
    fragment PaymentInvoiceFields on PaymentInvoices {
  id
  charge
  created
  currency
  description
  hostedInvoiceUrl
  invoicePdf
  paid
  status
  tax
  total
  number
}
    `;
export const PaymentSubscriptionFieldsFragmentDoc = gql`
    fragment PaymentSubscriptionFields on PaymentSubscription {
  id
  billingCycleAnchor
  cancelAt
  cancelAtPeriodEnd
  status
  productQuantities {
    product
    quantity
  }
}
    `;
export const PaymentFieldsFragmentDoc = gql`
    fragment PaymentFields on Payment {
  paymentCustomerId
  paymentUrl
}
    `;
export const PointSystemCheckFieldsFragmentDoc = gql`
    fragment PointSystemCheckFields on PointSystem {
  pointSystemType {
    type
  }
}
    `;
export const CalculationRuleFieldsFragmentDoc = gql`
    fragment CalculationRuleFields on CalculationRule {
  id
  points
  value
}
    `;
export const PointSystemTypeFieldsFragmentDoc = gql`
    fragment PointSystemTypeFields on PointSystemType {
  id
  type
}
    `;
export const PointSystemFieldsFragmentDoc = gql`
    fragment PointSystemFields on PointSystem {
  id
  company {
    calculationRules {
      ...CalculationRuleFields
    }
  }
  calculationRuleEntries
  pointSystemType {
    ...PointSystemTypeFields
  }
  scanLimit
}
    ${CalculationRuleFieldsFragmentDoc}
${PointSystemTypeFieldsFragmentDoc}`;
export const ProductPriceFieldsFragmentDoc = gql`
    fragment ProductPriceFields on ProductPriceInformation {
  id
  product
  prices {
    price
    maxQuantity
  }
}
    `;
export const PromotionFieldsFragmentDoc = gql`
    fragment PromotionFields on Promotion {
  id
  title
  description
  createdAt
  updatedAt
}
    `;
export const PromotionPeriodFieldsFragmentDoc = gql`
    fragment PromotionPeriodFields on PromotionPeriod {
  id
  updatedAt
  createdAt
  activeFrom
  activeUntil
  promotion {
    ...PromotionFields
  }
}
    ${PromotionFieldsFragmentDoc}`;
export const ReceivedPointFieldsFragmentDoc = gql`
    fragment ReceivedPointFields on ReceivedPoint {
  id
  count
  createdAt
  updatedAt
  sourceType
}
    `;
export const RewardFieldsFragmentDoc = gql`
    fragment RewardFields on Reward {
  id
  name
  description
  requiredPoints
  saving
  imageKey
  updatedAt
  createdAt
}
    `;
export const RedeemedPointFieldsFragmentDoc = gql`
    fragment RedeemedPointFields on RedeemedPoint {
  id
  createdAt
  updatedAt
  reward {
    ...RewardFields
  }
}
    ${RewardFieldsFragmentDoc}`;
export const CreateBranchOfficeWithCashDeskDocument = gql`
    mutation CreateBranchOfficeWithCashDesk($createInput: CreateBranchOfficeWithCashDeskInput!) {
  createBranchOffice(createInput: $createInput) {
    ...BranchOfficeFields
  }
}
    ${BranchOfficeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBranchOfficeWithCashDeskGQL extends Apollo.Mutation<CreateBranchOfficeWithCashDeskMutation, CreateBranchOfficeWithCashDeskMutationVariables> {
    override document = CreateBranchOfficeWithCashDeskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBranchOfficeDocument = gql`
    mutation DeleteBranchOffice($where: BranchOfficeUniqueInput!) {
  deleteBranchOffice(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBranchOfficeGQL extends Apollo.Mutation<DeleteBranchOfficeMutation, DeleteBranchOfficeMutationVariables> {
    override document = DeleteBranchOfficeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBranchOfficeDocument = gql`
    mutation UpdateBranchOffice($data: BranchOfficeUpdateInput!, $where: BranchOfficeWhereUniqueInput!) {
  updateBranchOffice(where: $where, data: $data) {
    ...BranchOfficeFields
  }
}
    ${BranchOfficeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBranchOfficeGQL extends Apollo.Mutation<UpdateBranchOfficeMutation, UpdateBranchOfficeMutationVariables> {
    override document = UpdateBranchOfficeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOrChangeCalculationRuleDocument = gql`
    mutation CreateOrChangeCalculationRule($data: CreateOrChangeCalculationRuleInput!) {
  createOrChangeCalculationRule(data: $data) {
    ...CalculationRuleFields
  }
}
    ${CalculationRuleFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrChangeCalculationRuleGQL extends Apollo.Mutation<CreateOrChangeCalculationRuleMutation, CreateOrChangeCalculationRuleMutationVariables> {
    override document = CreateOrChangeCalculationRuleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCashDesksDocument = gql`
    mutation CreateCashDesks($createInput: [CreateCashDeskWithoutTransactionInput!]!) {
  createCashDesk(createInput: $createInput) {
    ...CashDeskFields
  }
}
    ${CashDeskFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCashDesksGQL extends Apollo.Mutation<CreateCashDesksMutation, CreateCashDesksMutationVariables> {
    override document = CreateCashDesksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCashDeskDocument = gql`
    mutation DeleteCashDesk($uniqueInput: CashDeskUniqueInput!) {
  deleteCashDesk(uniqueInput: $uniqueInput) {
    id
    branchOfficeId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCashDeskGQL extends Apollo.Mutation<DeleteCashDeskMutation, DeleteCashDeskMutationVariables> {
    override document = DeleteCashDeskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCashDeskDocument = gql`
    mutation UpdateCashDesk($updateInput: UpdateCashDeskArgs!) {
  updateCashDesk(updateInput: $updateInput) {
    ...CashDeskFields
  }
}
    ${CashDeskFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCashDeskGQL extends Apollo.Mutation<UpdateCashDeskMutation, UpdateCashDeskMutationVariables> {
    override document = UpdateCashDeskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeVisibilityDocument = gql`
    mutation ChangeVisibility($visible: Boolean!) {
  changeVisibility(visible: $visible) {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeVisibilityGQL extends Apollo.Mutation<ChangeVisibilityMutation, ChangeVisibilityMutationVariables> {
    override document = ChangeVisibilityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($data: UpdateCompanyInput!, $where: CompanyUniqueInput) {
  updateCompany(data: $data, where: $where) {
    ...CompanyInformationFields
  }
}
    ${CompanyInformationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCompanyGQL extends Apollo.Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables> {
    override document = UpdateCompanyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContactSupportDocument = gql`
    mutation ContactSupport($data: ContactSupportInput!) {
  contactSupport(data: $data) {
    ...ContactFields
  }
}
    ${ContactFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactSupportGQL extends Apollo.Mutation<ContactSupportMutation, ContactSupportMutationVariables> {
    override document = ContactSupportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCouponDocument = gql`
    mutation CreateCoupon($data: CouponCreateInput!) {
  createCoupon(data: $data) {
    ...CouponFields
  }
}
    ${CouponFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCouponGQL extends Apollo.Mutation<CreateCouponMutation, CreateCouponMutationVariables> {
    override document = CreateCouponDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SwitchCouponStatusDocument = gql`
    mutation SwitchCouponStatus($where: CouponUniqueInput!, $data: SwitchCouponStatusInput!) {
  switchCouponStatus(where: $where, data: $data) {
    ...CouponFields
  }
}
    ${CouponFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SwitchCouponStatusGQL extends Apollo.Mutation<SwitchCouponStatusMutation, SwitchCouponStatusMutationVariables> {
    override document = SwitchCouponStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEmployeeDocument = gql`
    mutation DeleteEmployee($where: EmployeeIdentifierInput!) {
  deleteEmployee(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEmployeeGQL extends Apollo.Mutation<DeleteEmployeeMutation, DeleteEmployeeMutationVariables> {
    override document = DeleteEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InviteEmployeeDocument = gql`
    mutation InviteEmployee($email: String!) {
  inviteEmployee(email: $email) {
    ...EmployeeFields
  }
}
    ${EmployeeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InviteEmployeeGQL extends Apollo.Mutation<InviteEmployeeMutation, InviteEmployeeMutationVariables> {
    override document = InviteEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendPasswordChangeEmailDocument = gql`
    mutation SendPasswordChangeEmail {
  sendPasswordChangeEmail {
    ...EmployeeFields
  }
}
    ${EmployeeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SendPasswordChangeEmailGQL extends Apollo.Mutation<SendPasswordChangeEmailMutation, SendPasswordChangeEmailMutationVariables> {
    override document = SendPasswordChangeEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($data: UpdateEmployeeInput!, $where: EmployeeUniqueInput) {
  updateEmployee(data: $data, where: $where) {
    ...EmployeeFields
  }
}
    ${EmployeeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEmployeeGQL extends Apollo.Mutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables> {
    override document = UpdateEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateNotificationHistoryDocument = gql`
    mutation CreateNotificationHistory($createInput: CreateNotificationHistoryInput!) {
  createNotificationHistory(createInput: $createInput) {
    ...NotificationHistoryFields
  }
}
    ${NotificationHistoryFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateNotificationHistoryGQL extends Apollo.Mutation<CreateNotificationHistoryMutation, CreateNotificationHistoryMutationVariables> {
    override document = CreateNotificationHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelSubscriptionAtNoticePeriodDocument = gql`
    mutation CancelSubscriptionAtNoticePeriod {
  cancelSubscriptionAtNoticePeriod {
    ...PaymentSubscriptionFields
  }
}
    ${PaymentSubscriptionFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelSubscriptionAtNoticePeriodGQL extends Apollo.Mutation<CancelSubscriptionAtNoticePeriodMutation, CancelSubscriptionAtNoticePeriodMutationVariables> {
    override document = CancelSubscriptionAtNoticePeriodDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePaymentCustomerDocument = gql`
    mutation CreatePaymentCustomer($paymentCustomer: PaymentCreateCustomerInput!, $paymentSuccessRedirectUrl: String!) {
  createPaymentCustomer(
    paymentCustomer: $paymentCustomer
    paymentSuccessRedirectUrl: $paymentSuccessRedirectUrl
  ) {
    ...PaymentFields
  }
}
    ${PaymentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePaymentCustomerGQL extends Apollo.Mutation<CreatePaymentCustomerMutation, CreatePaymentCustomerMutationVariables> {
    override document = CreatePaymentCustomerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSubscriptionForExistingCustomerDocument = gql`
    mutation CreateSubscriptionForExistingCustomer($paymentSubscription: PaymentCreateSubscriptionInput, $paymentSuccessRedirectUrl: String!) {
  createSubscription(
    paymentSubscription: $paymentSubscription
    paymentSuccessRedirectUrl: $paymentSuccessRedirectUrl
  ) {
    ...PaymentFields
  }
}
    ${PaymentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSubscriptionForExistingCustomerGQL extends Apollo.Mutation<CreateSubscriptionForExistingCustomerMutation, CreateSubscriptionForExistingCustomerMutationVariables> {
    override document = CreateSubscriptionForExistingCustomerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpgradeSubscriptionItemsDocument = gql`
    mutation UpgradeSubscriptionItems($upgradeInput: [SubscriptionItemInput!]!) {
  upgradeSubscriptionItems(upgradeInput: $upgradeInput) {
    product
    action
    additionalQuantity
    subscription
    error {
      cause
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpgradeSubscriptionItemsGQL extends Apollo.Mutation<UpgradeSubscriptionItemsMutation, UpgradeSubscriptionItemsMutationVariables> {
    override document = UpgradeSubscriptionItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangePointSystemDocument = gql`
    mutation ChangePointSystem($input: ChangePointSystemInput!) {
  changePointSystem(input: $input) {
    ...PointSystemFields
  }
}
    ${PointSystemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangePointSystemGQL extends Apollo.Mutation<ChangePointSystemMutation, ChangePointSystemMutationVariables> {
    override document = ChangePointSystemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePointSystemDocument = gql`
    mutation CreatePointSystem($createInput: PointSystemCreateInput!) {
  createPointSystem(createInput: $createInput) {
    ...PointSystemFields
  }
}
    ${PointSystemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePointSystemGQL extends Apollo.Mutation<CreatePointSystemMutation, CreatePointSystemMutationVariables> {
    override document = CreatePointSystemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePromotionPeriodDocument = gql`
    mutation CreatePromotionPeriod($data: PromotionPeriodCreateInput!) {
  createPromotionPeriod(data: $data) {
    ...PromotionPeriodFields
  }
}
    ${PromotionPeriodFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePromotionPeriodGQL extends Apollo.Mutation<CreatePromotionPeriodMutation, CreatePromotionPeriodMutationVariables> {
    override document = CreatePromotionPeriodDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePromotionPeriodDocument = gql`
    mutation DeletePromotionPeriod($where: PromotionPeriodWhereUniqueInput!) {
  deletePromotionPeriod(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePromotionPeriodGQL extends Apollo.Mutation<DeletePromotionPeriodMutation, DeletePromotionPeriodMutationVariables> {
    override document = DeletePromotionPeriodDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePromotionPeriodDocument = gql`
    mutation UpdatePromotionPeriod($where: PromotionPeriodUniqueInput!, $data: PromotionPeriodUpdateInput!) {
  updatePromotionPeriod(where: $where, data: $data) {
    ...PromotionPeriodFields
  }
}
    ${PromotionPeriodFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePromotionPeriodGQL extends Apollo.Mutation<UpdatePromotionPeriodMutation, UpdatePromotionPeriodMutationVariables> {
    override document = UpdatePromotionPeriodDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePromotionDocument = gql`
    mutation CreatePromotion($data: PromotionCreateInput!) {
  createPromotion(data: $data) {
    ...PromotionFields
  }
}
    ${PromotionFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePromotionGQL extends Apollo.Mutation<CreatePromotionMutation, CreatePromotionMutationVariables> {
    override document = CreatePromotionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePromotionDocument = gql`
    mutation DeletePromotion($where: PromotionWhereUniqueInput!) {
  deletePromotion(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePromotionGQL extends Apollo.Mutation<DeletePromotionMutation, DeletePromotionMutationVariables> {
    override document = DeletePromotionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateManuallyReceivedPointDocument = gql`
    mutation CreateManuallyReceivedPoint($customer: CustomerWhereUniqueInput!, $count: Float!) {
  createManuallyReceivedPoint(count: $count, customer: $customer) {
    ...ReceivedPointFields
  }
}
    ${ReceivedPointFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateManuallyReceivedPointGQL extends Apollo.Mutation<CreateManuallyReceivedPointMutation, CreateManuallyReceivedPointMutationVariables> {
    override document = CreateManuallyReceivedPointDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StartRegistrationFlowDocument = gql`
    mutation StartRegistrationFlow($accountInformation: AccountInformationInput!, $companyInformation: CompanyInformationInput!) {
  startRegistrationFlow(
    accountInformation: $accountInformation
    companyInformation: $companyInformation
  ) {
    executionArn
    startDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StartRegistrationFlowGQL extends Apollo.Mutation<StartRegistrationFlowMutation, StartRegistrationFlowMutationVariables> {
    override document = StartRegistrationFlowDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateRewardDocument = gql`
    mutation CreateReward($data: CreateRewardInput!) {
  createReward(data: $data) {
    ...RewardFields
  }
}
    ${RewardFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateRewardGQL extends Apollo.Mutation<CreateRewardMutation, CreateRewardMutationVariables> {
    override document = CreateRewardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SwitchRewardStatusDocument = gql`
    mutation SwitchRewardStatus($input: SwitchRewardStatusInput!) {
  switchRewardStatus(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SwitchRewardStatusGQL extends Apollo.Mutation<SwitchRewardStatusMutation, SwitchRewardStatusMutationVariables> {
    override document = SwitchRewardStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateRewardDocument = gql`
    mutation UpdateReward($data: RewardUpdateInput!, $where: RewardWhereUniqueInput!) {
  updateReward(data: $data, where: $where) {
    ...RewardFields
  }
}
    ${RewardFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRewardGQL extends Apollo.Mutation<UpdateRewardMutation, UpdateRewardMutationVariables> {
    override document = UpdateRewardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BranchOfficesDocument = gql`
    query BranchOffices($where: BranchOfficeWhereInput, $oderBy: [BranchOfficeOrderByWithRelationInput!], $cursor: BranchOfficeWhereUniqueInput, $take: Int, $skip: Int, $distinct: [BranchOfficeScalarFieldEnum!]) {
  branchOffices(
    where: $where
    orderBy: $oderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...BranchOfficeFields
  }
}
    ${BranchOfficeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BranchOfficesGQL extends Apollo.Query<BranchOfficesQuery, BranchOfficesQueryVariables> {
    override document = BranchOfficesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CashDesksDocument = gql`
    query CashDesks($where: CashDeskWhereInput, $orderBy: [CashDeskOrderByWithRelationInput!], $cursor: CashDeskWhereUniqueInput, $take: Int, $skip: Int, $distinct: [CashDeskScalarFieldEnum!]) {
  cashDesks(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...CashDeskFields
  }
}
    ${CashDeskFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CashDesksGQL extends Apollo.Query<CashDesksQuery, CashDesksQueryVariables> {
    override document = CashDesksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompanyInformationDocument = gql`
    query CompanyInformation {
  company {
    ...CompanyInformationFields
  }
}
    ${CompanyInformationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CompanyInformationGQL extends Apollo.Query<CompanyInformationQuery, CompanyInformationQueryVariables> {
    override document = CompanyInformationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompanySubscriptionInfoDocument = gql`
    query CompanySubscriptionInfo {
  company {
    ...CompanySubscriptionInfoFields
  }
}
    ${CompanySubscriptionInfoFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CompanySubscriptionInfoGQL extends Apollo.Query<CompanySubscriptionInfoQuery, CompanySubscriptionInfoQueryVariables> {
    override document = CompanySubscriptionInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompanyDocument = gql`
    query Company($where: CompanyUniqueInput) {
  company(where: $where) {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CompanyGQL extends Apollo.Query<CompanyQuery, CompanyQueryVariables> {
    override document = CompanyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUsageAmountDocument = gql`
    query CurrentUsageAmount {
  company {
    id
    _count(onlyActiveRewards: true) {
      branchOffices
      employees
      rewards
      calculationRules
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUsageAmountGQL extends Apollo.Query<CurrentUsageAmountQuery, CurrentUsageAmountQueryVariables> {
    override document = CurrentUsageAmountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CouponsDocument = gql`
    query Coupons($where: CouponWhereInput, $orderBy: [CouponOrderByWithRelationInput!], $cursor: CouponWhereUniqueInput, $take: Int, $skip: Int, $distinct: [CouponScalarFieldEnum!]) {
  coupons(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...CouponFields
  }
}
    ${CouponFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CouponsGQL extends Apollo.Query<CouponsQuery, CouponsQueryVariables> {
    override document = CouponsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CustomersDocument = gql`
    query Customers($where: CustomerWhereInput, $orderBy: [CustomerOrderByWithRelationInput!], $cursor: CustomerWhereUniqueInput, $take: Int, $skip: Int, $distinct: [CustomerScalarFieldEnum!]) {
  customers(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...CustomerFields
  }
}
    ${CustomerFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CustomersGQL extends Apollo.Query<CustomersQuery, CustomersQueryVariables> {
    override document = CustomersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EmployeesDocument = gql`
    query Employees($where: EmployeeWhereInput, $orderBy: [EmployeeOrderByWithRelationInput!], $cursor: EmployeeWhereUniqueInput, $take: Int, $skip: Int, $distinct: [EmployeeScalarFieldEnum!]) {
  employees(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...EmployeeFields
  }
}
    ${EmployeeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EmployeesGQL extends Apollo.Query<EmployeesQuery, EmployeesQueryVariables> {
    override document = EmployeesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndustriesDocument = gql`
    query Industries($where: IndustryWhereInput, $orderBy: [IndustryOrderByWithRelationInput!], $cursor: IndustryWhereUniqueInput, $take: Int, $skip: Int, $distinct: [IndustryScalarFieldEnum!]) {
  industries(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...IndustryFields
  }
}
    ${IndustryFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndustriesGQL extends Apollo.Query<IndustriesQuery, IndustriesQueryVariables> {
    override document = IndustriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NotificationHistoryDocument = gql`
    query NotificationHistory($skipDuplicates: Boolean, $currentMonth: Boolean) {
  notificationHistory(
    skipDuplicates: $skipDuplicates
    currentMonth: $currentMonth
  ) {
    ...NotificationHistoryFields
  }
}
    ${NotificationHistoryFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NotificationHistoryGQL extends Apollo.Query<NotificationHistoryQuery, NotificationHistoryQueryVariables> {
    override document = NotificationHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckoutSessionUrlDocument = gql`
    query CheckoutSessionUrl {
  fetchOpenCheckoutSessionUrl
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckoutSessionUrlGQL extends Apollo.Query<CheckoutSessionUrlQuery, CheckoutSessionUrlQueryVariables> {
    override document = CheckoutSessionUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentSubscriptionDocument = gql`
    query CurrentSubscription {
  getSubscriptionOfCustomer {
    ...PaymentSubscriptionFields
  }
}
    ${PaymentSubscriptionFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentSubscriptionGQL extends Apollo.Query<CurrentSubscriptionQuery, CurrentSubscriptionQueryVariables> {
    override document = CurrentSubscriptionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoicesDocument = gql`
    query Invoices {
  getInvoicesOfCustomer {
    ...PaymentInvoiceFields
  }
}
    ${PaymentInvoiceFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoicesGQL extends Apollo.Query<InvoicesQuery, InvoicesQueryVariables> {
    override document = InvoicesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NextCancellationDateDocument = gql`
    query NextCancellationDate {
  nextCancellationDate {
    cancellationDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NextCancellationDateGQL extends Apollo.Query<NextCancellationDateQuery, NextCancellationDateQueryVariables> {
    override document = NextCancellationDateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductPricesDocument = gql`
    query ProductPrices {
  getProductPrices {
    ...ProductPriceFields
  }
}
    ${ProductPriceFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductPricesGQL extends Apollo.Query<ProductPricesQuery, ProductPricesQueryVariables> {
    override document = ProductPricesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PointSystemCheckDocument = gql`
    query PointSystemCheck {
  pointSystem {
    ...PointSystemCheckFields
  }
}
    ${PointSystemCheckFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PointSystemCheckGQL extends Apollo.Query<PointSystemCheckQuery, PointSystemCheckQueryVariables> {
    override document = PointSystemCheckDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PointSystemDocument = gql`
    query PointSystem {
  pointSystem {
    ...PointSystemFields
  }
}
    ${PointSystemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PointSystemGQL extends Apollo.Query<PointSystemQuery, PointSystemQueryVariables> {
    override document = PointSystemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionPeriodsDocument = gql`
    query PromotionPeriods($where: PromotionPeriodWhereInput, $orderBy: [PromotionPeriodOrderByWithRelationInput!], $cursor: PromotionPeriodWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PromotionPeriodScalarFieldEnum!]) {
  promotionPeriods(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...PromotionPeriodFields
  }
}
    ${PromotionPeriodFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionPeriodsGQL extends Apollo.Query<PromotionPeriodsQuery, PromotionPeriodsQueryVariables> {
    override document = PromotionPeriodsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionsDocument = gql`
    query Promotions($where: PromotionWhereInput, $orderBy: [PromotionOrderByWithRelationInput!], $cursor: PromotionWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PromotionScalarFieldEnum!]) {
  promotions(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...PromotionFields
  }
}
    ${PromotionFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionsGQL extends Apollo.Query<PromotionsQuery, PromotionsQueryVariables> {
    override document = PromotionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReceivedPointsDocument = gql`
    query ReceivedPoints($where: ReceivedPointWhereInput, $orderBy: [ReceivedPointOrderByWithRelationInput!], $cursor: ReceivedPointWhereUniqueInput, $take: Int, $skip: Int, $distinct: [ReceivedPointScalarFieldEnum!]) {
  receivedPoints(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...ReceivedPointFields
  }
}
    ${ReceivedPointFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ReceivedPointsGQL extends Apollo.Query<ReceivedPointsQuery, ReceivedPointsQueryVariables> {
    override document = ReceivedPointsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RedeemedPointsDocument = gql`
    query RedeemedPoints($where: RedeemedPointWhereInput, $orderBy: [RedeemedPointOrderByWithRelationInput!], $cursor: RedeemedPointWhereUniqueInput, $take: Int, $skip: Int, $distinct: [RedeemedPointScalarFieldEnum!]) {
  redeemedPoints(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...RedeemedPointFields
  }
}
    ${RedeemedPointFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RedeemedPointsGQL extends Apollo.Query<RedeemedPointsQuery, RedeemedPointsQueryVariables> {
    override document = RedeemedPointsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RewardsDocument = gql`
    query Rewards($where: RewardWhereInput, $orderBy: [RewardOrderByWithRelationInput!], $cursor: RewardWhereUniqueInput, $take: Int, $skip: Int, $distinct: [RewardScalarFieldEnum!]) {
  rewards(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...RewardFields
  }
}
    ${RewardFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RewardsGQL extends Apollo.Query<RewardsQuery, RewardsQueryVariables> {
    override document = RewardsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidateBaseConfigurationDocument = gql`
    query ValidateBaseConfiguration {
  calculationRules {
    ...CalculationRuleFields
  }
  pointSystem {
    ...PointSystemCheckFields
  }
  branchOffices {
    ...BranchOfficeCountFields
  }
}
    ${CalculationRuleFieldsFragmentDoc}
${PointSystemCheckFieldsFragmentDoc}
${BranchOfficeCountFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidateBaseConfigurationGQL extends Apollo.Query<ValidateBaseConfigurationQuery, ValidateBaseConfigurationQueryVariables> {
    override document = ValidateBaseConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }