import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StorageValue = string | number | boolean | object | Array<any>;

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  get<T extends StorageValue>(key: string): T | null {
    const item = localStorage.getItem(key);

    if (item === null || item === undefined) {
      return null;
    }

    try {
      return JSON.parse(item) as T;
    } catch (e) {
      return item as T;
    }
  }

  set<T extends StorageValue>(key: string, value: T): void {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, String(value));
    }
  }

  has(key: string): boolean {
    return !!localStorage.getItem(key);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}

export class MemoryStorageService {
  private _store: { [k: string]: string } = {};

  get<T extends StorageValue>(key: string): T | null {
    const item = this._store[key];

    if (item === null || item === undefined) {
      return null;
    }

    try {
      return JSON.parse(item) as T;
    } catch (e) {
      return item as T;
    }
  }

  set<T extends StorageValue>(key: string, value: T): void {
    if (typeof value === 'object') {
      this._store[key] = JSON.stringify(value);
    } else {
      this._store[key] = String(value);
    }
  }

  has(key: string): boolean {
    return !!this._store[key];
  }

  remove(key: string) {
    delete this._store[key];
  }

  clear() {
    this._store = {};
  }
}
