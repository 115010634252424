import { Injectable } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ConfirmationService } from '../confirmation/confirmation.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation/dialog/dialog.component';
import { MapGeocoder } from '@angular/google-maps';
import GeocoderResult = google.maps.GeocoderResult;
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import { TranslateService } from '@ngx-translate/core';

export interface AddressFormControl {
  address: FormGroup<AddressForm>;
}

export interface AddressForm {
  houseNumber: FormControl<string>;
  street: FormControl<string>;
  city: FormControl<string>;
  postalCode: FormControl<string>;
  countryCode: FormControl<string>;
  state: FormControl<string>;
  latitude: FormControl<number>;
  longitude: FormControl<number>;
}

type ExtractedGeocodingData = {
  [P in keyof AddressForm]: P extends 'latitude' | 'longitude'
    ? ResolvedGeocodingValue<string | number>
    : ResolvedGeocodingValue<string>;
};

interface ResolvedGeocodingValue<T extends string | number> {
  longName?: T | null;
  shortName?: T | null;
  validationResponseValue: T | null;
}

interface AddressValidationResponse {
  addressMatches: boolean;
  response: FormKeysAddressValidation;
}

type FormKeysAddressValidation = {
  [K in keyof AddressFormRawValue]: InputCompareWithGeocoding;
};

interface InputCompareWithGeocoding {
  inputValue: string | number | null;
  geocodingValue: string | number | null;
}

type AddressFormRawValueType<T extends FormGroup<AddressForm>> = ReturnType<
  T['getRawValue']
>;

type AddressFormRawValue = AddressFormRawValueType<FormGroup<AddressForm>>;

/**
 * Service to provide address validation using Google Maps Geocoding API.
 * This service allows validation and correction of addresses entered by the user.
 */
@Injectable({
  providedIn: 'root',
})
export class AddressValidationService {
  constructor(
    private readonly _geocoder: MapGeocoder,
    private readonly _fb: FormBuilder,
    private readonly _confirmation: ConfirmationService,
    private readonly _translate: TranslateService
  ) {}

  /**
   * Creates a form group for capturing and validating address information.
   * @returns {FormGroup<AddressForm>} The `FormGroup` configured with validators for address fields.
   */
  createAddressForm(): FormGroup<AddressForm> {
    return this._fb.group<AddressForm>({
      /* eslint-disable @typescript-eslint/unbound-method */
      street: this._fb.control('', {
        nonNullable: true,
        validators: Validators.required,
      }),
      houseNumber: this._fb.control('', {
        nonNullable: true,
        validators: Validators.required,
      }),
      city: this._fb.control('', {
        nonNullable: true,
        validators: Validators.required,
      }),
      postalCode: this._fb.control('', {
        nonNullable: true,
        validators: Validators.required,
      }),
      countryCode: this._fb.control('', {
        nonNullable: true,
        validators: Validators.required,
      }),
      state: this._fb.control('', {
        nonNullable: true,
        validators: Validators.required,
      }),
      latitude: this._fb.control(0, {
        nonNullable: true,
        validators: Validators.required,
      }),
      longitude: this._fb.control(0, {
        nonNullable: true,
        validators: Validators.required,
      }),
    });
  }

  /**
   * Validates an address using Google Maps Geocoding API.
   * It compares the entered address with geocoding data to check for accuracy.
   * @param {FormGroup<AddressForm>} form - The form containing the address information to validate.
   * @returns {Observable<boolean>} Observable emitting a boolean indicating if the address is valid or not.
   * @example
   * interface MyForm {
   *   name: FormControl<string>;
   * }
   *
   * interface MyFormWithAddress extends MyForm, AddressFormControl;
   *
   * export class MyComponent {
   *   public myForm: FormGroup<MyFormWithAddress> = this._fb.group<MyFormWithAddress>({
   *     name: this._fb.control('', { nonNullable: true }),
   *     address: this._addressValidation.createAddressForm(),
   *   });
   *   constructor(private readonly _fb: FormBuilder) {}
   *
   *   submitForm(): void {
   *     this._addressValidation.validateAddress(this.myForm.controls.address).subscribe(isValid => {
   *        console.log(isValid); // true or false
   *     });
   *   }
   * }
   */
  validateAddress(form: FormGroup<AddressForm>): Observable<boolean> {
    const addressString = this._constructAddressString(form.getRawValue());
    return this._getGeocoding(addressString).pipe(
      switchMap(geoResponse =>
        this._processGeocodingResponse(geoResponse, form)
      ),
      switchMap(validationResponse =>
        this._handleValidationResult(validationResponse, form)
      )
    );
  }

  /**
   * Sends a geocoding request to Google Maps API with the given address.
   * @param {string} address - The address string to be geocoded.
   * @private
   * @returns {Observable<GeocoderResult>} Observable emitting the geocoding results.
   */
  private _getGeocoding(address: string): Observable<GeocoderResult[]> {
    return this._geocoder
      .geocode({ address })
      .pipe(map(response => response.results));
  }

  /**
   * Constructs a full address string from the form's raw value.
   * @param {AddressFormRawValue} formValue - The raw value of the {@link AddressForm}.
   * @returns {string} The constructed address string.
   * @private
   */
  private _constructAddressString(formValue: AddressFormRawValue): string {
    const { street, houseNumber, postalCode, city } = formValue;
    return `${houseNumber} ${street}, ${postalCode} ${city}`;
  }

  /**
   * Processes the geocoding response and compares it with the form data.
   * @param {GeocoderResult[]} results - Array of results from the geocoding request.
   * @param {FormGroup<AddressForm>} form - The form containing address data.
   * @private
   * @returns {Observable<AddressValidationResponse>} Observable emitting the validation response.
   * @throws {Error} Throws an error if no results are found.
   */
  private _processGeocodingResponse(
    results: GeocoderResult[],
    form: FormGroup<AddressForm>
  ): Observable<AddressValidationResponse> {
    if (results.length > 0) {
      const result = results[0];
      return this._compareFormWithGeocoding(result, form);
    } else {
      throw new Error(
        this._translate.instant('addressValidation.not-found') as string
      );
    }
  }

  /**
   * Compares form data with geocoding data to validate the address.
   * @param {GeocoderResult} result - The first result from the geocoding request.
   * @param {FormGroup<AddressForm>} form - The form containing address data.
   * @private
   * @returns {Observable<AddressValidationResponse>} Observable emitting the comparison result.
   */
  private _compareFormWithGeocoding(
    result: GeocoderResult,
    form: FormGroup<AddressForm>
  ): Observable<AddressValidationResponse> {
    const geocodedData = this._extractGeocodingData(result);
    const addressMatches = this._doesAddressMatch(
      form.getRawValue(),
      geocodedData
    );

    return of({
      addressMatches,
      response: this._createValidationResponse(
        form.getRawValue(),
        geocodedData
      ),
    });
  }

  /**
   * Extracts relevant data from geocoding result for each address form field.
   * @param {GeocoderResult} result - The result from the geocoding request.
   * @private
   * @returns {ExtractedGeocodingData} An object containing the extracted data for each form field.
   */
  private _extractGeocodingData(
    result: GeocoderResult
  ): ExtractedGeocodingData {
    const addressComponents = result.address_components;

    return {
      street: this._getAddressComponent('route', addressComponents),
      houseNumber: this._getAddressComponent(
        'street_number',
        addressComponents
      ),
      city: this._getAddressComponent('locality', addressComponents),
      postalCode: this._getAddressComponent('postal_code', addressComponents),
      state: this._getAddressComponent(
        'administrative_area_level_1',
        addressComponents
      ),
      countryCode: this._getAddressComponent('country', addressComponents),
      latitude: {
        validationResponseValue: result.geometry.location.lat(),
      },
      longitude: {
        validationResponseValue: result.geometry.location.lng(),
      },
    };
  }

  /**
   * Retrieves a specific address component (e.g. street, city) from geocoding results.
   * @param {string} type - The type of address component to retrieve.
   * @param {GeocoderAddressComponent[]} components - Array of address components.
   * @private
   * @returns {ResolvedGeocodingValue} An object containing the resolved value for the requested component.
   */
  private _getAddressComponent(
    type: string,
    components: GeocoderAddressComponent[]
  ): ResolvedGeocodingValue<string> {
    const component = components.find(c => c.types.includes(type));
    if (type === 'administrative_area_level_1' || type === 'locality') {
      return {
        longName: component ? component.long_name : null,
        shortName: component ? component.short_name : null,
        validationResponseValue: component ? component.long_name : null,
      };
    }
    return {
      longName: component ? component.long_name : null,
      shortName: component ? component.short_name : null,
      validationResponseValue: component ? component.short_name : null,
    };
  }

  /**
   * Compares form data with geocoding data to determine if the address matches.
   * @param {AddressFormRawValue} formData - The raw values from the {@link AddressForm}
   * @param {ExtractedGeocodingData} geocodedData - The extracted geocoding data.
   * @private
   * @returns {boolean} True if the address matches, false otherwise.
   */
  private _doesAddressMatch(
    formData: AddressFormRawValue,
    geocodedData: ExtractedGeocodingData
  ): boolean {
    return (
      (formData.street.toLowerCase() ===
        geocodedData.street.longName?.toLowerCase() ||
        formData.street.toLowerCase() ===
          geocodedData.street.shortName?.toLowerCase()) &&
      (formData.houseNumber.toLowerCase() ===
        geocodedData.houseNumber.longName?.toLowerCase() ||
        formData.houseNumber.toLowerCase() ===
          geocodedData.houseNumber.shortName?.toLowerCase()) &&
      (formData.city.toLowerCase() ===
        geocodedData.city.longName?.toLowerCase() ||
        formData.city.toLowerCase() ===
          geocodedData.city.shortName?.toLowerCase()) &&
      (formData.postalCode.toLowerCase() ===
        geocodedData.postalCode.longName?.toLowerCase() ||
        formData.postalCode.toLowerCase() ===
          geocodedData.postalCode.shortName?.toLowerCase())
    );
  }

  /**
   *Creates a response object for address validation by comparing form data with geocoding data.
   * @param {AddressFormRawValue} formData - The raw values from the {@link AddressForm}.
   * @param {ExtractedGeocodingData} geocodedData - The extracted geocoding data.
   * @private
   * @returns {FormKeysAddressValidation} An object representing the validation response.
   */
  private _createValidationResponse(
    formData: AddressFormRawValue,
    geocodedData: ExtractedGeocodingData
  ): FormKeysAddressValidation {
    return Object.keys(formData).reduce((previousValue, currentValue) => {
      const obj = {
        [currentValue]: {
          inputValue: formData[currentValue as keyof AddressFormRawValue],
          geocodingValue:
            geocodedData[currentValue as keyof ExtractedGeocodingData]
              .validationResponseValue,
        },
      };
      return Object.assign(previousValue, obj);
    }, {} as FormKeysAddressValidation);
  }

  /**
   * Handles the result of the address validation, updating the form or prompting the user for confirmation.
   * @param {AddressValidationResponse} validationResponse - The validation response object.
   * @param {FormGroup<AddressForm>} form - The form containing address data.
   * @private
   * @returns {Observable<boolean>} Observable indicating the outcome of the validation handling.
   */
  private _handleValidationResult(
    validationResponse: AddressValidationResponse,
    form: FormGroup<AddressForm>
  ): Observable<boolean> {
    const { addressMatches, response } = validationResponse;

    if (addressMatches) {
      this._updateFormWithGeocodingData(form, response);
      return of(true);
    } else {
      return this._showAddressConfirmationDialog(form, response);
    }
  }

  /**
   * Updates the form with geocoded data when the user confirms the suggested address.
   * @param {FormGroup<AddressForm>} form - The form containing address data.
   * @param {FormKeysAddressValidation} response - The response containing the suggested address.
   * @private
   */
  private _updateFormWithGeocodingData(
    form: FormGroup<AddressForm>,
    response: FormKeysAddressValidation
  ): void {
    Object.keys(response).forEach(key => {
      const control = form.get(key);
      const { geocodingValue } = response[key as keyof AddressForm];
      if (control && geocodingValue !== null) {
        control.setValue(geocodingValue);
      }
    });
    form.updateValueAndValidity();
  }

  /**
   * Displays a dialog to the user to confirm or edit the validated address.
   * @param {FormGroup<AddressForm>} form The form containing address data.
   * @param {FormKeysAddressValidation} response - The response containing the suggested address.
   * @private
   * @returns {Observable<boolean>} Observable indicating whether the user confirmed the suggested address.
   */
  private _showAddressConfirmationDialog(
    form: FormGroup<AddressForm>,
    response: FormKeysAddressValidation
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent>;
    if (
      Object.keys(response).every(
        key => response[key as keyof FormKeysAddressValidation].geocodingValue
      )
    ) {
      const proposalAddress = `${response.street.geocodingValue} ${response.houseNumber.geocodingValue}, ${response.postalCode.geocodingValue} ${response.city.geocodingValue}`;
      dialogRef = this._confirmation.open({
        title: this._translate.instant(
          'addressValidation.confirmation.incorrect-address-title'
        ) as string,
        message: this._translate.instant(
          'addressValidation.confirmation.proposal-message',
          { address: proposalAddress }
        ) as string,
        actions: {
          confirm: {
            label: this._translate.instant(
              'addressValidation.confirmation.confirm-label'
            ) as string,
            color: 'primary',
          },
          cancel: {
            label: this._translate.instant(
              'addressValidation.confirmation.cancel-label'
            ) as string,
          },
        },
      });
    } else {
      dialogRef = this._confirmation.open({
        title: this._translate.instant(
          'addressValidation.confirmation.incorrect-address-title'
        ) as string,
        message: this._translate.instant(
          'addressValidation.confirmation.no-existing-message'
        ) as string,
        actions: {
          confirm: {
            show: false,
          },
          cancel: {
            label: this._translate.instant(
              'addressValidation.confirmation.no-existing-cancel-label'
            ) as string,
          },
        },
      });
    }

    return dialogRef.afterClosed().pipe(
      map(closeAction => {
        if (closeAction === 'confirmed') {
          this._updateFormWithGeocodingData(form, response);
          return true;
        }
        return false;
      })
    );
  }
}
