<div class="relative flex flex-col w-full h-full">
  <div class="p-8 pb-6 sm:pb-8">
    <div class="flex flex-col items-center mt-4 sm:mt-0 text-center">
      <!-- Title -->
      <div class="text-xl leading-6 font-medium">
        {{ 'loadingDialog.title' | translate }}
      </div>

      <!-- Message -->
      <div class="text-secondary">
        {{ 'loadingDialog.message' | translate }}
      </div>

      <!-- Loading animation -->
      <div class="w-48 h-48 mt-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 601.9 586.7">
          <path
            class="stroke"
            d="M531,329.8c-21.1-13.6-45.7-20.4-70.8-20.4H326.9c-4,0-7.2,3.2-7.2,7.2V566c0,4,3.2,7.2,7.2,7.2h131
          	c47,0,91.1-23.9,115.5-64c13.1-21.6,20.2-46.8,19.2-73.1C590.8,392.3,567,353,531,329.8L531,329.8z" />
          <path
            class="stroke"
            d="M573.3,209.8c13.1-21.6,20.2-46.8,19.2-73.1C590.8,93,567,53.6,531.1,30.5C510,16.9,485.3,10,460.3,10H326.9
          	c-4,0-7.2,3.2-7.2,7.2v249.4c0,4,3.2,7.2,7.2,7.2h130.9C504.8,273.9,548.9,249.9,573.3,209.8L573.3,209.8z" />
          <path
            class="stroke"
            d="M71.1,30.5C35.1,53.6,11.3,93,9.6,136.7c-1,26.3,6.1,51.5,19.2,73.1c24.4,40.1,68.5,64,115.5,64h130.9
          	c4,0,7.2-3.2,7.2-7.2V17.2c0-4-3.2-7.2-7.2-7.2H141.9C116.8,10,92.1,16.9,71.1,30.5L71.1,30.5z" />
          <path
            class="stroke"
            d="M22.8,573.2h252.4c4,0,7.2-3.2,7.2-7.2V316.6c0-4-3.2-7.2-7.2-7.2h-97.8c-2.5,0-4.8,1.3-6.1,3.4
          	C119.7,395.9,68.2,479,16.6,562.2C13.7,567,17.1,573.2,22.8,573.2L22.8,573.2z" />
        </svg>
      </div>
    </div>
  </div>
</div>
