import { Injectable } from '@angular/core';
import {
  BranchOfficeCountFieldsFragment,
  CalculationRuleFieldsFragment,
  CurrentSubscriptionGQL,
  PaymentSubscriptionFieldsFragment,
  StripeProduct,
  StripeSubscriptionProduct,
  SystemType,
  ValidateBaseConfigurationGQL,
  ValidateBaseConfigurationQuery,
} from '@graphql/generated/graphql';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { Apollo, QueryRef } from 'apollo-angular';

const BASE_PACKAGE_QUANTITIES: {
  [key in StripeSubscriptionProduct]: number;
} = {
  [StripeProduct.Reward]: 1,
  [StripeProduct.Employee]: 1,
  [StripeProduct.BranchOffice]: 1,
};

@Injectable({
  providedIn: 'root',
})
export class ConfValidationService {
  private readonly _hasRewardReachedLimit$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly _hasEmployeeReachedLimit$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly _hasBranchOfficeReachedLimit$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private _validateBaseConfigurationQuery: QueryRef<ValidateBaseConfigurationQuery>;

  get hasRewardReachedLimit$(): Observable<boolean> {
    return this._hasRewardReachedLimit$.asObservable();
  }

  get hasEmployeeReachedLimit$(): Observable<boolean> {
    return this._hasEmployeeReachedLimit$.asObservable();
  }

  get hasBranchOfficeReachedLimit$(): Observable<boolean> {
    return this._hasBranchOfficeReachedLimit$.asObservable();
  }

  constructor(
    private readonly _currentSubscriptionGQL: CurrentSubscriptionGQL,
    private readonly _validateBaseConfigurationGQL: ValidateBaseConfigurationGQL,
    private readonly _apollo: Apollo
  ) {
    this._validateBaseConfigurationQuery =
      this._apollo.watchQuery<ValidateBaseConfigurationQuery>({
        query: this._validateBaseConfigurationGQL.document,
      });
  }

  async refetchBaseConfiguration(): Promise<void> {
    await this._validateBaseConfigurationQuery?.refetch();
  }

  everyBranchOfficeHasCashDesks(): Observable<boolean> {
    return this._fetchBaseConfigurationValidationFields().pipe(
      map(({ branchOffices }) =>
        this._checkEveryBranchOfficeHasAtLeastOneCashDesk(branchOffices)
      )
    );
  }

  hasValidBaseConfiguration(): Observable<boolean> {
    return this._fetchBaseConfigurationValidationFields().pipe(
      map(({ pointSystem, branchOffices, calculationRules }) => {
        switch (pointSystem?.pointSystemType.type) {
          case SystemType.VisitBased: {
            const atLeastOneBranchOffice =
              this._checkAtLeastOneBranchOffice(branchOffices);
            const atLeastOneCalculationRule =
              this._checkAtLeastOneCalculationRule(calculationRules);

            return atLeastOneBranchOffice && atLeastOneCalculationRule;
          }
          case SystemType.Percentage:
          case SystemType.Staggered: {
            const atLeastOneCalculationRule =
              this._checkAtLeastOneCalculationRule(calculationRules);
            const everyBranchOfficeHasCashDesk =
              this._checkEveryBranchOfficeHasAtLeastOneCashDesk(branchOffices);

            return atLeastOneCalculationRule && everyBranchOfficeHasCashDesk;
          }
          default:
            return false;
        }
      })
    );
  }

  isValueBasedPointSystem(): Observable<boolean | undefined> {
    return this._fetchBaseConfigurationValidationFields().pipe(
      map(value => value.pointSystem?.pointSystemType.type),
      map(type => {
        if (type) {
          return type !== SystemType.VisitBased;
        }
        return undefined;
      })
    );
  }

  hasProductReachedSubscriptionLimit(
    product: StripeSubscriptionProduct,
    currentQuantity: number
  ): void {
    this._fetchCurrentSubscription()
      .pipe(
        map(value => {
          const productTier = value?.productQuantities?.find(
            p => (p.product as unknown as StripeSubscriptionProduct) === product
          );

          return (
            (productTier?.quantity ?? 0) + BASE_PACKAGE_QUANTITIES[product] <=
            currentQuantity
          );
        }),
        tap(reachedLimit => {
          switch (product) {
            case StripeSubscriptionProduct.BranchOffice:
              this._hasBranchOfficeReachedLimit$.next(reachedLimit);
              break;
            case StripeSubscriptionProduct.Reward:
              this._hasRewardReachedLimit$.next(reachedLimit);
              break;
            case StripeSubscriptionProduct.Employee:
              this._hasEmployeeReachedLimit$.next(reachedLimit);
          }
        })
      )
      .subscribe();
  }

  private _checkAtLeastOneBranchOffice(
    branchOffices: BranchOfficeCountFieldsFragment[]
  ): boolean {
    return branchOffices.length > 0;
  }

  private _checkAtLeastOneCalculationRule(
    calculationRules: CalculationRuleFieldsFragment[]
  ): boolean {
    return calculationRules.length > 0;
  }

  private _checkEveryBranchOfficeHasAtLeastOneCashDesk(
    branchOffices: BranchOfficeCountFieldsFragment[]
  ): boolean {
    if (branchOffices.length <= 0) return false;

    return branchOffices.every(
      branchOffice => branchOffice._count.cashDesks > 0
    );
  }

  private _fetchCurrentSubscription(): Observable<
    PaymentSubscriptionFieldsFragment | null | undefined
  > {
    return this._currentSubscriptionGQL
      .fetch()
      .pipe(map(value => value.data.getSubscriptionOfCustomer));
  }

  private _fetchBaseConfigurationValidationFields(): Observable<ValidateBaseConfigurationQuery> {
    return this._validateBaseConfigurationQuery.valueChanges.pipe(
      map(value => value.data)
    );
  }
}
