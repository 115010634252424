import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, startWith } from 'rxjs';
import {
  CheckoutSessionUrlGQL,
  CompanyStatus,
  CompanySubscriptionInfoFieldsFragment,
  CompanySubscriptionInfoGQL,
  CreatePaymentCustomerGQL,
  CreatePaymentCustomerMutationVariables,
  CreateSubscriptionForExistingCustomerGQL,
  CreateSubscriptionForExistingCustomerMutationVariables,
  ProductPriceFieldsFragment,
  ProductPricesGQL,
  StripeProduct,
} from '@graphql/generated/graphql';
import {
  CalculatedPrices,
  PlanBillingForm,
  PlanBillingFormValue,
  PriceDetails,
} from './payment-subscription.type';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class PaymentSubscriptionService {
  get isRequested$(): Observable<boolean> {
    return this._fetchCompanySubscriptionInfo().pipe(
      map(company => company.status === CompanyStatus.Requested)
    );
  }

  get isCanceled$(): Observable<boolean> {
    return this._fetchCompanySubscriptionInfo().pipe(
      map(company => company.status === CompanyStatus.Canceled)
    );
  }

  get isCancellationRequested$(): Observable<boolean> {
    return this._fetchCompanySubscriptionInfo().pipe(
      map(company => company.status === CompanyStatus.CancellationRequested)
    );
  }

  get isPaymentPending$(): Observable<boolean> {
    return this._fetchCompanySubscriptionInfo().pipe(
      map(company => company.status === CompanyStatus.PaymentPending)
    );
  }

  get isBlocked$(): Observable<boolean> {
    return this._fetchCompanySubscriptionInfo().pipe(
      map(company => company.status === CompanyStatus.Blocked)
    );
  }

  get isPaymentFailed(): Observable<boolean> {
    return this._fetchCompanySubscriptionInfo().pipe(
      map(company => company.status === CompanyStatus.PaymentFailed)
    );
  }

  get planBillingForm(): FormGroup<PlanBillingForm> {
    return this._fb.group<PlanBillingForm>({
      [StripeProduct.BasePackage]: this._fb.control(1, { nonNullable: true }),
      [StripeProduct.BranchOffice]: this._fb.control(0, { nonNullable: true }),
      [StripeProduct.Reward]: this._fb.control(0, { nonNullable: true }),
      [StripeProduct.Employee]: this._fb.control(0, { nonNullable: true }),
    });
  }

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _productPricesGQL: ProductPricesGQL,
    private readonly _companySubscriptionInfoGQL: CompanySubscriptionInfoGQL,
    private readonly _createPaymentCustomerGQL: CreatePaymentCustomerGQL,
    private readonly _createSubscriptionForExistingCustomerGQL: CreateSubscriptionForExistingCustomerGQL,
    private readonly _checkoutSessionUrlGQL: CheckoutSessionUrlGQL
  ) {}

  calculatePrices(
    formGroup: FormGroup<PlanBillingForm>
  ): Observable<CalculatedPrices> {
    return combineLatest([
      formGroup.valueChanges.pipe(startWith(formGroup.value)),
      this._fetchProductPrices(),
    ]).pipe(
      map(([value, productPrices]) => this._processPrices(value, productPrices))
    );
  }

  createPaymentCustomer(
    variables: CreatePaymentCustomerMutationVariables
  ): Observable<string | undefined> {
    return this._createPaymentCustomerGQL
      .mutate(variables)
      .pipe(map(result => result.data?.createPaymentCustomer.paymentUrl));
  }

  createSubscriptionForExistingCustomer(
    variables: CreateSubscriptionForExistingCustomerMutationVariables
  ): Observable<string | undefined> {
    return this._createSubscriptionForExistingCustomerGQL
      .mutate(variables)
      .pipe(map(result => result.data?.createSubscription.paymentUrl));
  }

  fetchCheckoutSessionUrl(): Observable<string | null | undefined> {
    return this._checkoutSessionUrlGQL
      .fetch()
      .pipe(map(result => result.data.fetchOpenCheckoutSessionUrl));
  }

  private _processPrices(
    value: PlanBillingFormValue,
    productPrices: ProductPriceFieldsFragment[]
  ): CalculatedPrices {
    let totalPrice: number = 0;
    const priceDetails: PriceDetails = {};

    Object.entries(value).forEach(([key, quantity]) => {
      const productPrice = productPrices.find(
        p => p.product === (key as StripeProduct)
      );
      const pricingTier = productPrice?.prices.find(
        p => quantity <= p.maxQuantity
      );

      if (pricingTier) {
        const price = quantity * pricingTier.price;
        totalPrice += price;
        priceDetails[key as keyof PlanBillingForm] = {
          unitPrice: pricingTier.price,
          totalPrice: price,
        };
      }
    });

    return { totalPrice, priceDetails };
  }

  private _fetchCompanySubscriptionInfo(): Observable<CompanySubscriptionInfoFieldsFragment> {
    return this._companySubscriptionInfoGQL
      .fetch({}, { fetchPolicy: 'network-only' })
      .pipe(map(value => value.data.company));
  }

  private _fetchProductPrices(): Observable<ProductPriceFieldsFragment[]> {
    return this._productPricesGQL
      .fetch()
      .pipe(map(value => value.data.getProductPrices));
  }
}
