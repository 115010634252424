import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './dialog/dialog.component';
import { merge } from 'lodash';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { TranslateService } from '@ngx-translate/core';

/**
 * Represents a configuration for the confirmation dialog.
 */
export interface ConfirmationConfig {
  title: string;
  message: string;
  icon: {
    show: boolean;
    name: string;
    color:
      | 'primary'
      | 'accent'
      | 'warn'
      | 'basic'
      | 'info'
      | 'success'
      | 'warning'
      | 'error';
  };
  confirmationInput: {
    show: boolean;
    description: string;
    validation: string;
    placeholder: string;
  };
  actions: {
    confirm: {
      show: boolean;
      label: string;
      color: 'primary' | 'accent' | 'warn';
    };
    cancel: {
      show: boolean;
      label: string;
    };
  };
  dismissible: boolean;
}

/**
 * Allows partial definition of ConfirmationConfig properties.
 */
type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;
type ConfirmationConfigInput = DeepPartial<ConfirmationConfig>;

export type ConfirmationResult = 'confirmed' | 'cancelled' | null;

/**
 * Service to manage and display confirmation dialogs in the application.
 */
@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  /**
   * Default configuration for confirmation dialog
   * @private
   */
  private _defaultConfig: ConfirmationConfig = {
    title: this._translate.instant('management.confirmation.action') as string,
    message: this._translate.instant(
      'management.confirmation.message'
    ) as string,
    icon: {
      show: true,
      name: 'warning',
      color: 'warning',
    },
    confirmationInput: {
      show: false,
      placeholder: '',
      validation: '',
      description: '',
    },
    actions: {
      confirm: {
        show: true,
        label: this._translate.instant(
          'management.confirmation.confirm'
        ) as string,
        color: 'warn',
      },
      cancel: {
        show: true,
        label: this._translate.instant(
          'management.confirmation.cancel'
        ) as string,
      },
    },
    dismissible: false,
  };
  constructor(
    private readonly _matDialog: MatDialog,
    private readonly _translate: TranslateService
  ) {
    this._changeConfigOnLangChange();
  }

  /**
   * Opens a confirmation dialog with optional custom configuration.
   * Merges the provided configuration with the default configuration.
   * @param {ConfirmationConfigInput} config - Optional custom configuration for the confirmation dialog.
   * @returns {MatDialogRef<ConfirmationDialogComponent>} Reference to the opened dialog.
   * @example
   * constructor(private readonly _confirmation: ConfirmationService) {}
   *
   * myMethod(): void {
   *  const dialogRef = this._confirmation.open({ icon: { show: false } }); // Icon will not be shown
   *
   *  dialogRef.afterClosed().subscribe(res => {
   *    console.log(res); // res = 'confirmed' || 'canceled' || undefined;
   *  });
   * }
   */
  open(
    config: ConfirmationConfigInput = {}
  ): MatDialogRef<ConfirmationDialogComponent, ConfirmationResult> {
    const userConfig = merge({}, this._defaultConfig, config);

    return this._matDialog.open(ConfirmationDialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: 'confirmation-dialog-panel',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  private _changeConfigOnLangChange(): void {
    this._translate.onLangChange.subscribe(({ translations }) => {
      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const confirmationValues = translations['management']['confirmation'];

      this._defaultConfig = {
        title: confirmationValues['action'] as string,
        message: confirmationValues['message'] as string,
        icon: {
          show: true,
          name: 'warning',
          color: 'warning',
        },
        confirmationInput: {
          show: false,
          placeholder: '',
          validation: '',
          description: '',
        },
        actions: {
          confirm: {
            show: true,
            label: confirmationValues['confirm'] as string,
            color: 'warn',
          },
          cancel: {
            show: true,
            label: confirmationValues['cancel'] as string,
          },
        },
        dismissible: false,
      };
    });
  }
}
