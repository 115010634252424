import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ErrorDialogComponent } from './dialog/error-dialog.component';
import { ErrorDialogData } from './error-dialog.types';

@Injectable({
  providedIn: 'root',
})
export class ErrorDialogService implements OnDestroy {
  private _subscription = new Subscription();

  constructor(private readonly _dialog: MatDialog) {}

  openDialog(error: ErrorDialogData): void {
    const dialogRef = this._dialog.open<
      ErrorDialogComponent,
      ErrorDialogData,
      undefined
    >(ErrorDialogComponent, {
      autoFocus: false,
      disableClose: false,
      data: error,
      panelClass: 'error-dialog-panel',
    });

    this._subscription = dialogRef
      .afterClosed()
      .subscribe(() => this._subscription.unsubscribe());
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
