import { Injectable } from '@angular/core';
import { ConfirmationService } from '@shared/services';
import { EMPTY, switchMap } from 'rxjs';
import { ChangeVisibilityGQL } from '@graphql/generated/graphql';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class VisibleStatusService {
  constructor(
    private readonly _confirmation: ConfirmationService,
    private readonly _changeVisibilityGQL: ChangeVisibilityGQL,
    private readonly _translate: TranslateService
  ) {}

  changeVisibleStatus(isCurrentlyVisible: boolean): void {
    const dialogRef = this._confirmation.open({
      title: this._translate.instant(
        'management.visibleStatus.title'
      ) as string,
      message: this._translate.instant(
        isCurrentlyVisible
          ? 'management.visibleStatus.not-visible-message'
          : 'management.visibleStatus.visible-message'
      ) as string,
      icon: {
        name: 'warning',
        color: 'warning',
      },
      actions: {
        confirm: {
          label: this._translate.instant(
            'management.visibleStatus.confirm'
          ) as string,
          color: 'primary',
        },
        cancel: {
          label: this._translate.instant(
            'management.visibleStatus.cancel'
          ) as string,
        },
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap(action => {
          if (action === 'confirmed') {
            return this._changeVisibilityGQL.mutate({
              visible: !isCurrentlyVisible,
            });
          }
          return EMPTY;
        })
      )
      .subscribe();
  }
}
