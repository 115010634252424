import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LoadingDialogComponent } from './dialog/loading-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class LoadingService implements OnDestroy {
  private _subscription = new Subscription();
  private _dialogRefMap: Map<string, MatDialogRef<LoadingDialogComponent>> =
    new Map();

  constructor(private readonly _dialog: MatDialog) {}

  showLoadingIndicator(key: string): void {
    this._showDialogLoading(key);
  }

  hideLoadingIndicator(key: string): void {
    this._hideDialogLoading(key);
  }

  private _showDialogLoading(key: string): void {
    const dialogRef = this._dialog.open(LoadingDialogComponent, {
      maxWidth: '100%',
      disableClose: true,
      hasBackdrop: false,
      panelClass: 'loading-dialog-panel',
    });

    this._dialogRefMap.set(key, dialogRef);
  }

  private _hideDialogLoading(key: string): void {
    const dialogRef = this._dialogRefMap.get(key);
    if (dialogRef) {
      dialogRef.close();
      this._dialogRefMap.delete(key);
    } else {
      console.error(
        'Loading dialog cannot be closed, because it does not exist.'
      );
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
