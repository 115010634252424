import {
  ElementRef,
  Injectable,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root',
})
export class OverlayPanelService {
  private _overlayRef?: OverlayRef;

  constructor(private readonly _overlay: Overlay) {}

  openPanel(
    origin: ElementRef,
    template: TemplateRef<unknown>,
    viewContainerRef: ViewContainerRef
  ): void {
    if (!origin) {
      return;
    }

    if (!this._overlayRef) {
      this._createOverlay(origin);
    }

    this._overlayRef!.attach(new TemplatePortal(template, viewContainerRef));
  }

  closePanel(): void {
    this._overlayRef?.detach();
  }

  private _createOverlay(origin: ElementRef): void {
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(
          origin.nativeElement as FlexibleConnectedPositionStrategyOrigin
        )
        .withLockedPosition(true)
        .withPush(false)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef?.detach();
    });
  }
}
