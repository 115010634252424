import { Injectable } from '@angular/core';
import { ApolloCache, DocumentNode } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';

type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

@Injectable({
  providedIn: 'root',
})
export class ApolloCacheService {
  /**
   * Adds a new item to the Apollo cache.
   * @param {ApolloCache<unknown>} cache - The Apollo cache on which the operation is performed.
   * @param {DocumentNode | TypedDocumentNode<TData, TVariables>} query - The GraphQL query,
   *          which is used to read the data from the cache.
   * @param {ArrayElement<TData[keyof TData]> | ArrayElement<TData[keyof TData]>[]} newItem - The item to add.
   * @param {keyof TData} dataPath - The path in the cache object where the data is to be modified.
   * @param {TVariables} variables - The variables needed for the query, if any.
   *
   * @template TData - The type of data stored in the cache.
   * @template TVariables - The type of variables used in the query.
   */
  add<TData, TVariables>(
    cache: ApolloCache<unknown>,
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    newItem:
      | ArrayElement<TData[keyof TData]>
      | ArrayElement<TData[keyof TData]>[],
    dataPath: keyof TData,
    variables?: TVariables
  ): void {
    this._operateOnCache<TData, TVariables>(
      cache,
      query,
      dataPath,
      variables,
      (data: TData): void => {
        const array = data[dataPath] as ArrayElement<TData[keyof TData]>[];
        Array.isArray(newItem) ? array.push(...newItem) : array.push(newItem);
      }
    );
  }

  /**
   * Removes an item from the Apollo cache.
   * @param {ApolloCache<unknown>} cache - The Apollo cache on which the operation is performed.
   * @param {DocumentNode | TypedDocumentNode<TData, TVariables>} query - The GraphQL query,
   *          which is used to read the data from the cache.
   * @param {string | number} itemId - The ID of the item to remove.
   * @param {keyof ArrayElement<TData[keyof TData]>} compareKey - The key to comparing the elements.
   * @param {keyof TData} dataPath - The path in the cache object where the data is to be modified.
   * @param {TVariables} variables - The variables needed for the query, if any.
   *
   * @template TData - The type of data stored in the cache.
   * @template TVariables - The type of variables used in the query.
   */
  remove<TData, TVariables>(
    cache: ApolloCache<unknown>,
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    itemId: string | number,
    compareKey: keyof ArrayElement<TData[keyof TData]>,
    dataPath: keyof TData,
    variables?: TVariables
  ): void {
    this._operateOnCache<TData, TVariables>(
      cache,
      query,
      dataPath,
      variables,
      (data: TData): void => {
        const array = data[dataPath] as ArrayElement<TData[keyof TData]>[];
        const index = array.findIndex(item => item[compareKey] === itemId);

        if (index !== -1) {
          array.splice(index, 1);
        } else {
          console.warn(
            `Item with ${compareKey.toString()}: ${itemId} not found.`
          );
        }
      }
    );
  }

  /**
   * Overwrites an item in the Apollo cache.
   * @param {ApolloCache<unknown>} cache - The Apollo cache on which the operation is performed.
   * @param {DocumentNode | TypedDocumentNode<TData, TVariables>} query - The GraphQL query,
   *          which is used to read the data from the cache.
   * @param {string | number} itemId - The ID of the item to overwrite.
   * @param {keyof ArrayElement<TData[keyof TData]>} compareKey - The key to comparing the elements.
   * @param {ArrayElement<TData[keyof TData]>} newItem - The new element.
   * @param {keyof TData} dataPath - The path in the cache object where the data is to be modified.
   * @param {TVariables} variables - The variables needed for the query, if any.
   *
   * @template TData - The type of data stored in the cache.
   * @template TVariables - The type of variables used in the query.
   */
  overwrite<TData, TVariables>(
    cache: ApolloCache<unknown>,
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    itemId: string | number,
    compareKey: keyof ArrayElement<TData[keyof TData]>,
    newItem: ArrayElement<TData[keyof TData]>,
    dataPath: keyof TData,
    variables?: TVariables
  ): void {
    this._operateOnCache<TData, TVariables>(
      cache,
      query,
      dataPath,
      variables,
      (data: TData): void => {
        const array = data[dataPath] as ArrayElement<TData[keyof TData]>[];
        const index = array.findIndex(item => item[compareKey] === itemId);

        if (index !== -1) {
          array[index] = newItem;
        } else {
          console.warn(
            `Item with ${compareKey.toString()}: ${itemId} not found in cache.`
          );
        }
      }
    );
  }

  /**
   * Performs an operation on the data stored in the Apollo cache. This generic method
   * Abstracts the reading and manipulation of data in the cache to reduce code repetition.
   * It encapsulates the pattern of reading, modifying, and writing data back to the cache.
   *
   * @param {ApolloCache<unknown>} cache - The Apollo cache on which the operation is performed.
   * @param {DocumentNode | TypedDocumentNode<TData, TVariables>} query - The GraphQL query,
   *          which is used to read the data from the cache.
   * @param {keyof TData} dataPath - The path in the cache object where the data is to be modified.
   * @param {TVariables} variables - The variables needed for the query, if any.
   * @param {(data: TData) => void} operation - The function that does the actual manipulation of the data.
   *          This function is called after the data is read from the cache.
   *
   * @template TData - The type of data stored in the cache.
   * @template TVariables - The type of variables used in the query.
   */
  private _operateOnCache<TData, TVariables>(
    cache: ApolloCache<unknown>,
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    dataPath: keyof TData,
    variables: TVariables | undefined,
    operation: (data: TData) => void
  ): void {
    const data = cloneDeep(
      cache.readQuery<TData, TVariables>({ query, variables })
    );

    if (!data || !Array.isArray(data[dataPath])) {
      console.error(
        `Expected an array at ${dataPath.toString()}, but found: ${typeof data?.[dataPath]}`
      );
      return;
    }
    operation(data);
    cache.writeQuery({ query, variables, data });
  }
}
