<div class="relative flex flex-col w-full h-full">
  <div class="absolute top-0 right-0 pt-4 pr-4">
    <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon class="text-secondary">close</mat-icon>
    </button>
  </div>

  <div
    class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">
    <div
      class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600">
      <mat-icon class="text-current">error</mat-icon>
    </div>

    <div
      class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">
      <!-- Title -->
      <div class="text-xl leading-6 font-medium">
        {{ 'errorDialog.title' | translate }}
      </div>

      @switch (data.type) {
        @case ('GraphQL') {
          <div class="text-secondary">
            {{ 'errorDialog.graphql.description' | translate }}
          </div>

          @if (
            data.error.extensions['code'] || data.error.extensions['requestId']
          ) {
            <div
              class="bg-gray-50 dark:bg-black dark:bg-opacity-10 p-4 rounded-2xl">
              <div class="leading-6 error-message flex flex-col">
                @if (data.error.extensions['code']; as code) {
                  <span>{{ code }}</span>
                }
                <span class="text-md">{{ data.error.message }}</span>
                @if (data.error.extensions['requestId']; as requestId) {
                  <span class="text-md">(Request ID: {{ requestId }})</span>
                }
              </div>
            </div>
          }

          <div class="text-secondary">
            @if (isManagementPortalRoute) {
              {{ 'errorDialog.graphql.supportMessage' | translate }}
            } @else {
              {{ 'errorDialog.application.supportMessage' | translate }}
            }
          </div>
        }
        @case ('Application') {
          <div class="text-secondary">
            {{ 'errorDialog.application.description' | translate }}
          </div>

          @if (data.error.name || data.error.message) {
            <div
              class="bg-gray-50 dark:bg-black dark:bg-opacity-10 p-4 rounded-2xl">
              <div class="leading-6 error-message flex flex-col">
                @if (data.error.name; as code) {
                  <span>{{ code }}</span>
                }
                <span class="text-md">{{ data.error.message }}</span>
              </div>
            </div>
          }

          <div class="text-secondary">
            {{ 'errorDialog.application.supportMessage' | translate }}
          </div>
        }
        @case ('Network') {
          <div class="text-secondary">
            {{ 'errorDialog.network.description' | translate }}
          </div>

          @if (data.error?.name || data.error?.message) {
            <div
              class="bg-gray-50 dark:bg-black dark:bg-opacity-10 p-4 rounded-2xl">
              <div class="leading-6 error-message flex flex-col">
                @if (data.error?.name; as code) {
                  <span>{{ code }}</span>
                }
                <span class="text-md">{{ data.error?.message }}</span>
              </div>
            </div>
          }

          <div class="text-secondary">
            {{ 'errorDialog.network.supportMessage' | translate }}
          </div>
        }
      }
    </div>
  </div>

  <div
    class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
    <button mat-stroked-button [matDialogClose]="undefined">
      {{ 'errorDialog.close' | translate }}
    </button>
    @if (data.type !== 'Network' && isManagementPortalRoute) {
      <button mat-flat-button color="primary" (click)="contactSupport()">
        {{ 'errorDialog.supportRequest' | translate }}
      </button>
    }
  </div>
</div>
