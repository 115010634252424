import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

/**
 * Service to manage alerts within the application.
 * This service allows for showing and dismissing alerts by name.
 */
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private readonly _onDismiss: ReplaySubject<string> =
    new ReplaySubject<string>(1);
  private readonly _onShow: ReplaySubject<string> = new ReplaySubject<string>(
    1
  );

  /**
   * Observable to listen for dismiss actions.
   * When an alert is dismissed, its name is emitted trough this observable.
   * @returns {Observable<string>} An observable emitting the name of the dismissed alert.
   */
  get onDismiss(): Observable<string> {
    return this._onDismiss.asObservable();
  }

  /**
   * Observable to listen for show actions.
   * When an alert is shown, its name is emitted through this observable.
   * @returns {Observable<string>} An observable emitting the name of the shown alert.
   */
  get onShow(): Observable<string> {
    return this._onShow.asObservable();
  }

  /**
   * Dismisses an alert by its name.
   * If the name is not provided, the method does nothing.
   * @param {string} name - The name of the alert to dismiss.
   */
  dismiss(name: string): void {
    if (!name) {
      return;
    }

    this._onDismiss.next(name);
  }

  /**
   * Shows an alert by its name.
   * If the name is not provided, the method does nothing.
   * @param {string} name - The name of the alert to show.
   */
  show(name: string): void {
    if (!name) {
      return;
    }

    this._onShow.next(name);
  }
}
